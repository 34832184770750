import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IAppointmentConfirm } from '@appointments/types/Confirm/confirm';
import { IAppointmentsConfirmContext } from '@appointments/types/Confirm/context';
import {
  ISearchAppointmentsToConfirmParamsRequest,
  IUpdateAppointmentToConfirm,
} from '@appointments/types/Confirm/requests';

import { useLoader } from '@loader/hooks/useLoader';

type IAppointmentsToConfirm = EquitesPaginate<IAppointmentConfirm>;

const AppointmentsConfirmContext = createContext<IAppointmentsConfirmContext>({} as IAppointmentsConfirmContext);

AppointmentsConfirmContext.displayName = 'AppointmentsConfirm';

const AppointmentsConfirmProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages']);

  const { showLoader, hideLoader } = useLoader();

  const [appointmentsConfirm, setAppointmentsConfirm] = useState<IAppointmentsToConfirm>({} as IAppointmentsToConfirm);
  const [appointmentConfirm, setAppointmentConfirm] = useState<IAppointmentConfirm>({} as IAppointmentConfirm);

  const getAppointmentsToConfirm = useCallback(
    async (params: ISearchAppointmentsToConfirmParamsRequest) => {
      try {
        showLoader();

        const response = await api.appointment().getAppointmentsToConfirm(params);

        setAppointmentsConfirm(response.data);
        setAppointmentConfirm({} as IAppointmentConfirm);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getAppointmentToConfirmById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.appointment().getAppointmentToConfirmById(id);

        setAppointmentConfirm(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const updateAppointmentToConfirm = useCallback(
    async (data: IUpdateAppointmentToConfirm) => {
      try {
        showLoader();

        await api.appointment().updateAppointmentToConfirm(data);

        toast(t('appointment.updated_success', { ns: 'messages' }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IAppointmentsConfirmContext>(
    () => ({
      appointmentsConfirm,
      appointmentConfirm,
      getAppointmentsToConfirm,
      updateAppointmentToConfirm,
      getAppointmentToConfirmById,
    }),
    [
      appointmentsConfirm,
      appointmentConfirm,
      getAppointmentsToConfirm,
      updateAppointmentToConfirm,
      getAppointmentToConfirmById,
    ],
  );

  return <AppointmentsConfirmContext.Provider value={contextValue}>{children}</AppointmentsConfirmContext.Provider>;
};

export { AppointmentsConfirmProvider, AppointmentsConfirmContext };
