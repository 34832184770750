import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Buffer } from 'buffer';
import { createContext } from 'use-context-selector';

import { Storage } from '@constants/Storage';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';
import usePersistedState from '@utils/usePersistedState';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';
import { ITokens } from '@users/types/Auth/auth';
import { IAuthContext } from '@users/types/Auth/context';
import { ISignInRequest } from '@users/types/Auth/requests';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

AuthContext.displayName = 'Auth';

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const getTokensStored = (): ITokens | null => {
    const tokensStored = localStorage.getItem(Storage.TOKENS);
    return tokensStored ? JSON.parse(tokensStored) : null;
  };

  const { showLoader, hideLoader } = useLoader();
  const { handleUserAuthenticated, handleUserCompany, handleUserPermissions, handleUserSubscription } = useUsers();

  const [isAuthenticated, setIsAuthenticated] = useState(() => !!getTokensStored());

  const [tokens, setTokens] = usePersistedState<ITokens | null>(Storage.TOKENS, getTokensStored());

  const signIn = useCallback(
    async (data: ISignInRequest) => {
      try {
        showLoader();

        const response = await api.sessions().create(data);

        setIsAuthenticated(true);
        setTokens({ accessToken: response.data.token, refreshToken: response.data.refreshToken });

        handleUserAuthenticated(response.data.user);
        handleUserCompany(response.data.company);
        handleUserSubscription(response.data.subscription);

        const { pms } = JSON.parse(Buffer.from(response.data.token.split('.')[1], 'base64').toString());
        const permissions = pms.map((permission: string) => `${permission}`);
        handleUserPermissions(permissions);

        sessionStorage.removeItem(Storage.COMPANY_REGISTER);

        navigate('/dashboard');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [
      handleUserAuthenticated,
      handleUserCompany,
      handleUserPermissions,
      handleUserSubscription,
      hideLoader,
      navigate,
      setTokens,
      showLoader,
    ],
  );

  const signOut = useCallback(async () => {
    try {
      showLoader();

      localStorage.removeItem(Storage.TOKENS);
      localStorage.removeItem(Storage.USER);
      localStorage.removeItem(Storage.COMPANY);
      localStorage.removeItem(Storage.SUBSCRIPTION);
      localStorage.removeItem(Storage.PERMISSIONS);

      setIsAuthenticated(false);
      setTokens(null);

      handleUserAuthenticated(null);
      handleUserCompany(null);
      handleUserSubscription(null);
      handleUserPermissions([]);

      navigate('/login', { replace: true });
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [
    handleUserAuthenticated,
    handleUserCompany,
    handleUserPermissions,
    handleUserSubscription,
    hideLoader,
    navigate,
    setTokens,
    showLoader,
  ]);

  const contextValue = useMemo<IAuthContext>(
    () => ({ isAuthenticated, tokens, signIn, signOut }),
    [isAuthenticated, tokens, signIn, signOut],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
