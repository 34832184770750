import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseMedicineContext } from '@horses/types/Medicines/context';
import { IHorseMedicine } from '@horses/types/Medicines/medicine';
import {
  IGetHorseMedicinesParams,
  ICreateHorseMedicineRequest,
  IUpdateHorseMedicineRequest,
  IDeleteHorseMedicineParams,
} from '@horses/types/Medicines/requests';

import { useLoader } from '@loader/hooks/useLoader';

type IMedicine = EquitesPaginate<IHorseMedicine>;

const HorseMedicineContext = createContext<IHorseMedicineContext>({} as IHorseMedicineContext);

HorseMedicineContext.displayName = 'HorseMedicines';

const HorseMedicineProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [medicines, setMedicines] = useState<IMedicine>({ totalItems: 0 } as IMedicine);

  const getHorseMedicines = useCallback(
    async (params: IGetHorseMedicinesParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseMedicines(params);

        const items = response.data.items.map(medicine => {
          const dateFormatted = format(parseISO(medicine.date), 'dd/MM/yyyy');
          return { ...medicine, dateFormatted };
        });

        setMedicines({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseMedicine = useCallback(
    async (data: ICreateHorseMedicineRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorseMedicine(data);

        const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');

        setMedicines(current => ({
          ...current,
          totalItems: current.totalItems + 1,
          items: [...current.items, { ...response.data, dateFormatted }],
        }));

        const item = t('medicine', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateHorseMedicine = useCallback(
    async (data: IUpdateHorseMedicineRequest) => {
      try {
        showLoader();

        const response = await api.horse().updateHorseMedicine(data);

        setMedicines(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === data.id);

          const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');

          items[index] = { ...response.data, dateFormatted };

          return { ...current, items };
        });

        const item = t('medicine', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorseMedicine = useCallback(
    async (params: IDeleteHorseMedicineParams) => {
      try {
        showLoader();

        await api.horse().deleteHorseMedicine(params);

        setMedicines(current => ({
          ...current,
          totalItems: current.totalItems - 1,
          items: current.items.filter(medicine => medicine.id !== params.id),
        }));

        const item = t('medicine', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorseMedicineContext>(
    () => ({ medicines, getHorseMedicines, createHorseMedicine, updateHorseMedicine, deleteHorseMedicine }),
    [medicines, getHorseMedicines, createHorseMedicine, updateHorseMedicine, deleteHorseMedicine],
  );

  return <HorseMedicineContext.Provider value={contextValue}>{children}</HorseMedicineContext.Provider>;
};

export { HorseMedicineProvider, HorseMedicineContext };
