import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useCompanies } from '@companies/hooks/useCompanies';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';
import { ISubscriptionContext } from '@users/types/Subscriptions/context';
import { IUpdateSubscription, IUpdatePayment } from '@users/types/Subscriptions/requests';
import { ISubscription, ISubscriptionLimits } from '@users/types/Subscriptions/subscriptions';

const SubscriptionContext = createContext<ISubscriptionContext>({} as ISubscriptionContext);

SubscriptionContext.displayName = 'Subscriptions';

const SubscriptionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'plans', 'address']);

  const { showLoader, hideLoader } = useLoader();

  const { updateCompanyState } = useCompanies();
  const { handleUserSubscription } = useUsers();

  const [subscription, setSubscription] = useState<ISubscription>({} as ISubscription);
  const [subscriptionLimits, setSubscriptionLimits] = useState<ISubscriptionLimits>({} as ISubscriptionLimits);

  const generateSubscriptionPayload = useCallback((data: ISubscription) => {
    if (data.payment?.payableWith === 'credit_card') {
      const nameText = data.payment.name;
      const cardNumberText = data.payment.cardNumber.replaceAll(/-/g, ' ');
      const monthYearText = data.payment.monthYear.padStart(5, '0');

      return {
        ...data,
        payment: {
          ...data.payment,
          cardNumber: '',
          monthYear: '',
          name: '',
          nameText,
          cardNumberText,
          monthYearText,
        },
      };
    }

    return data;
  }, []);

  const getMySubscription = useCallback(async () => {
    try {
      showLoader();

      const response = await api.subscriptions().getMySubscription();

      setSubscription(generateSubscriptionPayload(response.data));
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [generateSubscriptionPayload, hideLoader, showLoader]);

  const getSubscriptionLimits = useCallback(async () => {
    try {
      showLoader();

      const response = await api.subscriptions().getSubscriptionLimits();

      setSubscriptionLimits(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const updateSubscription = useCallback(
    async (data: IUpdateSubscription) => {
      try {
        showLoader();

        const response = await api.subscriptions().updateSubscription(data);

        const subscriptionPayload = generateSubscriptionPayload(response.data);

        setSubscription(subscriptionPayload);
        handleUserSubscription(subscriptionPayload);

        const item = t('plan', { ns: 'plans' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, generateSubscriptionPayload, handleUserSubscription, t, hideLoader],
  );

  const updatePayment = useCallback(
    async (data: IUpdatePayment) => {
      try {
        const response = await api.subscriptions().updatePayment(data);

        setSubscription(generateSubscriptionPayload(response.data));

        const { cnpj, cpf } = data;
        if (cnpj) updateCompanyState({ cnpj });
        if (cpf) updateCompanyState({ cpf });

        const isActivate = data.activateSubscription;
        const item = isActivate ? t('subscription', { ns: 'plans' }) : t('payment_method', { ns: 'plans' });
        const keyMessage = isActivate ? 'crud.activate_success' : 'crud.updated_success';
        const context = isActivate ? 'female' : 'male';
        toast(t(keyMessage, { ns: 'messages', context, item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      }
    },
    [generateSubscriptionPayload, updateCompanyState, t],
  );

  const handleSubscriptionState = useCallback((data: Partial<ISubscription>) => {
    setSubscription(current => ({ ...current, ...data }));
  }, []);

  const contextValue = useMemo<ISubscriptionContext>(
    () => ({
      subscription,
      subscriptionLimits,
      getMySubscription,
      getSubscriptionLimits,
      handleSubscriptionState,
      updateSubscription,
      updatePayment,
    }),
    [
      getMySubscription,
      getSubscriptionLimits,
      handleSubscriptionState,
      subscription,
      subscriptionLimits,
      updatePayment,
      updateSubscription,
    ],
  );

  return <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>;
};

export { SubscriptionContext, SubscriptionProvider };
