import { AxiosInstance, AxiosResponse } from 'axios';

import EquitesPaginate from '@equites/api/paginate';

import { openPdf } from '@utils/OpenPDF';

import { IHorseExam } from '@horses/types/Exams/exam';
import {
  IGetHorseExamsParams,
  ICreateHorseExamRequest,
  IUpdateHorseExamRequest,
  IDeleteHorseExamParams,
  IHorseExamReportUploadRequest,
  IDeleteHorseExamReportRequest,
} from '@horses/types/Exams/requests';
import { IHorseExpense } from '@horses/types/Expenses/expense';
import {
  IGetHorseExpensesParams,
  IReportHorseExpensesParams,
  ICreateHorseExpenseRequest,
  IDeleteHorseExpenseRequest,
  IPaidHorseExpenseRequest,
} from '@horses/types/Expenses/requests';
import { IHorse, IHorseFood, IHorseshoes } from '@horses/types/Horses/horse';
import {
  ICreateHorseRequest,
  IUpdateHorseRequest,
  IHandleHorseFoodsParams,
  IGetHorseHorseshoesParams,
  ICreateHorseHorseshoesParams,
  IGetHorsesPaginateParams,
} from '@horses/types/Horses/requests';
import { IHorseMedicine } from '@horses/types/Medicines/medicine';
import {
  IGetHorseMedicinesParams,
  ICreateHorseMedicineRequest,
  IUpdateHorseMedicineRequest,
  IDeleteHorseMedicineParams,
} from '@horses/types/Medicines/requests';
import { IHorseOdontology } from '@horses/types/Odontologist/odontology';
import {
  ICreateHorseOdontologistRequest,
  IDeleteHorseOdontologistParams,
  IGetHorseOdontologistParams,
  IUpdateHorseOdontologistRequest,
} from '@horses/types/Odontologist/requests';
import { IHorseOwner } from '@horses/types/Owners/owner';
import { ICreateHorseOwnerRequest, IUpdateHorseOwnerRequest } from '@horses/types/Owners/requests';
import {
  IGetHorseVaccinesParams,
  ICreateHorseVaccineRequest,
  IDeleteHorseVaccineParams,
} from '@horses/types/Vaccines/requests';
import { IVaccine } from '@horses/types/Vaccines/vaccine';

type IHorseExpensesResponse = Promise<AxiosResponse<EquitesPaginate<IHorseExpense>>>;

export class Horse {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getHorses(): Promise<AxiosResponse<IHorse[]>> {
    return this.instance.get(`/horses`);
  }

  getHorsesPaginate(params: IGetHorsesPaginateParams): Promise<AxiosResponse<EquitesPaginate<IHorse>>> {
    return this.instance.get(`/horses/paginate`, { params });
  }

  getHorse(id: string): Promise<AxiosResponse<IHorse>> {
    return this.instance.get(`/horses/${id}`);
  }

  createHorse(data: ICreateHorseRequest): Promise<AxiosResponse<IHorse>> {
    return this.instance.post(`/horses`, data);
  }

  updateHorse(data: IUpdateHorseRequest): Promise<AxiosResponse<IHorse>> {
    const { id, ...body } = data;
    return this.instance.patch(`/horses/${id}`, body);
  }

  deleteHorse(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/horses/${id}`);
  }

  updateHorseAvatar(id: string, file: File): Promise<AxiosResponse<IHorse>> {
    const data = new FormData();
    data.append('photo', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.instance.patch(`/horses/${id}/upload/avatar`, data, { headers });
  }

  // Vaccines

  getHorseVaccines(params: IGetHorseVaccinesParams): Promise<AxiosResponse<IVaccine[]>> {
    const { horseId, year } = params;
    return this.instance.get(`/horses/${horseId}/vaccines`, { params: { year } });
  }

  createHorseVaccine(data: ICreateHorseVaccineRequest): Promise<AxiosResponse<IVaccine>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/vaccines`, body);
  }

  deleteHorseVaccine(params: IDeleteHorseVaccineParams): Promise<AxiosResponse<IVaccine>> {
    const { horseId, id } = params;
    return this.instance.delete(`/horses/${horseId}/vaccines/${id}`);
  }

  // HorseFoods

  getHorseFoods(horseId: string): Promise<AxiosResponse<IHorseFood[]>> {
    return this.instance.get(`/horses/${horseId}/foods`);
  }

  handleHorseFoods(params: IHandleHorseFoodsParams): Promise<AxiosResponse<IHorseFood[]>> {
    const { horseId, foods } = params;
    return this.instance.post(`/horses/${horseId}/foods`, { foods });
  }

  // Horseshoes

  getHorseLastHorseshoes(params: IGetHorseHorseshoesParams): Promise<AxiosResponse<IHorseshoes>> {
    const { horseId } = params;
    return this.instance.get(`/horses/${horseId}/horseshoes/last`);
  }

  createHorseshoes(params: ICreateHorseHorseshoesParams): Promise<AxiosResponse<IHorseshoes>> {
    const { horseId, ...data } = params;
    return this.instance.post(`/horses/${horseId}/horseshoes`, { ...data });
  }

  // Expenses

  getHorseExpenses(requestParams: IGetHorseExpensesParams): IHorseExpensesResponse {
    const { horseId, ...params } = requestParams;
    return this.instance.get(`/horses/${horseId}/expenses`, { params });
  }

  async getReportHorseExpenses(requestParams: IReportHorseExpensesParams): Promise<AxiosResponse> {
    const { horseId, ...params } = requestParams;
    const { data, ...requestData } = await this.instance.get(`/horses/${horseId}/reports/expenses/pdf/export`, {
      params,
      responseType: 'blob',
    });

    openPdf(data);

    return { data, ...requestData };
  }

  createHorseExpense(data: ICreateHorseExpenseRequest): Promise<AxiosResponse<IHorseExpense>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/expenses`, body);
  }

  deleteHorseExpense(data: IDeleteHorseExpenseRequest): Promise<AxiosResponse<void>> {
    const { horseId, expenseId } = data;
    return this.instance.delete(`/horses/${horseId}/expenses/${expenseId}`);
  }

  paidHorseExpense(data: IPaidHorseExpenseRequest): Promise<AxiosResponse<IHorseExpense>> {
    const { horseId, expenseId, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/expenses/${expenseId}/paid`, body);
  }

  // Medicines

  getHorseMedicines(request: IGetHorseMedicinesParams): Promise<AxiosResponse<EquitesPaginate<IHorseMedicine>>> {
    const { horseId, ...params } = request;
    return this.instance.get(`/horses/${horseId}/medicines`, { params });
  }

  createHorseMedicine(data: ICreateHorseMedicineRequest): Promise<AxiosResponse<IHorseMedicine>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/medicines`, body);
  }

  updateHorseMedicine(data: IUpdateHorseMedicineRequest): Promise<AxiosResponse<IHorseMedicine>> {
    const { horseId, id, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/medicines/${id}`, body);
  }

  deleteHorseMedicine(params: IDeleteHorseMedicineParams): Promise<AxiosResponse<void>> {
    const { horseId, id } = params;
    return this.instance.delete(`/horses/${horseId}/medicines/${id}`);
  }

  // Exams

  getHorseExams(request: IGetHorseExamsParams): Promise<AxiosResponse<EquitesPaginate<IHorseExam>>> {
    const { horseId, ...params } = request;
    return this.instance.get(`/horses/${horseId}/exams`, { params });
  }

  createHorseExam(data: ICreateHorseExamRequest): Promise<AxiosResponse<IHorseExam>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/exams`, body);
  }

  updateHorseExam(data: IUpdateHorseExamRequest): Promise<AxiosResponse<IHorseExam>> {
    const { horseId, id, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/exams/${id}`, body);
  }

  deleteHorseExam(params: IDeleteHorseExamParams): Promise<AxiosResponse<void>> {
    const { horseId, id } = params;
    return this.instance.delete(`/horses/${horseId}/exams/${id}`);
  }

  uploadHorseExamReport(params: IHorseExamReportUploadRequest): Promise<AxiosResponse<IHorseExam>> {
    const { examId, horseId, report } = params;

    const data = new FormData();
    data.append('report', report);

    return this.instance.post(`/horses/${horseId}/exams/${examId}/upload/report`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteHorseExamReport(params: IDeleteHorseExamReportRequest): Promise<AxiosResponse<void>> {
    const { horseId, examId } = params;
    return this.instance.delete(`/horses/${horseId}/exams/${examId}/report`);
  }

  // Owners

  getOwners(): Promise<AxiosResponse<IHorseOwner[]>> {
    return this.instance.get(`/horses/owners`);
  }

  createOwner(data: ICreateHorseOwnerRequest): Promise<AxiosResponse<IHorseOwner>> {
    return this.instance.post(`/horses/owners`, data);
  }

  updateOwner(data: IUpdateHorseOwnerRequest): Promise<AxiosResponse<IHorseOwner>> {
    const { id, ...body } = data;
    return this.instance.patch(`/horses/owners/${id}`, body);
  }

  // Odontologist

  getHorseOdontologist(request: IGetHorseOdontologistParams): Promise<AxiosResponse<IHorseOdontology[]>> {
    const { horseId, ...params } = request;
    return this.instance.get(`/horses/${horseId}/odontologist`, { params });
  }

  createHorseOdontology(data: ICreateHorseOdontologistRequest): Promise<AxiosResponse<IHorseOdontology>> {
    const { horseId, ...body } = data;
    return this.instance.post(`/horses/${horseId}/odontologist`, body);
  }

  updateHorseOdontology(data: IUpdateHorseOdontologistRequest): Promise<AxiosResponse<IHorseOdontology>> {
    const { horseId, id, ...body } = data;
    return this.instance.patch(`/horses/${horseId}/odontologist/${id}`, body);
  }

  deleteHorseOdontology(params: IDeleteHorseOdontologistParams): Promise<AxiosResponse<void>> {
    const { horseId, id } = params;
    return this.instance.delete(`/horses/${horseId}/odontologist/${id}`);
  }
}

export const horse = (instance: AxiosInstance): Horse => new Horse(instance);
