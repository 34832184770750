import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import { IMenuContext } from '@menu/types/Menu/context';

const MenuContext = createContext<IMenuContext>({} as IMenuContext);

MenuContext.displayName = 'Menu';

const MenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [shouldShowSidebarMobile, setShouldShowSidebarMobile] = useState(false);

  const showSidebarMobile = useCallback(() => {
    setShouldShowSidebarMobile(true);
  }, []);

  const hideSidebarMobile = useCallback(() => {
    setShouldShowSidebarMobile(false);
  }, []);

  const contextValue = useMemo<IMenuContext>(
    () => ({ shouldShowSidebarMobile, hideSidebarMobile, showSidebarMobile }),
    [shouldShowSidebarMobile, hideSidebarMobile, showSidebarMobile],
  );

  return <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>;
};

export { MenuProvider, MenuContext };
