import { AxiosInstance } from 'axios';

import { IConfigProps } from '@equites/api/config';

import { configuration, Configuration } from '@configurations/services/Configurations';

import { appointment, Appointment } from '@appointments/services';

import { affiliates, Affiliates } from '@associations/services/Affiliates';
import { association, Association } from '@associations/services/Association';
import { invite, Invite } from '@associations/services/Invites';
import { person, Person } from '@associations/services/Persons';

import { balance, Balance } from '@balances/services/Balances';
import { balanceCategory, BalanceCategory } from '@balances/services/Categories';
import { wallet, Wallet } from '@balances/services/Wallets';

import { bank, Bank } from '@banks/services/Banks';

import { company, Company } from '@companies/services/Companies';

import { dashboard, Dashboard } from '@dashboard/services/Dashboard';

import { food, Food } from '@foods/services/Foods';

import { feedHorse, FeedHorse } from '@horses/services/Feeds';
import { Horse, horse } from '@horses/services/Horses';

import { modality, Modality } from '@modalities/services/Modalities';
import { modalityCategory, ModalityCategory } from '@modalities/services/ModalityCategories';
import {
  modalityCategoryAssociation,
  ModalityCategoryAssociation,
} from '@modalities/services/ModalityCategoriesAssociations';

import { PaymentMethods, paymentMethods } from '@paymentMethods/services/PaymentMethods';

import { rider, Rider } from '@riders/services';

import { teacher, Teacher } from '@teachers/services';

import { License, license } from '@users/services/Licenses';
import { Password, password } from '@users/services/Passwords';
import { profile, Profile } from '@users/services/Profile';
import { sessions, Sessions } from '@users/services/Sessions';
import { subscription, Subscription } from '@users/services/Subscriptions';
import { TeamUser, teamUser } from '@users/services/Team';
import { Users, users } from '@users/services/Users';

import config from './config';
import { zipCode, ZipCode } from './zipCode';

export class SecretariatAPI {
  private instance: AxiosInstance;

  constructor(params: IConfigProps) {
    this.instance = config(params).instance();
  }

  affiliates(): Affiliates {
    return affiliates(this.instance);
  }

  appointment(): Appointment {
    return appointment(this.instance);
  }

  association(): Association {
    return association(this.instance);
  }

  balance(): Balance {
    return balance(this.instance);
  }

  balanceCategory(): BalanceCategory {
    return balanceCategory(this.instance);
  }

  banks(): Bank {
    return bank(this.instance);
  }

  company(): Company {
    return company(this.instance);
  }

  configuration(): Configuration {
    return configuration(this.instance);
  }

  dashboard(): Dashboard {
    return dashboard(this.instance);
  }

  feedHorse(): FeedHorse {
    return feedHorse(this.instance);
  }

  food(): Food {
    return food(this.instance);
  }

  horse(): Horse {
    return horse(this.instance);
  }

  invite(): Invite {
    return invite(this.instance);
  }

  license(): License {
    return license(this.instance);
  }

  modality(): Modality {
    return modality(this.instance);
  }

  modalityCategory(): ModalityCategory {
    return modalityCategory(this.instance);
  }

  modalityCategoryAssociation(): ModalityCategoryAssociation {
    return modalityCategoryAssociation(this.instance);
  }

  password(): Password {
    return password(this.instance);
  }

  paymentMethods(): PaymentMethods {
    return paymentMethods(this.instance);
  }

  person(): Person {
    return person(this.instance);
  }

  profile(): Profile {
    return profile(this.instance);
  }

  rider(): Rider {
    return rider(this.instance);
  }

  sessions(): Sessions {
    return sessions(this.instance);
  }

  subscriptions(): Subscription {
    return subscription(this.instance);
  }

  teacher(): Teacher {
    return teacher(this.instance);
  }

  teamUser(): TeamUser {
    return teamUser(this.instance);
  }

  users(): Users {
    return users(this.instance);
  }

  wallet(): Wallet {
    return wallet(this.instance);
  }

  zipCode(): ZipCode {
    return zipCode(this.instance);
  }
}

const api = (params: IConfigProps): SecretariatAPI => new SecretariatAPI(params);

export default api;
