import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseOdontologyContext } from '@horses/types/Odontologist/context';
import { IHorseOdontology } from '@horses/types/Odontologist/odontology';
import {
  ICreateHorseOdontologistRequest,
  IDeleteHorseOdontologistParams,
  IGetHorseOdontologistParams,
  IUpdateHorseOdontologistRequest,
} from '@horses/types/Odontologist/requests';

import { useLoader } from '@loader/hooks/useLoader';

const HorseOdontologyContext = createContext<IHorseOdontologyContext>({} as IHorseOdontologyContext);

HorseOdontologyContext.displayName = 'HorseOdontologist';

const HorseOdontologyProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [odontologist, setOdontologist] = useState<IHorseOdontology[]>([]);

  const getHorseOdontologist = useCallback(
    async (params: IGetHorseOdontologistParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseOdontologist(params);

        const items = response.data.map(odontology => {
          const dateFormatted = format(parseISO(odontology.date), 'dd/MM/yyyy');
          const goBackDateFormatted = odontology.goBackDate
            ? format(parseISO(odontology.goBackDate), 'dd/MM/yyyy')
            : '';
          return { ...odontology, dateFormatted, goBackDateFormatted };
        });

        setOdontologist(items);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseOdontology = useCallback(
    async (data: ICreateHorseOdontologistRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorseOdontology(data);

        const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');
        const goBackDateFormatted = response.data.goBackDate
          ? format(parseISO(response.data.goBackDate), 'dd/MM/yyyy')
          : '';

        setOdontologist(current => [...current, { ...response.data, dateFormatted, goBackDateFormatted }]);

        const item = t('odontology', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateHorseOdontology = useCallback(
    async (data: IUpdateHorseOdontologistRequest) => {
      try {
        showLoader();

        const response = await api.horse().updateHorseOdontology(data);

        setOdontologist(current => {
          const items = [...current];
          const index = items.findIndex(item => item.id === data.id);

          const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');
          const goBackDateFormatted = response.data.goBackDate
            ? format(parseISO(response.data.goBackDate), 'dd/MM/yyyy')
            : '';

          items[index] = { ...response.data, dateFormatted, goBackDateFormatted };
          return items;
        });

        const item = t('odontology', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorseOdontology = useCallback(
    async (params: IDeleteHorseOdontologistParams) => {
      try {
        showLoader();

        await api.horse().deleteHorseOdontology(params);

        setOdontologist(current => current.filter(item => item.id !== params.id));

        const item = t('odontology', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorseOdontologyContext>(
    () => ({
      odontologist,
      createHorseOdontology,
      deleteHorseOdontology,
      getHorseOdontologist,
      updateHorseOdontology,
    }),
    [createHorseOdontology, deleteHorseOdontology, getHorseOdontologist, odontologist, updateHorseOdontology],
  );

  return <HorseOdontologyContext.Provider value={contextValue}>{children}</HorseOdontologyContext.Provider>;
};

export { HorseOdontologyProvider, HorseOdontologyContext };
