import styled from 'styled-components';

export const Container = styled.div``;

export const Logo = styled.img`
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;
