import { useContextSelector } from 'use-context-selector';

import { LoaderContext } from '@loader/contexts/Loader';
import { ILoaderContext } from '@loader/types/Loader/context';

export function useLoader(): ILoaderContext {
  const isActive = useContextSelector(LoaderContext, cs => cs.isActive);

  const showLoader = useContextSelector(LoaderContext, cs => cs.showLoader);
  const hideLoader = useContextSelector(LoaderContext, cs => cs.hideLoader);

  return { isActive, showLoader, hideLoader };
}
