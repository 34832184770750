import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IWalletContext } from '@balances/types/Wallets/context';
import {
  ICreateWalletRequest,
  IGetWalletsRequest,
  IGetWalletsOptions,
  IUpdateWalletRequest,
} from '@balances/types/Wallets/requests';
import { IWallet } from '@balances/types/Wallets/wallets';

import { useLoader } from '@loader/hooks/useLoader';

const BalanceWalletsContext = createContext<IWalletContext>({} as IWalletContext);

BalanceWalletsContext.displayName = 'BalanceWallets';

const BalanceWalletsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'balances']);

  const { showLoader, hideLoader } = useLoader();

  const [wallet, setWallet] = useState<IWallet>({} as IWallet);
  const [wallets, setWallets] = useState<IWallet[]>([]);

  const createWallet = useCallback(
    async (data: ICreateWalletRequest) => {
      try {
        showLoader();

        const response = await api.wallet().create(data);

        setWallets(old => [...old, response.data]);

        const item = t('wallet', { ns: 'balances' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const getWallets = useCallback(
    async (params?: IGetWalletsRequest, options?: IGetWalletsOptions) => {
      try {
        showLoader();

        const response = await api.wallet().getAll(params);

        const showAll = options?.showAll ?? false;

        const items = showAll ? response.data : response.data.filter(w => w.active);

        setWallets(items);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const updateWallet = useCallback(
    async (data: IUpdateWalletRequest) => {
      try {
        showLoader();

        const response = await api.wallet().update(data);

        setWallets(old => {
          const items = old;
          const index = items.findIndex(w => w.id === response.data.id);
          items[index] = { ...items[index], ...response.data };
          return items;
        });

        const item = t('wallet', { ns: 'balances' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const setWalletItem = useCallback((data: IWallet) => {
    setWallet(data);
  }, []);

  const contextValue = useMemo<IWalletContext>(
    () => ({ wallet, wallets, createWallet, getWallets, setWallet: setWalletItem, updateWallet }),
    [createWallet, getWallets, setWalletItem, updateWallet, wallet, wallets],
  );

  return <BalanceWalletsContext.Provider value={contextValue}>{children}</BalanceWalletsContext.Provider>;
};

export { BalanceWalletsContext, BalanceWalletsProvider };
