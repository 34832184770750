import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IBalanceReportsContext } from '@balances/types/Reports/context';
import {
  IBalanceReportDaily,
  IBalanceReportMonthly,
  IBalanceReportByCategory,
  IBalanceReportFilter,
} from '@balances/types/Reports/reports';
import {
  IGetBalancesDailyParams,
  IGetBalancesMonthlyParams,
  IGetBalancesCategoriesParams,
} from '@balances/types/Reports/requests';

import { useLoader } from '@loader/hooks/useLoader';

const BalanceReportsContext = createContext<IBalanceReportsContext>({} as IBalanceReportsContext);
BalanceReportsContext.displayName = 'BalanceReports';

const BalanceReportsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [balancesDaily, setBalancesDaily] = useState<IBalanceReportDaily[]>([]);
  const [balancesMonthly, setBalancesMonthly] = useState<IBalanceReportMonthly>({} as IBalanceReportMonthly);
  const [balancesCategories, setBalancesCategories] = useState<IBalanceReportByCategory>(
    {} as IBalanceReportByCategory,
  );
  const [filter, setFilter] = useState<IBalanceReportFilter>({} as IBalanceReportFilter);

  const getBalancesDaily = useCallback(
    async (params: IGetBalancesDailyParams) => {
      try {
        showLoader();

        const response = await api.balance().getReportBalancesDaily(params);

        setBalancesDaily(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesDailyCSV = useCallback(
    async (params: IGetBalancesDailyParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesDailyCSV(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesDailyPDF = useCallback(
    async (params: IGetBalancesDailyParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesDailyPDF(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesMonthly = useCallback(
    async (params: IGetBalancesMonthlyParams) => {
      try {
        showLoader();

        const response = await api.balance().getReportBalancesMonthly(params);

        setBalancesMonthly(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesMonthlyCSV = useCallback(
    async (params: IGetBalancesMonthlyParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesMonthlyCSV(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesMonthlyPDF = useCallback(
    async (params: IGetBalancesMonthlyParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesMonthlyPDF(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesCategories = useCallback(
    async (params: IGetBalancesCategoriesParams) => {
      try {
        showLoader();

        const response = await api.balance().getReportBalancesCategories(params);

        setBalancesCategories(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesCategoriesCSV = useCallback(
    async (params: IGetBalancesCategoriesParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesCategoriesCSV(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesCategoriesPDF = useCallback(
    async (params: IGetBalancesCategoriesParams) => {
      try {
        showLoader();

        await api.balance().getReportBalancesCategoriesPDF(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const handleBalanceReportFilter = useCallback((data: IBalanceReportFilter, reset?: boolean) => {
    setFilter(current => (reset ? { ...data } : { ...current, ...data }));
  }, []);

  const handleSubmitFilter = useCallback(
    async (filterParams: IBalanceReportFilter) => {
      try {
        showLoader();

        const { tab, period, ...params } = filterParams;

        if (tab === 'EARNINGS_EXPENSES') {
          if (period === 'DAILY') {
            const response = await api.balance().getReportBalancesDaily(params);

            setBalancesDaily(response.data);
            setBalancesMonthly({} as IBalanceReportMonthly);
            setBalancesCategories({} as IBalanceReportByCategory);
          }

          if (period === 'MONTHLY') {
            const response = await api.balance().getReportBalancesMonthly(params);
            setBalancesMonthly(response.data);
            setBalancesDaily([]);
            setBalancesCategories({} as IBalanceReportByCategory);
          }
        }

        if (tab === 'CATEGORIES') {
          const response = await api.balance().getReportBalancesCategories(params);
          setBalancesCategories(response.data);
          setBalancesDaily([]);
          setBalancesMonthly({} as IBalanceReportMonthly);
        }
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IBalanceReportsContext>(
    () => ({
      balancesCategories,
      balancesDaily,
      balancesMonthly,
      filter,
      getBalancesCategories,
      getBalancesCategoriesCSV,
      getBalancesCategoriesPDF,
      getBalancesDaily,
      getBalancesDailyCSV,
      getBalancesDailyPDF,
      getBalancesMonthly,
      getBalancesMonthlyCSV,
      getBalancesMonthlyPDF,
      handleBalanceReportFilter,
      handleSubmitFilter,
    }),
    [
      balancesCategories,
      balancesDaily,
      balancesMonthly,
      filter,
      getBalancesCategories,
      getBalancesCategoriesCSV,
      getBalancesCategoriesPDF,
      getBalancesDaily,
      getBalancesDailyCSV,
      getBalancesDailyPDF,
      getBalancesMonthly,
      getBalancesMonthlyCSV,
      getBalancesMonthlyPDF,
      handleBalanceReportFilter,
      handleSubmitFilter,
    ],
  );

  return <BalanceReportsContext.Provider value={contextValue}>{children}</BalanceReportsContext.Provider>;
};

export { BalanceReportsProvider, BalanceReportsContext };
