import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IRidersContext } from '@riders/types/context';
import {
  ICreateRiderRequest,
  IUpdateRiderRequest,
  IDeleteRiderRequest,
  IUpdateRiderAvatarRequest,
} from '@riders/types/requests';
import { IRider } from '@riders/types/riders';

const RidersContext = createContext<IRidersContext>({} as IRidersContext);

RidersContext.displayName = 'Riders';

const RidersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'riders']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [rider, setRider] = useState<IRider>({} as IRider);
  const [riders, setRiders] = useState<IRider[]>([]);
  const [riderPhotoFile, setRiderPhotoFile] = useState<File>();

  const getRiders = useCallback(async () => {
    try {
      showLoader();

      const response = await api.rider().getAll();
      setRiders(response.data);

      setRider({} as IRider);
      setRiderPhotoFile(undefined);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const getRiderById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.rider().getById(id);

        setRider(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createRider = useCallback(
    async (data: ICreateRiderRequest) => {
      try {
        showLoader();

        const response = await api.rider().createRider(data);

        if (riderPhotoFile) await api.rider().updateRiderAvatar(response.data.id, riderPhotoFile);

        navigate(`/riders`);

        const isMale = response.data.gender === 'M';
        const item = isMale ? t('knight', { ns: 'riders' }) : t('amazon', { ns: 'riders' });
        const context = isMale ? 'male' : 'female';
        toast(t('crud.created_success', { ns: 'messages', context, item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, riderPhotoFile, showLoader, t],
  );

  const updateRider = useCallback(
    async (data: IUpdateRiderRequest) => {
      try {
        showLoader();

        const response = await api.rider().updateRider(data);

        setRider(response.data);

        const isMale = response.data.gender === 'M';
        const item = isMale ? t('knight', { ns: 'riders' }) : t('amazon', { ns: 'riders' });
        const context = isMale ? 'male' : 'female';
        toast(t('crud.updated_success', { ns: 'messages', context, item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteRider = useCallback(
    async (data: IDeleteRiderRequest) => {
      try {
        showLoader();

        await api.rider().deleteRider(data);

        const isMale = rider.gender === 'M';
        const item = isMale ? t('knight', { ns: 'riders' }) : t('amazon', { ns: 'riders' });
        const context = isMale ? 'male' : 'female';
        toast(t('crud.deleted_success', { ns: 'messages', context, item }), { type: 'success' });

        navigate(`/riders`);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, rider.gender, t, navigate, hideLoader],
  );

  const updateRiderAvatar = useCallback(
    async (data: IUpdateRiderAvatarRequest) => {
      try {
        showLoader();

        const response = await api.rider().updateRiderAvatar(data.id, data.file);

        const isMale = response.data.gender === 'M';
        const item = isMale ? t('knight', { ns: 'riders' }) : t('amazon', { ns: 'riders' });
        const context = isMale ? 'male' : 'female';
        toast(t('crud.updated_success', { ns: 'messages', context, item }), { type: 'success' });

        setRiderPhotoFile(data.file);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const handleRiderPhoto = useCallback((file?: File) => {
    setRiderPhotoFile(file);
  }, []);

  const contextValue = useMemo<IRidersContext>(
    () => ({
      rider,
      riderPhotoFile,
      riders,
      createRider,
      deleteRider,
      getRiderById,
      getRiders,
      handleRiderPhoto,
      updateRider,
      updateRiderAvatar,
    }),
    [
      createRider,
      deleteRider,
      getRiderById,
      getRiders,
      handleRiderPhoto,
      rider,
      riderPhotoFile,
      riders,
      updateRider,
      updateRiderAvatar,
    ],
  );

  return <RidersContext.Provider value={contextValue}>{children}</RidersContext.Provider>;
};

export { RidersProvider, RidersContext };
