import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IRiderLessonPackageContext } from '@riders/types/context';
import { ICreateRiderLessonPackageRequest, IUpdateRiderLessonPackageRequest } from '@riders/types/requests';
import { IRiderLessonPackage } from '@riders/types/riders';

const RiderLessonPackageContext = createContext<IRiderLessonPackageContext>({} as IRiderLessonPackageContext);

RiderLessonPackageContext.displayName = 'RiderLessonPackage';

const RiderLessonPackageProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'riders']);

  const { showLoader, hideLoader } = useLoader();

  const [lessonPackage, setLessonPackage] = useState<IRiderLessonPackage>({} as IRiderLessonPackage);

  const getLessonPackage = useCallback(
    async (riderId: string) => {
      try {
        showLoader();

        const response = await api.rider().getLessonPackageByRider(riderId);
        setLessonPackage(response.data);
      } catch (err) {
        handleErrorApi({ err, otherStatus: [404] });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createLessonPackage = useCallback(
    async (data: ICreateRiderLessonPackageRequest) => {
      try {
        showLoader();

        const response = await api.rider().createLessonPackage(data);
        setLessonPackage(response.data);

        const item = t('lessons_package', { ns: 'riders' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateLessonPackage = useCallback(
    async (data: IUpdateRiderLessonPackageRequest) => {
      try {
        showLoader();

        const response = await api.rider().updateLessonPackage(data);
        setLessonPackage(response.data);

        const item = t('lessons_package', { ns: 'riders' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const handleLessonPackage = useCallback((data: IRiderLessonPackage, reset?: boolean) => {
    setLessonPackage(current => (reset ? data : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IRiderLessonPackageContext>(
    () => ({ lessonPackage, createLessonPackage, getLessonPackage, updateLessonPackage, handleLessonPackage }),
    [createLessonPackage, getLessonPackage, handleLessonPackage, lessonPackage, updateLessonPackage],
  );

  return <RiderLessonPackageContext.Provider value={contextValue}>{children}</RiderLessonPackageContext.Provider>;
};

export { RiderLessonPackageProvider, RiderLessonPackageContext };
