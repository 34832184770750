import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { TeachersForm } = lazily(() => import('@teachers/pages/TeachersForm'));
const { TeachersList } = lazily(() => import('@teachers/pages/TeachersList'));

export default [
  {
    component: TeachersList,
    path: 'teachers',
    title: 'private.teachers',
    permission: Roles.GET_TEACHERS,
    canAccess: true,
  },
  {
    component: TeachersForm,
    path: 'teachers/:id/edit',
    title: 'private.teachers_edit',
    permission: Roles.UPDATE_TEACHER,
    canAccess: true,
  },
  {
    component: TeachersForm,
    path: 'teachers/new',
    title: 'private.teachers_new',
    permission: Roles.CREATE_TEACHER,
    canAccess: true,
  },
] as IRoute[];
