import { AxiosInstance, AxiosResponse } from 'axios';

import { IModality } from '@modalities/types/Modalities/modalities';

export class Modality {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getModalities(): Promise<AxiosResponse<IModality[]>> {
    return this.instance.get(`/modalities`);
  }
}

export const modality = (instance: AxiosInstance): Modality => new Modality(instance);
