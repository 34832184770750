import { BalanceCategoriesProvider } from './BalanceCategories';
import { BalanceReportsProvider } from './BalanceReports';
import { BalancesProvider } from './Balances';
import { BalanceWalletsProvider } from './BalanceWallets';

export const AppBalanceProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <BalancesProvider>
      <BalanceCategoriesProvider>
        <BalanceWalletsProvider>
          <BalanceReportsProvider>{children}</BalanceReportsProvider>
        </BalanceWalletsProvider>
      </BalanceCategoriesProvider>
    </BalancesProvider>
  );
};
