import { AffiliatesProvider } from './Affiliates';
import { AffiliatesAthletesProvider } from './AffiliatesAthletes';
import { AffiliatesHorsesProvider } from './AffiliatesHorses';
import { AffiliatesMicrochipProvider } from './AffiliatesMicrochip';
import { AffiliatesPublicProvider } from './AffiliatesPublic';
import { AffiliatesSealProvider } from './AffiliatesSeal';
import { AssociationProvider } from './Association';
import { AssociationPublicProvider } from './AssociationPublic';
import { InvitesProvider } from './Invite';
import { PersonsProvider } from './Persons';

export const AppAssociationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AssociationProvider>
      <AssociationPublicProvider>
        <AffiliatesPublicProvider>
          <AffiliatesProvider>
            <AffiliatesAthletesProvider>
              <AffiliatesMicrochipProvider>
                <AffiliatesSealProvider>
                  <AffiliatesHorsesProvider>
                    <InvitesProvider>
                      <PersonsProvider>{children}</PersonsProvider>
                    </InvitesProvider>
                  </AffiliatesHorsesProvider>
                </AffiliatesSealProvider>
              </AffiliatesMicrochipProvider>
            </AffiliatesAthletesProvider>
          </AffiliatesProvider>
        </AffiliatesPublicProvider>
      </AssociationPublicProvider>
    </AssociationProvider>
  );
};
