import { Link as LinkReactRouterDom, LinkProps } from 'react-router-dom';

interface ILinkProps extends LinkProps {
  to: string;
}

const Link: React.FC<React.PropsWithChildren<ILinkProps>> = ({ children, to, ...props }) => {
  return (
    <LinkReactRouterDom {...props} to={{ pathname: to }}>
      {children}
    </LinkReactRouterDom>
  );
};

export default Link;
