import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import { Storage } from '@constants/Storage';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';
import usePersistedState from '@utils/usePersistedState';

import { useLoader } from '@loader/hooks/useLoader';

import { IUserContext } from '@users/types/Users/context';
import { IUpdatePasswordRequest } from '@users/types/Users/requests';
import { ISubscription, IUser, IUserCompany } from '@users/types/Users/users';

const UsersContext = createContext<IUserContext>({} as IUserContext);

UsersContext.displayName = 'Users';

const UsersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['profile', 'common']);

  const { showLoader, hideLoader } = useLoader();

  const [user, setUser] = usePersistedState<IUser | null>(Storage.USER, null);
  const [company, setUserCompany] = usePersistedState<IUserCompany | null>(Storage.COMPANY, null);
  const [subscription, setUserSubscription] = usePersistedState<ISubscription | null>(Storage.SUBSCRIPTION, null);
  const [permissions, setPermissions] = usePersistedState<string[]>(Storage.PERMISSIONS, []);

  const handleUserAuthenticated = useCallback(
    (data: IUser) => {
      setUser(data);
    },
    [setUser],
  );

  const handleUserCompany = useCallback(
    (data: IUserCompany) => {
      setUserCompany(data);
    },
    [setUserCompany],
  );

  const handleUserSubscription = useCallback(
    (data: ISubscription) => {
      setUserSubscription(data);
    },
    [setUserSubscription],
  );

  const handleUserPermissions = useCallback(
    (data: string[]) => {
      setPermissions(data);
    },
    [setPermissions],
  );

  const updateUserAvatar = useCallback(
    async (file: File) => {
      try {
        showLoader();

        const response = await api.users().updateAvatar(file);

        setUser(response.data);

        const item = t('picture', { ns: 'common' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, setUser, showLoader, t],
  );

  const updateUserPassword = useCallback(
    async (data: IUpdatePasswordRequest) => {
      try {
        showLoader();

        if (user) {
          await api.profile().update({ ...data, name: user.name, email: user.email });

          const item = t('password', { ns: 'profile' });
          toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
        }
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t, user],
  );

  const contextValue = useMemo<IUserContext>(
    () => ({
      company,
      permissions,
      subscription,
      user,
      handleUserAuthenticated,
      handleUserCompany,
      handleUserPermissions,
      handleUserSubscription,
      updateUserAvatar,
      updateUserPassword,
    }),
    [
      company,
      handleUserAuthenticated,
      handleUserCompany,
      handleUserPermissions,
      handleUserSubscription,
      permissions,
      subscription,
      updateUserAvatar,
      updateUserPassword,
      user,
    ],
  );

  return <UsersContext.Provider value={contextValue}>{children}</UsersContext.Provider>;
};

export { UsersContext, UsersProvider };
