import { lazily } from 'react-lazily';

import { IRoute } from '@routes/index';

const { CompetitionsDetails } = lazily(() => import('@competitions/pages/CompetitionsDetail'));
const { CompetitionsHorse } = lazily(() => import('@competitions/pages/CompetitionsHorse'));
const { CompetitionsList } = lazily(() => import('@competitions/pages/CompetitionsList'));

export default [
  {
    component: CompetitionsList,
    path: 'competitions',
    title: 'private.competitions',
    // TODO: permission here
    // permission:
  },
  {
    component: CompetitionsDetails,
    // path: 'competitions/details/:id',
    path: 'competitions/details',
    title: 'private.competitions_details',
    // TODO: permission here
    // permission:
  },
  {
    component: CompetitionsHorse,
    // path: 'competitions/details/:id',
    path: 'competitions/details/horse',
    title: 'private.competitions_details_horse',
    // TODO: permission here
    // permission:
  },
] as IRoute[];
