import { lighten, rgba } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  $isSelected?: boolean;
  $menuActive?: boolean;
}

export const Container = styled.li<IContainerProps>`
  background-color: ${props => props.theme.colors.background};
  box-shadow: none;
  position: relative;
  cursor: pointer;
  transition: all 0.2s;
  > button {
    background-color: transparent;
    border: none;
  }
  a,
  button {
    .indicator {
      margin-left: auto;
      transition: all 0.2s;
      transform: ${props => (props.$isSelected ? 'rotateX(180deg)' : 'rotateX(0deg)')};
    }
    &:hover {
      background-color: ${props => lighten(0.2, props.theme.colors.grayLight)};
    }
    align-items: center;

    display: flex;
    justify-content: ${props => (props.$menuActive ? 'left' : 'center')};
    padding: 4px 1.5rem;
    width: 100%;
    height: 60px;

    svg {
      color: ${props => props.theme.colors.gray};
    }

    span {
      color: ${props => props.theme.colors.gray};
      font-size: 1.2rem;
      font-weight: 500;
      margin-left: 0.8rem;
      text-align: center;
    }
  }

  ${props =>
    props.$isSelected &&
    css`
      a,
      button {
        &:hover {
          background-color: ${rgba(props.theme.colors.primary, 0.25)};
        }
        svg {
          color: ${props.theme.colors.primary};
        }

        span {
          color: ${props.theme.colors.primary};
        }
      }
    `}
`;
export const SubItems = styled.div<IContainerProps>`
  display: flex;
  cursor: pointer;
  transition: all 0.4s ease;
  flex-direction: column;
`;

export const SubItemContainer = styled.li<IContainerProps>`
  background-color: ${props => props.theme.colors.background};
  box-shadow: none;
  position: relative;
  cursor: pointer;
  a {
    &:hover {
      background-color: ${props => lighten(0.2, props.theme.colors.grayLight)};
    }
    align-items: center;

    display: flex;
    justify-content: ${props => (props.$menuActive ? 'left' : 'center')};
    padding: 4px 1.5rem;
    width: 100%;
    height: 40px;

    svg {
      color: ${props => props.theme.colors.gray};
    }

    span {
      color: ${props => props.theme.colors.gray};
      font-size: 1.2rem;
      font-weight: 500;
      margin-left: 0.8rem;
      text-align: center;
      padding-left: 0.8rem;
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid
        ${props => (props.$isSelected ? props.theme.colors.primary : props.theme.colors.grayExtraLight)};
    }
  }

  ${props =>
    props.$isSelected &&
    css`
      &:hover {
        background-color: ${rgba(props.theme.colors.primary, 0.25)};
      }

      a {
        svg {
          color: ${props.theme.colors.primary};
        }

        span {
          color: ${props.theme.colors.primary};
        }
      }
    `}
`;
