/* eslint-disable import/no-duplicates */

import { useCallback, useMemo, useState } from 'react';

import { formatDistance } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { createContext } from 'use-context-selector';

import { INotificationsContext } from '@notifications/types/Notifications/context';
import { INotification } from '@notifications/types/Notifications/notifications';

const NotificationsContext = createContext<INotificationsContext>({} as INotificationsContext);

NotificationsContext.displayName = 'Notifications';

const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const getDistanceCreatedAt = (createdAt: string): string => {
    const date = new Date(createdAt);
    return formatDistance(date, new Date(), { addSuffix: true, locale: ptBr });
  };

  const [notifications] = useState<INotification[]>([
    {
      id: '1',
      title: 'Novo evento criado',
      description: 'Participe do campeonato',
      createdAt: '2022-07-12T10:00:00-03:00',
      createdAtFormatted: getDistanceCreatedAt('2022-07-12T10:00:00-03:00'),
      isRead: false,
    },
    {
      id: '2',
      title: 'Novo evento criado',
      description: 'Participe do campeonato',
      createdAt: '2022-07-12T15:00:00-03:00',
      createdAtFormatted: getDistanceCreatedAt('2022-07-12T15:00:00-03:00'),
      isRead: false,
    },
    {
      id: '3',
      title: 'Novo evento criado',
      description: 'Participe do campeonato',
      createdAt: '2022-07-10T10:00:00-03:00',
      createdAtFormatted: getDistanceCreatedAt('2022-07-10T10:00:00-03:00'),
      isRead: false,
    },
    {
      id: '4',
      title: 'Novo evento criado',
      description: 'Participe do campeonato',
      createdAt: '2022-07-10T10:00:00-03:00',
      createdAtFormatted: getDistanceCreatedAt('2022-07-10T10:00:00-03:00'),
      isRead: true,
    },
  ]);
  const [notificationsSelected, setNotificationsSelected] = useState<INotification[]>([]);
  const [shouldShowSummaryNotifications, setShouldShowSummaryNotifications] = useState(false);

  const showSummaryNotifications = useCallback(() => {
    setShouldShowSummaryNotifications(true);
  }, []);

  const hideSummaryNotifications = useCallback(() => {
    setShouldShowSummaryNotifications(false);
  }, []);

  const handleNotificationSelected = useCallback((notification: INotification) => {
    setNotificationsSelected(currentNotifications =>
      currentNotifications.map(({ id }) => id).includes(notification.id)
        ? currentNotifications.filter(({ id }) => id !== notification.id)
        : [...currentNotifications, notification],
    );
  }, []);

  const cleanNotificationsSelected = useCallback(() => {
    setNotificationsSelected([]);
  }, []);

  const contextValue = useMemo<INotificationsContext>(
    () => ({
      notifications,
      notificationsSelected,
      showSummaryNotifications,
      cleanNotificationsSelected,
      handleNotificationSelected,
      hideSummaryNotifications,
      shouldShowSummaryNotifications,
    }),
    [
      cleanNotificationsSelected,
      handleNotificationSelected,
      hideSummaryNotifications,
      notifications,
      notificationsSelected,
      shouldShowSummaryNotifications,
      showSummaryNotifications,
    ],
  );

  return <NotificationsContext.Provider value={contextValue}>{children}</NotificationsContext.Provider>;
};

export { NotificationsProvider, NotificationsContext };
