import { AxiosInstance, AxiosResponse } from 'axios';

import { IFood } from '@foods/types/Foods/food';
import { ICreateFoodRequest, IUpdateFoodRequest } from '@foods/types/Foods/requests';

export class Food {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getFoodById(id: string): Promise<AxiosResponse<IFood>> {
    return this.instance.get(`/foods/${id}`);
  }

  getFoods(): Promise<AxiosResponse<IFood[]>> {
    return this.instance.get(`/foods`);
  }

  createFood(data: ICreateFoodRequest): Promise<AxiosResponse<IFood>> {
    return this.instance.post(`/foods`, data);
  }

  updateFood(data: IUpdateFoodRequest): Promise<AxiosResponse<IFood>> {
    const { id, ...body } = data;
    return this.instance.patch(`/foods/${id}`, body);
  }
}

export const food = (instance: AxiosInstance): Food => new Food(instance);
