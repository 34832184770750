import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';
import { IPermissionsContext } from '@users/types/Permissions/context';
import { IPermission } from '@users/types/Permissions/permissions';

const PermissionsContext = createContext<IPermissionsContext>({} as IPermissionsContext);

PermissionsContext.displayName = 'Permissions';

const PermissionsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [permissions, setPermissions] = useState<IPermission[]>([]);

  const { user } = useUsers();

  const getPermissions = useCallback(async () => {
    try {
      showLoader();

      const response = await api.teamUser().getPermissions();

      const isAssociation = user?.types[0].type === 'ASSOCIATION';

      const permissionsFiltered = response.data.filter(permission => {
        if (isAssociation)
          return permission.visible.filter(p => p.userType === 'ASSOCIATION').length > 0 && permission.active;

        return permission.visible.filter(p => p.userType === 'COMPANY').length > 0 && permission.active;
      });

      setPermissions(permissionsFiltered);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader, user]);

  const contextValue = useMemo<IPermissionsContext>(
    () => ({ permissions, getPermissions }),
    [getPermissions, permissions],
  );

  return <PermissionsContext.Provider value={contextValue}>{children}</PermissionsContext.Provider>;
};

export { PermissionsProvider, PermissionsContext };
