import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IModalitiesContext } from '@modalities/types/Modalities/context';
import { IModality } from '@modalities/types/Modalities/modalities';

const ModalitiesContext = createContext<IModalitiesContext>({} as IModalitiesContext);

ModalitiesContext.displayName = 'Modalities';

const ModalitiesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [modalities, setModalities] = useState<IModality[]>([]);

  const getModalities = useCallback(async () => {
    try {
      showLoader();

      const response = await api.modality().getModalities();

      setModalities(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const contextValue = useMemo<IModalitiesContext>(() => ({ modalities, getModalities }), [modalities, getModalities]);

  return <ModalitiesContext.Provider value={contextValue}>{children}</ModalitiesContext.Provider>;
};

export { ModalitiesProvider, ModalitiesContext };
