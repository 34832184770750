import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import i18n from '@config/Translate';

import Cookie from '@components/Cookie';

import Loader, { Loader as LoaderComponent } from '@loader/components/Loader';

import { AppThemeProvider } from '@theme/contexts';

import withClearCache from './ClearCache';
import Toast from './components/Toast';
import AppProvider from './context';
import EquitesRoutes from './routes';
import GlobalStyles from './styles/global';

const MainApp: React.FC<React.PropsWithChildren> = () => {
  if (process.env.NODE_ENV !== 'production') {
    const tag = `ga-disable-${process.env.REACT_APP_ID_GA}`;
    window[tag] = true;
  }

  return (
    <AppThemeProvider>
      <GlobalStyles />
      <Suspense fallback={<LoaderComponent />}>
        <I18nextProvider i18n={i18n}>
          <AppProvider>
            <EquitesRoutes />
            <Cookie />
            <Toast />
            <Loader />
          </AppProvider>
        </I18nextProvider>
      </Suspense>
    </AppThemeProvider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const App: React.FC<React.PropsWithChildren> = () => {
  return <ClearCacheComponent />;
};

export default App;
