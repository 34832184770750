import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IBalanceCategory } from '@balances/types/Categories/categories';
import { IBalanceCategoryContext } from '@balances/types/Categories/context';
import {
  IGetBalanceCategoriesOptions,
  ICreateBalanceCategoryRequest,
  IUpdateBalanceCategoryRequest,
} from '@balances/types/Categories/requests';

import { useLoader } from '@loader/hooks/useLoader';

const BalanceCategoriesContext = createContext<IBalanceCategoryContext>({} as IBalanceCategoryContext);

BalanceCategoriesContext.displayName = 'BalanceCategories';

const BalanceCategoriesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'balances']);

  const { showLoader, hideLoader } = useLoader();

  const [balanceCategory, setBalanceCategory] = useState<IBalanceCategory>({} as IBalanceCategory);
  const [balanceCategories, setBalanceCategories] = useState<IBalanceCategory[]>([]);

  const getBalanceCategories = useCallback(
    async (options?: IGetBalanceCategoriesOptions) => {
      try {
        showLoader();

        const response = await api.balanceCategory().getAll();

        const showAll = options?.showAll || false;

        const categories = showAll ? response.data : response.data.filter(c => c.active);

        setBalanceCategories(categories);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createBalanceCategory = useCallback(
    async (data: ICreateBalanceCategoryRequest) => {
      try {
        showLoader();

        const response = await api.balanceCategory().create(data);

        setBalanceCategories(old => [...old, response.data]);

        const item = t('category', { ns: 'balances' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateBalanceCategory = useCallback(
    async (data: IUpdateBalanceCategoryRequest) => {
      try {
        showLoader();

        const response = await api.balanceCategory().update(data);

        setBalanceCategories(old => {
          const items = old;
          const index = items.findIndex(bc => bc.id === response.data.id);
          items[index] = response.data;
          return items;
        });

        const item = t('category', { ns: 'balances' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const setCategory = useCallback((category: IBalanceCategory) => {
    setBalanceCategory(category);
  }, []);

  const contextValue = useMemo<IBalanceCategoryContext>(
    () => ({
      balanceCategory,
      balanceCategories,
      createBalanceCategory,
      getBalanceCategories,
      setBalanceCategory: setCategory,
      updateBalanceCategory,
    }),
    [
      balanceCategory,
      balanceCategories,
      createBalanceCategory,
      getBalanceCategories,
      setCategory,
      updateBalanceCategory,
    ],
  );

  return <BalanceCategoriesContext.Provider value={contextValue}>{children}</BalanceCategoriesContext.Provider>;
};

export { BalanceCategoriesContext, BalanceCategoriesProvider };
