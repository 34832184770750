import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { HorsesCreate } = lazily(() => import('@horses/pages/HorsesCreate'));
const { HorsesEdit } = lazily(() => import('@horses/pages/HorsesEdit'));
const { HorsesExpenses } = lazily(() => import('@horses/pages/HorsesExpenses'));
const { HorsesFeed } = lazily(() => import('@horses/pages/HorsesFeed'));
const { HorsesFoods } = lazily(() => import('@horses/pages/HorsesFoods'));
const { HorsesList } = lazily(() => import('@horses/pages/HorsesList'));
const { HorsesMedicalCenter } = lazily(() => import('@horses/pages/HorsesMedicalCenter'));

export default [
  {
    component: HorsesList,
    path: 'stable',
    title: 'private.stable',
    permission: Roles.GET_HORSES,
    canAccess: true,
  },
  {
    component: HorsesEdit,
    path: 'stable/horse/:id/edit',
    title: 'private.stable_edit_horse',
    permission: Roles.UPDATE_HORSE,
    canAccess: true,
  },
  {
    component: HorsesCreate,
    path: 'stable/horse/new',
    title: 'private.stable_new_horse',
    permission: Roles.CREATE_HORSE,
    canAccess: true,
  },
  {
    component: HorsesExpenses,
    path: 'stable/horse/:id/expenses',
    title: 'private.stable_horse_expenses',
    permission: Roles.GET_HORSE_EXPENSES,
    canAccess: true,
  },
  {
    component: HorsesFoods,
    path: 'stable/horse/:id/feed',
    title: 'private.stable_horse_feed',
    permission: Roles.GET_HORSE_FEEDS,
    canAccess: true,
  },
  {
    component: HorsesFeed,
    path: 'stable/feed',
    title: 'private.stable_feed',
    permission: Roles.CREATE_HORSE_FEED,
    canAccess: true,
  },
  {
    component: HorsesMedicalCenter,
    path: 'stable/horse/:id/medical-center',
    title: 'private.stable_horse_medical_center',
    permission: [Roles.GET_HORSE_MEDICINES, Roles.GET_HORSE_EXAMS, Roles.GET_HORSE_VACCINES],
    canAccess: true,
  },
] as IRoute[];
