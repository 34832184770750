import { HmacSHA256, enc } from 'crypto-js';

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
const API_BASE = process.env.REACT_APP_INTERCOM_API_BASE;
const SECRET_KEY = process.env.REACT_APP_INTERCOM_SECRET_KEY || '';

// Initializes Intercom
export const boot = (options?: Intercom_.IntercomSettings): void => {
  const opts = options ?? ({} as Intercom_.IntercomSettings);

  const hashSHA256 = HmacSHA256(opts.email ?? '', SECRET_KEY);
  const user_hash = enc.Hex.stringify(hashSHA256);

  window && window.Intercom && window.Intercom('boot', { app_id: APP_ID, api_base: API_BASE, user_hash, ...opts });
};

// This method just calls Intercom('update'), which should be run on every page
// change. This does two things:
// 1. Send an update to Intercom to create an impression on the current URL
// 2. Fetch any messages that should be delivered based on the URL and user
export const update = (): void => {
  window && window.Intercom && window.Intercom('update');
};

// Clears user session and unloads messages
export const shutdown = (): void => {
  window && window.Intercom && window.Intercom('shutdown');
};
