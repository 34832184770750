import { rgba } from 'polished';
import styled from 'styled-components';

import breakpoints from '@styles/breakpoints';

export const Container = styled.div`
  .Toastify__toast-container {
    width: 416px;

    .Toastify__toast {
      background-color: ${props => props.theme.colors.background};
      box-shadow: 0 8px 40px ${props => rgba(props.theme.colors.black, 0.1)};
      border-radius: 8px;
      font-size: 1.4rem;
      font-weight: 600;
      min-height: 10px;
      padding: 40px 16px;
    }

    .Toastify__toast--success {
      color: ${props => props.theme.colors.primary};
    }

    .Toastify__toast--error {
      color: ${props => props.theme.colors.error};
    }

    .Toastify__toast--warning {
      color: ${props => props.theme.colors.yellowStrong};
    }
  }

  @media ${breakpoints.xs} {
    .Toastify__toast-container {
      margin-bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100vw - 16px);
    }
  }
`;
