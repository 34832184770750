export const Storage = {
  COMPANY: '@Equites:company',
  COMPANY_REGISTER: '@Equites:companyRegister',
  COOKIE: '@Equites:cookie',
  HORSE_LIST_VIEW: '@Equites:horse_list_view',
  LANGUAGE: '@Equites:language',
  PERMISSIONS: '@Equites:permissions',
  SUBSCRIPTION: '@Equites:subscription',
  THEME: '@Equites:theme',
  TOKENS: '@Equites:tokens',
  USER: '@Equites:user',
};
