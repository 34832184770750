import 'react-toastify/dist/ReactToastify.css';

import { memo, useMemo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { ToastContainer, ToastContainerProps } from 'react-toastify';

import { useTheme } from '@theme/hooks/useTheme';

import { Container } from './styles';

const Toast: React.FC<React.PropsWithChildren<ToastContainerProps>> = ({ ...props }) => {
  const { theme } = useTheme();

  const position = useMemo(() => {
    return isMobileOnly ? 'bottom-center' : 'bottom-right';
  }, []);

  return (
    <Container>
      <ToastContainer
        autoClose={5000}
        position={position}
        icon={false}
        theme="light"
        progressStyle={{ backgroundColor: theme.colors.grayExtraLight }}
        {...props}
      />
    </Container>
  );
};

export default memo(Toast);
