import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';
import { IUsersGroupsContext } from '@users/types/Groups/context';
import { IGroup } from '@users/types/Groups/groups';
import { ICreateGroupPermissionsRequest, IUpdateGroupPermissionsRequest } from '@users/types/Groups/requests';

const GroupsContext = createContext<IUsersGroupsContext>({} as IUsersGroupsContext);

GroupsContext.displayName = 'Groups';

const GroupsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'myTeam']);

  const { showLoader, hideLoader } = useLoader();

  const [groups, setGroups] = useState<IGroup[]>([]);

  const { user } = useUsers();

  const getGroups = useCallback(async () => {
    try {
      showLoader();

      const response = await api
        .teamUser()
        .getGroups(user?.types?.filter(type => type.isDefault)[0].type === 'ASSOCIATION');

      setGroups(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader, user]);

  const createGroup = useCallback(
    async (data: ICreateGroupPermissionsRequest) => {
      try {
        showLoader();

        await api.teamUser().createGroup(data);

        await getGroups();

        const item = t('group_permission', { ns: 'myTeam' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getGroups, hideLoader, showLoader, t],
  );

  const updateGroup = useCallback(
    async (data: IUpdateGroupPermissionsRequest) => {
      try {
        showLoader();

        await api.teamUser().updateGroup(data);

        await getGroups();

        const item = t('group_permission', { ns: 'myTeam' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getGroups, hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IUsersGroupsContext>(
    () => ({ groups, getGroups, updateGroup, createGroup }),
    [groups, getGroups, updateGroup, createGroup],
  );

  return <GroupsContext.Provider value={contextValue}>{children}</GroupsContext.Provider>;
};

export { GroupsProvider, GroupsContext };
