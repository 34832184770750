import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import { Storage } from '@constants/Storage';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { ICompany } from '@companies/types/Companies/company';
import { ICompanyContext } from '@companies/types/Companies/context';
import {
  ICreateCompany,
  ICreateCompanyAdditionalInfo,
  IUpdateCompany,
  IUpdateAddress,
  ICreateAddress,
} from '@companies/types/Companies/requests';

import { useLoader } from '@loader/hooks/useLoader';

import { useSubscriptions } from '@users/hooks/useSubscriptions';

const CompaniesContext = createContext<ICompanyContext>({} as ICompanyContext);

CompaniesContext.displayName = 'Companies';

const CompaniesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'companies', 'profile']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();
  const { handleSubscriptionState } = useSubscriptions();

  const [company, setCompany] = useState<ICompany>(() => {
    const companyString = localStorage.getItem(Storage.COMPANY);
    return companyString ? JSON.parse(companyString) : ({} as ICompany);
  });

  const create = useCallback(async (data: ICreateCompany) => {
    try {
      const response = await api.company().create(data);

      sessionStorage.setItem(Storage.COMPANY_REGISTER, JSON.stringify(response.data));

      return true;
    } catch (err) {
      handleErrorApi({ err });
    }

    return false;
  }, []);

  const updateAddInformation = useCallback(
    async (data: ICreateCompanyAdditionalInfo) => {
      try {
        const companyRegister = sessionStorage.getItem(Storage.COMPANY_REGISTER);
        if (companyRegister) {
          const companyStored: ICompany = JSON.parse(companyRegister);
          await api.company().updateAddInformation({ ...data, id: companyStored.id });

          navigate('/login');

          const item = t('account', { ns: 'companies' });
          toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });

          sessionStorage.removeItem(Storage.COMPANY_REGISTER);
        }
      } catch (err) {
        handleErrorApi({ err });
      }
    },
    [navigate, t],
  );

  const getCurrentCompany = useCallback(async () => {
    try {
      const response = await api.company().get();

      setCompany(response.data);
    } catch (err) {
      handleErrorApi({ err });
    }
  }, []);

  const updateCompanyState = useCallback((data: Partial<ICompany>, reset?: boolean) => {
    setCompany(old => (reset ? { ...({} as ICompany), ...data } : { ...old, ...data }));
  }, []);

  const updateCompany = useCallback(
    async (data: IUpdateCompany) => {
      try {
        showLoader();

        const response = await api.company().update(data);

        setCompany(response.data);

        const item = t('profile', { ns: 'profile' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateAddress = useCallback(
    async (data: IUpdateAddress) => {
      try {
        const response = await api.company().updateAddress(data);

        handleSubscriptionState({ address: response.data });

        const item = t('address', { ns: 'address' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      }
    },
    [handleSubscriptionState, t],
  );

  const createAddress = useCallback(
    async (data: ICreateAddress) => {
      try {
        const response = await api.company().createAddress(data);

        handleSubscriptionState({ address: response.data });

        const item = t('address', { ns: 'address' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      }
    },
    [handleSubscriptionState, t],
  );

  const contextValue = useMemo<ICompanyContext>(
    () => ({
      company,
      create,
      createAddress,
      getCurrentCompany,
      updateAddInformation,
      updateAddress,
      updateCompany,
      updateCompanyState,
    }),
    [
      company,
      create,
      createAddress,
      getCurrentCompany,
      updateAddInformation,
      updateAddress,
      updateCompany,
      updateCompanyState,
    ],
  );

  return <CompaniesContext.Provider value={contextValue}>{children}</CompaniesContext.Provider>;
};

export { CompaniesProvider, CompaniesContext };
