import logo from '@assets/img/icon.webp';

import Backdrop from '@components/Modal/Backdrop';
import Spinner from '@components/Spinner';

import { useLoader } from '@loader/hooks/useLoader';

import { Container, Logo } from './styles';

export const Loader: React.FC<React.PropsWithChildren> = () => {
  return (
    <Container>
      <Backdrop animate={{ initial: { opacity: 1 }, animate: { opacity: 1 }, exit: { opacity: 0 } }}>
        <Spinner size={104} />
        <Logo src={logo} alt="Equites" />
      </Backdrop>
    </Container>
  );
};

const LoaderComponent: React.FC<React.PropsWithChildren> = () => {
  const { isActive } = useLoader();
  return isActive ? <Loader /> : null;
};

export default LoaderComponent;
