import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, lastDayOfMonth, parseISO, startOfMonth } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import {
  IBalanceItem,
  IBalanceGeneral,
  IBalanceMonth,
  IBalancesMonth,
  IBalanceFilter,
} from '@balances/types/Balances/balances';
import { IBalanceContext } from '@balances/types/Balances/context';
import {
  IGetBalancesParams,
  IGetBalanceMonthRequest,
  IGetBalancesMonthRequest,
  IReportBalancesCsvParams,
  IReportBalancesPdfParams,
  ICreateBalanceRequest,
  IUpdateBalanceRequest,
  IUploadBalanceAttachmentRequest,
  IDeleteBalanceAttachmentRequest,
} from '@balances/types/Balances/requests';

import { useLoader } from '@loader/hooks/useLoader';

type IBalance = EquitesPaginate<IBalanceItem>;

const BalancesContext = createContext<IBalanceContext>({} as IBalanceContext);
BalancesContext.displayName = 'Balances';

const BalancesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'common']);

  const { showLoader, hideLoader } = useLoader();

  const [balance, setBalance] = useState<IBalance>({ totalItems: 0 } as IBalance);
  const [balancesGeneral, setBalancesGeneral] = useState<IBalanceGeneral[]>([]);
  const [balanceMonth, setBalanceMonth] = useState<IBalanceMonth>({} as IBalanceMonth);
  const [balancesMonth, setBalancesMonth] = useState<IBalancesMonth>({} as IBalancesMonth);
  const [selected, setSelected] = useState<IBalanceItem>({} as IBalanceItem);
  const [filter, setFilter] = useState<IBalanceFilter>({
    limit: 10,
    page: 1,
    from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    to: format(lastDayOfMonth(new Date()), 'yyyy-MM-dd'),
    withPreviousAmountInBalanceAmount: true,
  });

  const formatBalanceItem = (item: IBalanceItem): IBalanceItem => {
    return {
      ...item,
      dateFormatted: format(parseISO(item.date), 'dd/MM/yyyy'),
      dueFormatted: item.due ? format(parseISO(item.due), 'dd/MM/yyyy') : 'Não definido',
      paidFormatted: item.paid ? format(parseISO(item.paid), 'dd/MM/yyyy') : '',
    };
  };

  const formatBalanceItems = useCallback((items: IBalanceItem[]) => {
    return items.map(item => formatBalanceItem(item));
  }, []);

  const getBalances = useCallback(
    async (params: IGetBalancesParams) => {
      try {
        showLoader();

        const response = await api.balance().get(params);

        const items = formatBalanceItems(response.data.items);

        setBalance({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [formatBalanceItems, hideLoader, showLoader],
  );

  const getBalancesGeneral = useCallback(async () => {
    try {
      showLoader();

      const response = await api.balance().getBalancesGeneral();

      setBalancesGeneral(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const getBalanceMonth = useCallback(
    async (params: IGetBalanceMonthRequest) => {
      try {
        showLoader();

        const response = await api.balance().getBalanceMonth(params);

        setBalanceMonth(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getBalancesMonth = useCallback(
    async (params: IGetBalancesMonthRequest) => {
      try {
        showLoader();

        const response = await api.balance().getBalancesMonth(params);

        const balances = response.data;

        const earnings = balances.map(b => b.earnings.amount).reduce((a, b) => a + b, 0);
        const expenses = balances.map(b => b.expense.amount).reduce((a, b) => a + b, 0);
        const balanceTotal = balances.map(b => b.balance.amount).reduce((a, b) => a + b, 0);

        setBalancesMonth({ balances, total: { earnings, expenses, balance: balanceTotal } });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getReportBalancesCsv = useCallback(
    async (params: IReportBalancesCsvParams) => {
      try {
        showLoader();

        await api.balance().getReportCsv(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getReportBalancesPdf = useCallback(
    async (params: IReportBalancesPdfParams) => {
      try {
        showLoader();

        await api.balance().getReportPdf(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createBalance = useCallback(
    async (data: ICreateBalanceRequest) => {
      try {
        showLoader();

        const payload = { ...data };

        delete payload.walletName;
        delete payload.categoryName;

        await api.balance().create(payload);

        await getBalances(filter);

        const year = Number(filter.from.split('-')[0]);
        const month = Number(filter.from.split('-')[1]);
        await getBalanceMonth({
          month,
          year,
          walletId: filter.walletId,
          withPreviousAmountInBalanceAmount: filter.withPreviousAmountInBalanceAmount,
        });

        setSelected({} as IBalanceItem);

        const itemMessage = t('item', { ns: 'common' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item: itemMessage }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, getBalances, filter, getBalanceMonth, t, hideLoader],
  );

  const updateBalance = useCallback(
    async (data: IUpdateBalanceRequest) => {
      try {
        showLoader();

        const payload = { ...data };

        delete payload.walletName;
        delete payload.categoryName;

        await api.balance().update(payload);

        await getBalances(filter);

        const { from } = filter;
        const year = Number(from.split('-')[0]);
        const month = Number(from.split('-')[1]);
        await getBalanceMonth({
          month,
          year,
          walletId: filter.walletId,
          withPreviousAmountInBalanceAmount: filter.withPreviousAmountInBalanceAmount,
        });

        setSelected({} as IBalanceItem);

        const itemMessage = t('item', { ns: 'common' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item: itemMessage }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, getBalances, filter, getBalanceMonth, t, hideLoader],
  );

  const paidBalance = useCallback(
    async (data: IUpdateBalanceRequest) => {
      try {
        showLoader();

        await api.balance().update(data);

        await getBalances(filter);

        const { from } = filter;
        const year = Number(from.split('-')[0]);
        const month = Number(from.split('-')[1]);
        await getBalanceMonth({
          month,
          year,
          walletId: filter.walletId,
          withPreviousAmountInBalanceAmount: filter.withPreviousAmountInBalanceAmount,
        });

        setSelected({} as IBalanceItem);

        const itemMessage = t('item', { ns: 'common' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item: itemMessage }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, getBalances, filter, getBalanceMonth, t, hideLoader],
  );

  const deleteBalance = useCallback(
    async (balanceId: string) => {
      try {
        showLoader();

        await api.balance().delete(balanceId);

        setBalance(current => ({ ...current, items: current.items.filter(i => i.id !== balanceId) }));

        const { from } = filter;
        const year = Number(from.split('-')[0]);
        const month = Number(from.split('-')[1]);
        await getBalanceMonth({
          month,
          year,
          walletId: filter.walletId,
          withPreviousAmountInBalanceAmount: filter.withPreviousAmountInBalanceAmount,
        });

        setSelected({} as IBalanceItem);

        const itemMessage = t('item', { ns: 'common' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item: itemMessage }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, filter, getBalanceMonth, t, hideLoader],
  );

  const setBalanceItemSelected = useCallback((item: IBalanceItem) => {
    setSelected(item);
  }, []);

  const handleFilters = useCallback((filters: IBalanceFilter, resetFilter?: boolean) => {
    setFilter(old => (resetFilter ? filters : { ...old, ...filters }));
  }, []);

  const uploadBalanceAttachment = useCallback(
    async (params: IUploadBalanceAttachmentRequest) => {
      try {
        showLoader();

        const response = await api.balance().uploadAttachment(params);

        setBalance(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === params.balanceId);

          items[index] = formatBalanceItem(response.data);

          return { ...current, items };
        });

        const item = t('attachment', { ns: 'balances' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteBalanceAttachment = useCallback(
    async (params: IDeleteBalanceAttachmentRequest) => {
      try {
        showLoader();

        await api.balance().deleteAttachment(params);

        setBalance(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === params.balanceId);

          items[index] = formatBalanceItem({ ...items[index], attachmentUrl: '' });

          return { ...current, items };
        });

        const item = t('attachment', { ns: 'balances' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IBalanceContext>(
    () => ({
      balance,
      balancesGeneral,
      balanceMonth,
      balancesMonth,
      balanceSelected: selected,
      filter,
      getBalances,
      getBalancesGeneral,
      getBalanceMonth,
      getBalancesMonth,
      getReportBalancesCsv,
      getReportBalancesPdf,
      handleFilters,
      updateBalance,
      createBalance,
      deleteBalance,
      deleteBalanceAttachment,
      setBalanceSelected: setBalanceItemSelected,
      paidBalance,
      uploadBalanceAttachment,
    }),
    [
      balance,
      balancesGeneral,
      balanceMonth,
      balancesMonth,
      selected,
      filter,
      getBalances,
      getBalancesGeneral,
      getBalanceMonth,
      getBalancesMonth,
      getReportBalancesCsv,
      getReportBalancesPdf,
      handleFilters,
      updateBalance,
      createBalance,
      deleteBalance,
      deleteBalanceAttachment,
      setBalanceItemSelected,
      paidBalance,
      uploadBalanceAttachment,
    ],
  );

  return <BalancesContext.Provider value={contextValue}>{children}</BalancesContext.Provider>;
};

export { BalancesProvider, BalancesContext };
