import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { RidersAppointments } = lazily(() => import('@riders/pages/RidersAppointments'));
const { RidersForm } = lazily(() => import('@riders/pages/RidersForm'));
const { RidersList } = lazily(() => import('@riders/pages/RidersList'));
const { RidersMonthlyPayments } = lazily(() => import('@riders/pages/RidersMonthlyPayments'));

export default [
  {
    component: RidersList,
    path: 'riders',
    title: 'private.riders',
    permission: Roles.GET_RIDERS,
    canAccess: true,
  },
  {
    component: RidersForm,
    path: 'riders/:id/edit',
    title: 'private.riders_edit',
    permission: Roles.UPDATE_RIDER,
    canAccess: true,
  },
  {
    component: RidersForm,
    path: 'riders/new',
    title: 'private.riders_new',
    permission: Roles.CREATE_RIDER,
    canAccess: true,
  },
  {
    component: RidersMonthlyPayments,
    path: 'riders/:id/monthly-payments',
    title: 'private.riders_monthly_payments',
    permission: Roles.GET_RIDER_PAYMENTS,
    canAccess: true,
  },
  {
    component: RidersAppointments,
    path: 'riders/:id/appointments',
    title: 'private.riders_appointments',
    permission: [Roles.GET_APPOINTMENTS, Roles.GET_FIXED_APPOINTMENTS],
    canAccess: true,
  },
] as IRoute[];
