import { AxiosInstance, AxiosResponse } from 'axios';

import { ILicense, ILicensesByInterval } from '@users/types/Licenses/license';

export class License {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAll(): Promise<AxiosResponse<ILicense[]>> {
    return this.instance.get(`/licenses`);
  }

  getToSubscribe(): Promise<AxiosResponse<ILicensesByInterval>> {
    return this.instance.get(`/licenses/to-subscribe`);
  }
}

export const license = (instance: AxiosInstance): License => new License(instance);
