import { useMemo } from 'react';

import { useUsers } from '@users/hooks/useUsers';

interface Props {
  permission: string | string[];
}

export const NeedPermission: React.FC<React.PropsWithChildren<Props>> = ({ permission, children }) => {
  const { permissions } = useUsers();

  const hasPermission = useMemo(() => {
    return typeof permission === 'string'
      ? permissions.includes(permission)
      : permissions.filter(p => permission.includes(p)).length > 0;
  }, [permission, permissions]);

  function render(): React.ReactNode {
    return hasPermission ? children : null;
  }

  return <>{render()}</>;
};
