import { useContextSelector } from 'use-context-selector';

import { AuthContext } from '@users/contexts/Auth';
import { IAuthContext } from '@users/types/Auth/context';

export function useAuth(): IAuthContext {
  const isAuthenticated = useContextSelector(AuthContext, cs => cs.isAuthenticated);
  const tokens = useContextSelector(AuthContext, cs => cs.tokens);

  const signIn = useContextSelector(AuthContext, cs => cs.signIn);
  const signOut = useContextSelector(AuthContext, cs => cs.signOut);

  return {
    isAuthenticated,
    tokens,

    signIn,
    signOut,
  };
}
