import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { ISeal } from '@associations/types/Affiliates/affiliates';
import { IAffiliatesSealContext } from '@associations/types/Seals/context';
import {
  IDeletePassportDocument,
  IHandleSealRequest,
  IUploadPassportDocument,
} from '@associations/types/Seals/requests';

import { useLoader } from '@loader/hooks/useLoader';

const AffiliatesSealContext = createContext<IAffiliatesSealContext>({} as IAffiliatesSealContext);

AffiliatesSealContext.displayName = 'AffiliatesSeal';

const AffiliatesSealProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'affiliates']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [seal, setSeal] = useState<ISeal>({} as ISeal);

  const getSeal = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const { data } = await api.affiliates().getSeal(id);

        setSeal(data);
      } catch (err) {
        handleErrorApi({ err, otherStatus: [404] });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const handleSeal = useCallback(
    async ({ passportFiles, ...sealData }: IHandleSealRequest) => {
      try {
        showLoader();

        const { data } = await api.affiliates().handleSeal(sealData);
        setSeal(data);

        if (passportFiles?.length) {
          const { affiliatedId } = sealData;
          const { id: sealId } = data;

          const responseUpload = await api
            .affiliates()
            .uploadPassportDocument({ affiliatedId, sealId, files: passportFiles });

          setSeal(responseUpload.data);
        }

        navigate(`/affiliates/horses/${sealData.affiliatedId}/edit`);

        toast(
          t('crud.updated_success', { ns: 'messages', context: 'male', item: t('affiliated', { ns: 'affiliates' }) }),
          { type: 'success' },
        );
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t],
  );

  const handleSetSeal = useCallback((data: ISeal) => {
    setSeal(data);
  }, []);

  const uploadPassportDocument = useCallback(
    async (upload: IUploadPassportDocument) => {
      try {
        showLoader();

        const { data } = await api.affiliates().uploadPassportDocument(upload);

        setSeal(data);

        toast(
          t('crud.updated_success', { ns: 'messages', context: 'male', item: t('affiliated', { ns: 'affiliates' }) }),
          { type: 'success' },
        );
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deletePassportDocument = useCallback(
    async (data: IDeletePassportDocument) => {
      try {
        showLoader();

        await api.affiliates().deletePassportDocument(data);

        setSeal(current => ({
          ...current,
          passportDocuments: current.passportDocuments.filter(p => !data.passportDocumentIds.includes(p.id)),
        }));

        toast(
          t('crud.updated_success', { ns: 'messages', context: 'male', item: t('affiliated', { ns: 'affiliates' }) }),
          { type: 'success' },
        );
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IAffiliatesSealContext>(
    () => ({ seal, getSeal, handleSeal, handleSetSeal, deletePassportDocument, uploadPassportDocument }),
    [seal, getSeal, handleSeal, handleSetSeal, deletePassportDocument, uploadPassportDocument],
  );

  return <AffiliatesSealContext.Provider value={contextValue}>{children}</AffiliatesSealContext.Provider>;
};

export { AffiliatesSealProvider, AffiliatesSealContext };
