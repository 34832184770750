import { AxiosInstance, AxiosResponse } from 'axios';

import { IPerson, PersonType } from '@associations/types/Persons/persons';
import { ICreatePersonRequest } from '@associations/types/Persons/requests';

export class Person {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(data: ICreatePersonRequest): Promise<AxiosResponse<IPerson>> {
    return this.instance.post(`/persons`, data);
  }

  findAll(): Promise<AxiosResponse<IPerson[]>> {
    return this.instance.get(`/persons`);
  }

  findById(id: string): Promise<AxiosResponse<IPerson>> {
    return this.instance.get(`/persons/${id}`);
  }

  findByType(type: PersonType): Promise<AxiosResponse<IPerson[]>> {
    return this.instance.get(`/persons/type/${type}`);
  }
}

export const person = (instance: AxiosInstance): Person => new Person(instance);
