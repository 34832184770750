import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';
import toCurrency from '@utils/toCurrency';

import { useLoader } from '@loader/hooks/useLoader';

import { ILicenseContext } from '@users/types/Licenses/context';
import { ILicense, ILicensesByInterval } from '@users/types/Licenses/license';

const LicensesContext = createContext<ILicenseContext>({} as ILicenseContext);

LicensesContext.displayName = 'Licenses';

const LicensesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [licenses, setLicenses] = useState<ILicense[]>([]);
  const [licensesByInterval, setLicensesByInterval] = useState<ILicensesByInterval>({} as ILicensesByInterval);

  const getLicenses = useCallback(async () => {
    try {
      showLoader();

      const response = await api.license().getAll();

      const licensesFormatted = response.data.map(license => ({
        ...license,
        priceFormatted: toCurrency(license.price, { precision: 0 }),
      }));

      setLicenses(licensesFormatted);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const getLicensesToSubscribe = useCallback(async () => {
    try {
      showLoader();

      const response = await api.license().getToSubscribe();

      setLicensesByInterval(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const contextValue = useMemo<ILicenseContext>(
    () => ({ licenses, licensesByInterval, getLicenses, getLicensesToSubscribe }),
    [licenses, licensesByInterval, getLicenses, getLicensesToSubscribe],
  );

  return <LicensesContext.Provider value={contextValue}>{children}</LicensesContext.Provider>;
};

export { LicensesProvider, LicensesContext };
