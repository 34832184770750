import { AxiosInstance, AxiosResponse } from 'axios';

import EquitesPaginate from '@equites/api/paginate';

import {
  IAcceptInviteToCreateAffiliatedByAssociationRequest,
  IAcceptInviteToUpdateAffiliatedByAssociationRequest,
} from '@associations/types/Affiliates/requests';
import { IAssociationCompany } from '@associations/types/Association/association';
import { IInvite, IInviteWithAssociationAndCompany } from '@associations/types/Invites/invite';
import {
  IAcceptInviteToCreateCompanyByAssociationRequest,
  IAcceptInviteToUpdateCompanyByAssociationRequest,
  IGetInvitesRequest,
  ISendInviteToUpdateAffiliatedRequest,
} from '@associations/types/Invites/requests';

import { ICompany } from '@companies/types/Companies/company';

import { IModality } from '@modalities/types/Modalities/modalities';
import { IModalityCategory } from '@modalities/types/ModalityCategories/modalityCategories';

export class Invite {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(params: IGetInvitesRequest): Promise<AxiosResponse<EquitesPaginate<IInvite>>> {
    return this.instance.get(`/invites`, { params });
  }

  getById(id: string, token: string): Promise<AxiosResponse<IInviteWithAssociationAndCompany>> {
    return this.instance.get(`/invites/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  }

  revokeById(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/invites/${id}`);
  }

  getCompaniesByAssociationId(associationId: string, token: string): Promise<AxiosResponse<IAssociationCompany[]>> {
    return this.instance.get(`/invites/associations/${associationId}/companies`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  getModalities(token: string): Promise<AxiosResponse<IModality[]>> {
    return this.instance.get(`/invites/modalities`, { headers: { Authorization: `Bearer ${token}` } });
  }

  getModalityCategories(modalityId: string, token: string): Promise<AxiosResponse<IModalityCategory[]>> {
    return this.instance.get(`/invites/modalities/${modalityId}/categories`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  sendInvitesToCompanies(emails: string[]): Promise<AxiosResponse<void>> {
    return this.instance.post(`/invites/send/companies`, { emails });
  }

  sendInvitesToAffiliates(emails: string[]): Promise<AxiosResponse<void>> {
    return this.instance.post(`/invites/send/affiliates`, { emails });
  }

  sendInviteToUpdateCompany(email: string, companyId: string): Promise<AxiosResponse<void>> {
    return this.instance.post(`/invites/send/companies/${companyId}`, { email });
  }

  sendInviteToUpdateAffiliated(data: ISendInviteToUpdateAffiliatedRequest): Promise<AxiosResponse<void>> {
    const { companyId, affiliatedId, email } = data;
    return this.instance.post(`/invites/send/companies/${companyId}/affiliates/${affiliatedId}`, { email });
  }

  sendAcceptInviteToCreateCompany(
    data: IAcceptInviteToCreateCompanyByAssociationRequest,
  ): Promise<AxiosResponse<ICompany>> {
    const { associationId, inviteId, token, ...body } = data;
    return this.instance.post(`/invites/${inviteId}/associations/${associationId}/companies`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  sendAcceptInviteToUpdateCompany(
    data: IAcceptInviteToUpdateCompanyByAssociationRequest,
  ): Promise<AxiosResponse<ICompany>> {
    const { associationId, inviteId, companyId, token, ...body } = data;
    return this.instance.patch(`/invites/${inviteId}/associations/${associationId}/companies/${companyId}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  sendAcceptInviteToCreateAffiliated(
    data: IAcceptInviteToCreateAffiliatedByAssociationRequest,
  ): Promise<AxiosResponse<void>> {
    const { associationId, inviteId, companyId, token, ...body } = data;
    return this.instance.post(
      `/invites/${inviteId}/associations/${associationId}/companies/${companyId}/affiliates`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  sendAcceptInviteToUpdateAffiliated(
    data: IAcceptInviteToUpdateAffiliatedByAssociationRequest,
  ): Promise<AxiosResponse<void>> {
    const { associationId, inviteId, companyId, affiliatedId, token, ...body } = data;
    return this.instance.patch(
      `/invites/${inviteId}/associations/${associationId}/companies/${companyId}/affiliates/${affiliatedId}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  }

  updateCompanyAvatar(id: string, data: FormData, token: string): Promise<AxiosResponse<void>> {
    return this.instance.patch(`/invites/companies/${id}/upload/avatar`, data, {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
    });
  }
}

export const invite = (instance: AxiosInstance): Invite => new Invite(instance);
