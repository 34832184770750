import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import {
  FaHome,
  FaHorseHead,
  FaUsers,
  FaCalendarAlt,
  FaCarrot,
  FaUserNinja,
  FaUsersCog,
  FaToriiGate,
  FaUserFriends,
  FaAngleLeft,
  // FaTrophy,
  FaHandHoldingUsd,
  FaPaperPlane,
} from 'react-icons/fa';

import icon from '@assets/img/icon-animated-once-time.gif';
import logo from '@assets/img/logo-animated-once-time.gif';

import PrivateRoutesConfig from '@routes/config/PrivateRoutes';

import Link from '@components/Link';

import { getAllowedRoutes } from '@utils/getAllowedRoutes';

import { useAuth } from '@users/hooks/useAuth';
import { useUsers } from '@users/hooks/useUsers';

import { ISubItem, SidebarItem } from './SidebarItem';
import { Container, Logo, Items, HandleMenu, Content } from './styles';

interface ISidebarProps {}

interface IRoute {
  title: string;
  icon: IconType;
  path: string;
  subItems?: ISubItem[];
}

const SidebarComponent: React.FC<React.PropsWithChildren<ISidebarProps>> = () => {
  const { t } = useTranslation('menu');

  const { company, permissions } = useUsers();
  const { signOut } = useAuth();

  const [routeItems, setRouteItems] = useState<IRoute[]>([]);
  const [activeMenu, setActiveMenu] = useState<boolean>(false);

  const getRoutes = useCallback(async () => {
    const routes = [
      { title: company?.slug ?? company?.fantasyName ?? '', icon: FaHome, path: '/dashboard' },
      { title: t('balance'), icon: FaHandHoldingUsd, path: '/balance' },
      { title: t('companies_teams'), icon: FaToriiGate, path: '/companies' },
      { title: t('athletes'), icon: FaUserFriends, path: '/affiliates/athletes' },
      { title: t('horses'), icon: FaHorseHead, path: '/affiliates/horses' },
      { title: t('invites'), icon: FaPaperPlane, path: '/invites' },
      { title: t('my_stables'), icon: FaHorseHead, path: '/stable' },
      { title: t('knights_and_amazons'), icon: FaUsers, path: '/riders' },
      { title: t('teachers'), icon: FaUserNinja, path: '/teachers' },
      { title: t('appointments'), icon: FaCalendarAlt, path: '/appointments' },
      // { title: 'Provas', icon: FaTrophy, path: '/competitions' },
      { title: t('my_warehouse'), icon: FaCarrot, path: '/warehouse' },
      { title: t('my_team'), icon: FaUsersCog, path: '/my-team' },
    ];

    const allowedRoutes = await getAllowedRoutes({ routes: PrivateRoutesConfig, permissions, signOut });
    const paths = allowedRoutes.filter(({ canAccess }) => canAccess).map(route => `/${route.path}`);

    setRouteItems(routes.filter(route => paths.includes(route.path)));
  }, [company, permissions, signOut, t]);

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);
  return (
    <Container $active={activeMenu}>
      <Content $active={activeMenu}>
        <HandleMenu $active={activeMenu} onClick={() => setActiveMenu(prev => !prev)}>
          <FaAngleLeft size={18} />
        </HandleMenu>
        <Link to="/dashboard">
          <Logo src={activeMenu ? logo : icon} alt="Equites" />
        </Link>

        <Items>
          {routeItems.map(item => (
            <SidebarItem
              key={item.path}
              title={item.title}
              icon={item.icon}
              path={item.path}
              menuActive={activeMenu}
              subItems={item?.subItems}
            />
          ))}
        </Items>
      </Content>
    </Container>
  );
};

export const Sidebar = memo(SidebarComponent);
