import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IAssociationCompany } from '@associations/types/Association/association';
import { IInviteContext } from '@associations/types/Invites/context';
import { IInviteWithAssociationAndCompany, IInvite } from '@associations/types/Invites/invite';
import { IGetInvitesRequest, ISendInviteToUpdateAffiliatedRequest } from '@associations/types/Invites/requests';

import { useLoader } from '@loader/hooks/useLoader';

import { IModality } from '@modalities/types/Modalities/modalities';
import { IModalityCategory } from '@modalities/types/ModalityCategories/modalityCategories';

const InvitesContext = createContext<IInviteContext>({} as IInviteContext);

InvitesContext.displayName = 'Invites';

const InvitesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'invites']);

  const { showLoader, hideLoader } = useLoader();

  const [inviteWithAssociationAndCompany, setInviteWithAssociationAndCompany] =
    useState<IInviteWithAssociationAndCompany>({} as IInviteWithAssociationAndCompany);
  const [companies, setCompanies] = useState<IAssociationCompany[]>([]);
  const [modalities, setModalities] = useState<IModality[]>([]);
  const [modalityCategories, setModalityCategories] = useState<IModalityCategory[]>([]);
  const [invites, setInvites] = useState<EquitesPaginate<IInvite>>({} as EquitesPaginate<IInvite>);

  const getInvites = useCallback(
    async (params: IGetInvitesRequest) => {
      try {
        showLoader();

        const response = await api.invite().get(params);

        const items = response.data.items.map(invite => ({
          ...invite,
          createdAt: format(new Date(invite.createdAt), 'dd/MM/yyyy'),
        }));

        setInvites({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getInviteById = useCallback(
    async (id: string, token: string) => {
      try {
        showLoader();

        const response = await api.invite().getById(id, token);

        setInviteWithAssociationAndCompany(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const revokeInviteById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.invite().revokeById(id);

        setInvites(current => {
          const { items } = current;

          const index = current.items.findIndex(i => i.id === id);
          items[index].isRevoked = true;

          return { ...current, items };
        });

        const item = t('invite', { ns: 'invites' });
        toast(t('crud.canceled_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const getCompaniesToInvite = useCallback(
    async (associationId: string, token: string) => {
      try {
        showLoader();

        const response = await api.invite().getCompaniesByAssociationId(associationId, token);

        setCompanies(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getModalitiesToInvite = useCallback(
    async (token: string) => {
      try {
        showLoader();

        const response = await api.invite().getModalities(token);

        setModalities(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getModalityCategoriesToInvite = useCallback(
    async (modalityId: string, token: string) => {
      try {
        showLoader();

        const response = await api.invite().getModalityCategories(modalityId, token);

        setModalityCategories(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const sendInvitesToCompanies = useCallback(
    async (emails: string[]) => {
      try {
        showLoader();

        await api.invite().sendInvitesToCompanies(emails);

        const item = t('invites', { ns: 'invites' });
        toast(t('crud.sent_success_plural', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const sendInvitesToUpdateCompany = useCallback(
    async (email: string, companyId: string) => {
      try {
        showLoader();

        await api.invite().sendInviteToUpdateCompany(email, companyId);

        const item = t('invite', { ns: 'invites' });
        toast(t('crud.sent_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const sendInviteToUpdateAffiliated = useCallback(
    async (data: ISendInviteToUpdateAffiliatedRequest) => {
      try {
        showLoader();

        await api.invite().sendInviteToUpdateAffiliated(data);

        const item = t('invite', { ns: 'invites' });
        toast(t('crud.sent_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const sendInvitesToAffiliates = useCallback(
    async (emails: string[]) => {
      try {
        showLoader();

        await api.invite().sendInvitesToAffiliates(emails);

        const item = t('invites', { ns: 'invites' });
        toast(t('crud.sent_success_plural', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IInviteContext>(
    () => ({
      companies,
      invites,
      inviteWithAssociationAndCompany,
      modalities,
      modalityCategories,
      getCompaniesToInvite,
      getInviteById,
      getInvites,
      getModalitiesToInvite,
      getModalityCategoriesToInvite,
      revokeInviteById,
      sendInvitesToAffiliates,
      sendInvitesToCompanies,
      sendInvitesToUpdateCompany,
      sendInviteToUpdateAffiliated,
    }),
    [
      companies,
      invites,
      inviteWithAssociationAndCompany,
      modalities,
      modalityCategories,
      getCompaniesToInvite,
      getInviteById,
      getInvites,
      getModalitiesToInvite,
      getModalityCategoriesToInvite,
      revokeInviteById,
      sendInvitesToAffiliates,
      sendInvitesToCompanies,
      sendInvitesToUpdateCompany,
      sendInviteToUpdateAffiliated,
    ],
  );

  return <InvitesContext.Provider value={contextValue}>{children}</InvitesContext.Provider>;
};

export { InvitesProvider, InvitesContext };
