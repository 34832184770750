import { createGlobalStyle } from 'styled-components';

import 'react-day-picker/lib/style.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import Bold from '@assets/font/Signika/Bold.woff2';
import Light from '@assets/font/Signika/Light.woff2';
import Regular from '@assets/font/Signika/Regular.woff2';
import SemiBold from '@assets/font/Signika/SemiBold.woff2';

export default createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: 'Signika';
    src: url(${Regular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-display: swap;
    font-family: 'Signika';
    src: url(${Light});
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-display: swap;
    font-family: 'Signika';
    src: url(${SemiBold});
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-display: swap;
    font-family: 'Signika';
    src: url(${Bold});
    font-style: normal;
    font-weight: 700;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    scroll-behavior: smooth;
  }

  html {
    font-size: 62.5%; /* Equivalente a 10px */
    scroll-behavior: smooth;
    height: 100%;
  }

  body {
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    -webkit-font-smoothing: antialiased;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${props => props.theme.colors.background};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: ${props => props.theme.colors.primary};
    }
  }

  body, input, button, select, textarea {
    font-size: 1.6rem;
    font-family: 'Signika', serif;
    font-style: normal;
    font-weight: 400;
  }

  body, #root {
    height: 100% !important;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  button {
    cursor: pointer;
  }

  .disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.5;
    }

    body {
      .intercom-lightweight-app {
        z-index: 2;
      }
    }

`;
