import styled, { keyframes } from 'styled-components';

interface IContainerProps {
  size: number;
}

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<IContainerProps>`
  animation: ${spin} 1s linear infinite;
  border: 3px solid ${props => props.theme.colors.white};
  border-left-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
`;
