export default {
  // ADDRESSES
  GET_ADDRESS_BY_CEP: 'GET_ADDRESS_BY_CEP',

  // AFFILIATES
  APPROVE_REPROVE_AFFILIATED: 'APPROVE_REPROVE_AFFILIATED',
  CREATE_AFFILIATE: 'CREATE_AFFILIATE',
  CREATE_AFFILIATE_BY_LINK: 'CREATE_AFFILIATE_BY_LINK',
  DELETE_AFFILIATE: 'DELETE_AFFILIATE',
  GENERATE_REGISTRATION_NUMBERS: 'GENERATE_REGISTRATION_NUMBERS',
  GET_AFFILIATES: 'GET_AFFILIATES',
  GET_AFFILIATIONS: 'GET_AFFILIATIONS',
  GET_MICROCHIPS: 'GET_MICROCHIPS',
  GET_SEALS: 'GET_SEALS',
  HANDLE_AFFILIATED_ADDRESS: 'HANDLE_AFFILIATED_ADDRESS',
  HANDLE_AFFILIATES_PERSONS: 'HANDLE_AFFILIATES_PERSONS',
  HANDLE_AFFILIATIONS: 'HANDLE_AFFILIATIONS',
  HANDLE_MICROCHIPS: 'HANDLE_MICROCHIPS',
  HANDLE_SEALS: 'HANDLE_SEALS',
  UPDATE_AFFILIATE: 'UPDATE_AFFILIATE',
  UPDATE_AFFILIATE_BY_LINK: 'UPDATE_AFFILIATE_BY_LINK',

  // APPOINTMENTS
  CREATE_APPOINTMENT: 'CREATE_APPOINTMENT',
  CREATE_FIXED_APPOINTMENT: 'CREATE_FIXED_APPOINTMENT',
  DELETE_APPOINTMENT: 'DELETE_APPOINTMENT',
  DELETE_FIXED_APPOINTMENT: 'DELETE_FIXED_APPOINTMENT',
  GET_APPOINTMENTS: 'GET_APPOINTMENTS',
  GET_APPOINTMENTS_HISTORY: 'GET_APPOINTMENTS_HISTORY',
  GET_FIXED_APPOINTMENTS: 'GET_FIXED_APPOINTMENTS',
  GET_REPORT_APPOINTMENTS_DAY: 'GET_REPORT_APPOINTMENTS_DAY',
  GET_REPORT_APPOINTMENTS_HISTORY: 'GET_REPORT_APPOINTMENTS_HISTORY',
  UPDATE_APPOINTMENT: 'UPDATE_APPOINTMENT',
  UPDATE_CONFIRM_APPOINTMENT: 'UPDATE_CONFIRM_APPOINTMENT',
  UPDATE_FIXED_APPOINTMENT: 'UPDATE_FIXED_APPOINTMENT',

  // BALANCE
  CREATE_BALANCE: 'CREATE_BALANCE',
  CREATE_BALANCE_CATEGORY: 'CREATE_BALANCE_CATEGORY',
  CREATE_WALLET: 'CREATE_WALLET',
  DELETE_BALANCE: 'DELETE_BALANCE',
  DELETE_BALANCE_ATTACHMENT: 'DELETE_BALANCE_ATTACHMENT',
  EXPORT_BALANCE_REPORTS: 'EXPORT_BALANCE_REPORTS',
  GET_BALANCE: 'GET_BALANCE',
  GET_BALANCE_CATEGORIES: 'GET_BALANCE_CATEGORY',
  GET_WALLET: 'GET_WALLET',
  UPDATE_BALANCE: 'UPDATE_BALANCE',
  UPDATE_BALANCE_CATEGORY: 'UPDATE_BALANCE_CATEGORY',
  UPDATE_WALLET: 'UPDATE_WALLET',
  UPLOAD_BALANCE_ATTACHMENT: 'UPLOAD_BALANCE_ATTACHMENT',
  VIEW_BALANCE_ATTACHMENT: 'VIEW_BALANCE_ATTACHMENT',
  VIEW_BALANCE_REPORTS: 'VIEW_BALANCE_REPORTS',

  // BALANCE GENERAL
  CREATE_BALANCE_GENERAL: 'CREATE_BALANCE_GENERAL',
  CREATE_BALANCE_GENERAL_MONTH: 'CREATE_BALANCE_GENERAL_MONTH',
  GET_BALANCE_GENERAL: 'GET_BALANCE_GENERAL',
  GET_BALANCE_GENERAL_MONTH: 'GET_BALANCE_GENERAL_MONTH',

  // BANKS
  GET_BANKS: 'GET_BANKS',

  // COMPANIES
  APPROVE_REPROVE_ASSOCIATION_COMPANY: 'APPROVE_REPROVE_ASSOCIATION_COMPANY',
  CREATE_ASSOCIATION: 'CREATE_ASSOCIATION',
  CREATE_ASSOCIATION_COMPANY: 'CREATE_ASSOCIATION_COMPANY',
  CREATE_ASSOCIATION_COMPANY_BY_LINK: 'CREATE_ASSOCIATION_COMPANY_BY_LINK',
  CREATE_COMPANY_ADDRESS: 'CREATE_COMPANY_ADDRESS',
  DELETE_ASSOCIATION_COMPANY: 'DELETE_ASSOCIATION_COMPANY',
  GET_ASSOCIATION_COMPANIES: 'GET_ASSOCIATION_COMPANIES',
  GET_COMPANY_ME: 'GET_COMPANY_ME',
  GET_SUBSCRIPTION: 'GET_SUBSCRIPTION',
  GET_SUBSCRIPTIONS_LIMIT: 'GET_SUBSCRIPTIONS_LIMIT',
  UPDATE_ASSOCIATION_COMPANY: 'UPDATE_ASSOCIATION_COMPANY',
  UPDATE_ASSOCIATION_COMPANY_BY_LINK: 'UPDATE_ASSOCIATION_COMPANY_BY_LINK',
  UPDATE_COMPANY_ADDRESS: 'UPDATE_COMPANY_ADDRESS',
  UPDATE_COMPANY_ME: 'UPDATE_COMPANY_ME',
  UPDATE_COMPANY_PAYMENT: 'UPDATE_COMPANY_PAYMENT',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',

  // CONFIGURATIONS
  CREATE_CONFIGURATIONS: 'CREATE_CONFIGURATIONS',
  GET_CONFIGURATIONS: 'GET_CONFIGURATIONS',
  UPDATE_CONFIGURATION_IMAGE_REPORTS: 'UPDATE_CONFIGURATION_IMAGE_REPORTS',
  UPDATE_CONFIGURATIONS: 'UPDATE_CONFIGURATIONS',

  // DASHBOARD
  GET_DASHBOARD: 'GET_DASHBOARD',

  // EQUITES
  SEND_CONTACT_US: 'SEND_CONTACT_US',

  // FOODS
  CREATE_FOOD: 'CREATE_FOOD',
  GET_FOODS: 'GET_FOODS',
  UPDATE_FOOD: 'UPDATE_FOOD',

  // HORSES
  CREATE_HORSE: 'CREATE_HORSE',
  CREATE_HORSE_EXAMS: 'CREATE_HORSE_EXAMS',
  CREATE_HORSE_EXPENSE: 'CREATE_HORSE_EXPENSE',
  CREATE_HORSE_FEED: 'CREATE_HORSE_FEED',
  CREATE_HORSE_MEDICINES: 'CREATE_HORSE_MEDICINES',
  CREATE_OWNER_HORSE: 'CREATE_OWNER_HORSE',
  DELETE_HORSE: 'DELETE_HORSE',
  DELETE_HORSE_EXAMS: 'DELETE_HORSE_EXAMS',
  DELETE_HORSE_EXPENSE: 'DELETE_HORSE_EXPENSE',
  DELETE_HORSE_MEDICINES: 'DELETE_HORSE_MEDICINES',
  DELETE_HORSE_EXAM_REPORT: 'DELETE_HORSE_EXAM_REPORT',
  GET_HORSE_EXAMS: 'GET_HORSE_EXAMS',
  GET_HORSE_EXPENSES: 'GET_HORSE_EXPENSES',
  GET_HORSE_FEEDS: 'GET_HORSE_FEEDS',
  GET_HORSE_FEEDS_HISTORY: 'GET_HORSE_FEEDS_HISTORY',
  GET_HORSE_HORSESHOES: 'GET_HORSE_HORSESHOES',
  GET_HORSE_MEDICINES: 'GET_HORSE_MEDICINES',
  GET_HORSE_VACCINES: 'GET_HORSE_VACCINES',
  GET_HORSES: 'GET_HORSES',
  GET_OWNERS_HORSES: 'GET_OWNERS_HORSES',
  GET_REPORT_HORSE_EXPENSE: 'GET_REPORT_HORSE_EXPENSE',
  HANDLE_HORSE_FOODS: 'HANDLE_HORSE_FOODS',
  HANDLE_HORSE_HORSESHOES: 'HANDLE_HORSE_HORSESHOES',
  HANDLE_HORSE_VACCINES: 'HANDLE_HORSE_VACCINES',
  UPDATE_HORSE: 'UPDATE_HORSE',
  UPDATE_HORSE_AVATAR: 'UPDATE_HORSE_AVATAR',
  UPDATE_HORSE_EXAMS: 'UPDATE_HORSE_EXAMS',
  UPDATE_HORSE_MEDICINES: 'UPDATE_HORSE_MEDICINES',
  UPDATE_OWNER_HORSE: 'UPDATE_OWNER_HORSE',
  UPDATE_PAID_HORSE_EXPENSE: 'UPDATE_PAID_HORSE_EXPENSE',
  UPLOAD_HORSE_EXAM_REPORT: 'UPLOAD_HORSE_EXAM_REPORT',
  VIEW_HORSE_EXAM_REPORT: 'VIEW_HORSE_EXAM_REPORT',

  // IDENTIFIERS
  GENERATE_IDENTIFIERS: 'GENERATE_IDENTIFIERS',

  // INVITES
  GET_INVITES: 'GET_INVITES',
  REVOKE_INVITE: 'REVOKE_INVITE',

  // LICENSES
  CREATE_LICENSE: 'CREATE_LICENSE',
  GET_LICENSES: 'GET_LICENSES',
  UPDATE_LICENSE: 'UPDATE_LICENSE',

  // MODALITIES
  GET_MODALITIES: 'GET_MODALITIES',
  GET_MODALITY_CATEGORIES: 'GET_MODALITY_CATEGORIES',
  GET_MODALITY_CATEGORIES_BY_ASSOCIATION: 'GET_MODALITY_CATEGORIES_BY_ASSOCIATION',
  CREATE_MODALITY: 'CREATE_MODALITY',
  CREATE_MODALITY_CATEGORY: 'CREATE_MODALITY_CATEGORY',
  CREATE_MODALITY_CATEGORY_BY_ASSOCIATION: 'CREATE_MODALITY_CATEGORY_BY_ASSOCIATION',
  DELETE_MODALITY: 'DELETE_MODALITY',
  DELETE_MODALITY_CATEGORY: 'DELETE_MODALITY_CATEGORY',
  DELETE_MODALITY_CATEGORY_BY_ASSOCIATION: 'DELETE_MODALITY_CATEGORY_BY_ASSOCIATION',
  UPDATE_MODALITY: 'UPDATE_MODALITY',
  UPDATE_MODALITY_CATEGORY: 'UPDATE_MODALITY_CATEGORY',
  UPDATE_MODALITY_CATEGORY_BY_ASSOCIATION: 'UPDATE_MODALITY_CATEGORY_BY_ASSOCIATION',

  // PAYMENT METHODS
  CREATE_PAYMENT_METHOD: 'CREATE_PAYMENT_METHOD',
  DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
  GET_PAYMENT_METHODS: 'GET_PAYMENT_METHODS',
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',

  // PERSONS
  CREATE_PERSON: 'CREATE_PERSON',
  GET_PERSON: 'GET_PERSON',

  // RIDERS
  CREATE_RIDER: 'CREATE_RIDER',
  CREATE_RIDER_LESSONS_PACKAGE: 'CREATE_RIDER_LESSONS_PACKAGE',
  CREATE_RIDER_PAYMENT: 'CREATE_RIDER_PAYMENT',
  CREATE_RIDER_PAYMENT_LESSON_PACKAGE: 'CREATE_RIDER_PAYMENT_LESSON_PACKAGE',
  DELETE_RIDER: 'DELETE_RIDER',
  DELETE_RIDER_PAYMENT: 'DELETE_RIDER_PAYMENT',
  GET_RIDER_LESSONS_PACKAGE: 'GET_RIDER_LESSONS_PACKAGE',
  GET_RIDER_PAYMENTS: 'GET_RIDER_PAYMENTS',
  GET_RIDERS: 'GET_RIDERS',
  GET_RIDERS_APPOINTMENTS_COUNTER: 'GET_RIDERS_APPOINTMENTS_COUNTER',
  UPDATE_PAID_RIDER_PAYMENT: 'UPDATE_PAID_RIDER_PAYMENT',
  UPDATE_RIDER: 'UPDATE_RIDER',
  UPDATE_RIDER_AVATAR: 'UPDATE_RIDER_AVATAR',
  UPDATE_RIDER_LESSONS_PACKAGE: 'UPDATE_RIDER_LESSONS_PACKAGE',

  // TEACHERS
  CREATE_TEACHER: 'CREATE_TEACHER',
  DELETE_TEACHER: 'DELETE_TEACHER',
  GET_TEACHERS: 'GET_TEACHERS',
  UPDATE_TEACHER: 'UPDATE_TEACHER',
  UPDATE_TEACHER_AVATAR: 'UPDATE_TEACHER_AVATAR',

  // REFRESH TOKENS
  REVOKE_ALL_REFRESH_TOKENS: 'REVOKE_ALL_REFRESH_TOKENS',

  // USERS
  CREATE_PERMISSION: 'CREATE_PERMISSION',
  CREATE_TEAM_USER: 'CREATE_TEAM_USER',
  CREATE_USERS_GROUP: 'CREATE_USERS_GROUP',
  DELETE_TEAM_USER: 'DELETE_TEAM_USER',
  GET_PERMISSIONS: 'GET_PERMISSIONS',
  GET_PROFILE: 'GET_PROFILE',
  GET_TEAM_USERS: 'GET_TEAM_USERS',
  GET_USERS_GROUPS: 'GET_USERS_GROUPS',
  HANDLE_BLOCK_TEAM_USER: 'HANDLE_BLOCK_TEAM_USER',
  UPDATE_PERMISSION: 'UPDATE_PERMISSION',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  UPDATE_TEAM_USER: 'UPDATE_TEAM_USER',
  UPDATE_USER_AVATAR: 'UPDATE_USER_AVATAR',
  UPDATE_USERS_GROUP: 'UPDATE_USERS_GROUP',
};
