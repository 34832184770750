import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { IConfigProps } from '@equites/api/config';

import { Languages } from '@constants/Languages';
import { Storage } from '@constants/Storage';

import { ITokens } from '@users/types/Auth/auth';

interface ResponseError {
  status?: string;
  message?: string;
}

export class Config {
  private axios: AxiosInstance;

  constructor({ apiUrl }: IConfigProps) {
    if (!apiUrl) throw new Error('Missing apiUrl');

    this.axios = this.create(apiUrl);

    this.axios.interceptors.request.use(this.handleRequest, error => error);
    this.axios.interceptors.response.use(response => response, this.handleErrorResponse);
  }

  create(apiUrl: string): AxiosInstance {
    return axios.create({
      baseURL: apiUrl,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': localStorage.getItem(Storage.LANGUAGE) || Languages.PT_BR,
      },
    });
  }

  instance(): AxiosInstance {
    return this.axios;
  }

  private handleRequest = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (!request.headers?.Authorization) {
      const tokens = localStorage.getItem(Storage.TOKENS);

      if (tokens && JSON.parse(tokens)) {
        const { accessToken }: ITokens = JSON.parse(tokens);
        Object.assign(request, { headers: { ...request.headers, Authorization: `Bearer ${accessToken}` } });
      }
    }

    return request;
  };

  private handleErrorResponse = (error: AxiosError<ResponseError>): Promise<AxiosResponse<ResponseError>> => {
    return Promise.reject(error);
  };
}

const config = (props: IConfigProps): Config => new Config(props);

export default config;
