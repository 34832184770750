import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import HeadTitle from '@components/HeadTitle';

import { getAllowedRoutes } from '@utils/getAllowedRoutes';

import PublicRoutesConfig from '../config/PublicRoutes';
import { Container } from './styles';

interface IGetPublicRoutesRequest {
  signOut: () => Promise<void>;
}

export interface IGetPublicRoutes {
  canAccess: boolean;
  path: string;
  element: ReactNode;
  isPublicButAccessLogged?: boolean;
}

export const getPublicRoutes = async (data: IGetPublicRoutesRequest): Promise<IGetPublicRoutes[]> => {
  const { signOut } = data;

  const routes = await getAllowedRoutes({ routes: PublicRoutesConfig, permissions: [], signOut });

  return routes.map(route => {
    const { path, component: Component, title, children, canAccess, isPublicButAccessLogged, ...rest } = route;
    return {
      isPublicButAccessLogged,
      canAccess,
      path,
      element: (
        <Container {...rest}>
          <Outlet />
          <HeadTitle titlePage={title} />
          <Component>{children}</Component>
        </Container>
      ),
    };
  });
};
