import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { ITeacherContext } from '@teachers/types/context';
import {
  ICreateTeacherRequest,
  IGetAllTeachersOptions,
  IUpdateTeacherAvatarRequest,
  IUpdateTeacherRequest,
} from '@teachers/types/requests';
import { ITeacher } from '@teachers/types/teacher';

const TeacherContext = createContext<ITeacherContext>({} as ITeacherContext);

TeacherContext.displayName = 'Teachers';

const TeacherProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'appointments']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [teacher, setTeacher] = useState<ITeacher>({} as ITeacher);
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [teacherPhotoFile, setTeacherPhotoFile] = useState<File>();

  const createTeacher = useCallback(
    async (data: ICreateTeacherRequest) => {
      try {
        showLoader();

        const response = await api.teacher().create(data);

        navigate('/teachers');

        if (teacherPhotoFile) {
          await api.teacher().updateTeacherAvatar(response.data.id, teacherPhotoFile);
        }

        const itemMessage = t('teacher', { ns: 'appointments' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item: itemMessage }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t, teacherPhotoFile],
  );

  const deleteTeacher = useCallback(
    async (id: string) => {
      try {
        await api.teacher().deleteById(id);

        navigate('/teachers');

        const item = t('teacher', { ns: 'appointments' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, t],
  );

  const getAllTeachers = useCallback(
    async (options?: IGetAllTeachersOptions) => {
      try {
        showLoader();

        const response = await api.teacher().getAll();

        setTeachers(options?.onlyActives ? response.data.filter(({ active }) => active) : response.data);
        setTeacher({} as ITeacher);
        setTeacherPhotoFile(undefined);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getTeacherById = useCallback(
    async (teacherId: string) => {
      try {
        showLoader();

        const response = await api.teacher().getById(teacherId);

        setTeacher(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const updateTeacher = useCallback(
    async (data: IUpdateTeacherRequest) => {
      try {
        showLoader();

        await api.teacher().update(data);

        const item = t('teacher', { ns: 'appointments' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateTeacherAvatar = useCallback(
    async (data: IUpdateTeacherAvatarRequest) => {
      try {
        showLoader();

        await api.teacher().updateTeacherAvatar(data.id, data.file);

        toast(t('picture_updated_success', { ns: 'common' }), { type: 'success' });

        setTeacherPhotoFile(data.file);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const handleTeacherPhoto = useCallback((file?: File) => {
    setTeacherPhotoFile(file);
  }, []);

  const contextValue = useMemo<ITeacherContext>(
    () => ({
      teacher,
      teachers,
      teacherPhotoFile,
      createTeacher,
      deleteTeacher,
      getAllTeachers,
      getTeacherById,
      handleTeacherPhoto,
      updateTeacher,
      updateTeacherAvatar,
    }),
    [
      createTeacher,
      deleteTeacher,
      getAllTeachers,
      getTeacherById,
      handleTeacherPhoto,
      teacher,
      teacherPhotoFile,
      teachers,
      updateTeacher,
      updateTeacherAvatar,
    ],
  );

  return <TeacherContext.Provider value={contextValue}>{children}</TeacherContext.Provider>;
};

export { TeacherProvider, TeacherContext };
