import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import {
  FaHome,
  FaHorseHead,
  FaUsers,
  FaUserNinja,
  FaCalendarAlt,
  FaCarrot,
  FaUsersCog,
  FaToriiGate,
  FaUserFriends,
  // FaTrophy,
  FaHandHoldingUsd,
  FaPaperPlane,
} from 'react-icons/fa';
import { FiX } from 'react-icons/fi';

import logo from '@assets/img/logo.webp';

import PrivateRoutesConfig from '@routes/config/PrivateRoutes';

import { getAllowedRoutes } from '@utils/getAllowedRoutes';

import { useMenu } from '@menu/hooks/useMenu';

import { useTheme } from '@theme/hooks/useTheme';

import { useAuth } from '@users/hooks/useAuth';
import { useUsers } from '@users/hooks/useUsers';

import { ISubItem, SidebarItem } from '../Sidebar/SidebarItem';
import { Container, Content, Items } from './styles';

interface ISidebarMobileProps {}

interface IRoute {
  title: string;
  icon: IconType;
  path: string;
  subItems?: ISubItem[];
}

const SidebarMobileComponent: React.FC<React.PropsWithChildren<ISidebarMobileProps>> = () => {
  const { theme } = useTheme();
  const { t } = useTranslation('menu');

  const { company, permissions } = useUsers();
  const { signOut } = useAuth();
  const { shouldShowSidebarMobile, hideSidebarMobile } = useMenu();

  const [routeItems, setRouteItems] = useState<IRoute[]>([]);

  const getRoutes = useCallback(async () => {
    const routes = [
      { title: company?.slug ?? company?.fantasyName ?? '', icon: FaHome, path: '/dashboard' },
      { title: t('balance'), icon: FaHandHoldingUsd, path: '/balance' },
      { title: t('companies_teams'), icon: FaToriiGate, path: '/companies' },
      { title: t('athletes'), icon: FaUserFriends, path: '/affiliates/athletes' },
      { title: t('horses'), icon: FaHorseHead, path: '/affiliates/horses' },
      { title: t('invites'), icon: FaPaperPlane, path: '/invites' },
      { title: t('my_stables'), icon: FaHorseHead, path: '/stable' },
      { title: t('knights_and_amazons'), icon: FaUsers, path: '/riders' },
      { title: t('teachers'), icon: FaUserNinja, path: '/teachers' },
      { title: t('appointments'), icon: FaCalendarAlt, path: '/appointments' },
      // { title: 'Provas', icon: FaTrophy, path: '/competitions' },
      { title: t('my_warehouse'), icon: FaCarrot, path: '/warehouse' },
      { title: t('my_team'), icon: FaUsersCog, path: '/my-team' },
    ];

    const allowedRoutes = await getAllowedRoutes({ routes: PrivateRoutesConfig, permissions, signOut });
    const paths = allowedRoutes.filter(({ canAccess }) => canAccess).map(route => `/${route.path}`);

    setRouteItems(routes.filter(route => paths.includes(route.path)));
  }, [company, permissions, signOut, t]);

  useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  return shouldShowSidebarMobile ? (
    <Container
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ type: 'spring', duration: 0.5 }}
    >
      <header>
        <img src={logo} alt="Equites" />
        <FiX size={24} color={theme.colors.primary} onClick={hideSidebarMobile} />
      </header>

      <Content
        initial={{ opacity: 0, x: -70 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 70 }}
        transition={{ type: 'spring' }}
      >
        <Items>
          {routeItems.map(item => (
            <SidebarItem
              key={item.path}
              title={item.title}
              icon={item.icon}
              path={item.path}
              menuActive
              subItems={item?.subItems}
            />
          ))}
        </Items>
      </Content>
    </Container>
  ) : null;
};

export const SidebarMobile = memo(SidebarMobileComponent);
