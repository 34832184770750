class Clean {
  public static phone(phone?: string): string {
    return phone ? phone.replace(/[-() ]/g, '') : '';
  }

  public static cep(cep?: string): string {
    return cep ? cep.replace(/-/g, '') : '';
  }

  public static cpf(cpf?: string): string {
    return cpf ? cpf.replace(/[-.]/g, '') : '';
  }

  public static cnpj(cnpj?: string): string {
    return cnpj ? cnpj.replace(/[./-]/g, '') : '';
  }
}

export default Clean;
