import { shade } from 'polished';
import styled from 'styled-components';

import breakpoints from '@styles/breakpoints';

export const Container = styled.header`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  margin: 32px 0;

  img {
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 50%;

    width: 60px;
    height: 60px;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  .sidebar_mobile__bars {
    display: none;
  }

  @media ${breakpoints.m} {
    .sidebar_mobile__bars {
      display: block;
    }
  }
`;

export const ButtonBack = styled.button`
  background-color: ${props => props.theme.colors.background};
  border: none;
  display: flex;
  align-items: center;

  span {
    color: ${props => props.theme.colors.text};
    font-size: 1.6rem;
    margin-left: 12px;
  }

  @media ${breakpoints.m} {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .subscription__container {
    margin-right: 16px;

    .activate_now {
      padding: 0 8px;
      width: auto;
    }
    .subscribe_now {
      width: 100px;
    }

    .link__my_subscription {
      span {
        color: ${props => props.theme.colors.primary};
        font-size: 1.4rem;
        font-weight: 700;
        transition: 0.2s all;

        &:hover {
          color: ${props => shade(0.2, props.theme.colors.primary)};
        }
      }
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
  }

  @media ${breakpoints.m} {
    .subscription__container {
      flex: 1;
      margin-right: 0;
      text-align: center;
    }
  }
`;

export const NotificationsContainer = styled.div`
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    width: auto;
  }
`;
