import { AxiosInstance, AxiosResponse } from 'axios';

import { IConfiguration } from '@configurations/types/Configurations/configurations';

export class Configuration {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getConfigurations(): Promise<AxiosResponse<IConfiguration>> {
    return this.instance.get(`/configurations`);
  }

  updateConfigurations(data: Partial<IConfiguration>): Promise<AxiosResponse<IConfiguration>> {
    return this.instance.put(`/configurations`, data);
  }

  updateImageToReports(file: File): Promise<AxiosResponse<IConfiguration>> {
    const data = new FormData();
    data.append('image', file);

    const headers = { 'Content-Type': 'multipart/form-data' };

    return this.instance.patch(`/configurations/images/reports/upload`, data, { headers });
  }
}

export const configuration = (instance: AxiosInstance): Configuration => new Configuration(instance);
