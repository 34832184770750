import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';
import { validate } from 'uuid';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IRegisterAffiliatedByAssociation } from '@associations/types/Affiliates/affiliates';
import { IAffiliatedContext } from '@associations/types/Affiliates/context';
import { ICreatePersonToAffiliated, IUpdatePersonToAffiliated } from '@associations/types/Affiliates/requests';

import { useLoader } from '@loader/hooks/useLoader';

const AffiliatesContext = createContext<IAffiliatedContext>({} as IAffiliatedContext);

AffiliatesContext.displayName = 'Affiliates';

const AffiliatesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [registerStep, setRegisterStep] = useState(1);
  const [registerAffiliated, setRegisterAffiliated] = useState<IRegisterAffiliatedByAssociation>(
    {} as IRegisterAffiliatedByAssociation,
  );
  const [heightSelected, setHeightSelected] = useState<number>();
  const [shouldResetCategoriesSelected, setShouldResetCategoriesSelected] = useState(false);

  const { t } = useTranslation(['messages', 'common']);
  const { showLoader, hideLoader } = useLoader();

  const handleRegisterStep = useCallback((step: number) => {
    setRegisterStep(step);
  }, []);

  const handleDataRegisterAffiliated = useCallback((data: Partial<IRegisterAffiliatedByAssociation>) => {
    setRegisterAffiliated(current => ({ ...current, ...data }));
  }, []);

  const handleAffiliatedByLink = useCallback(async () => {
    try {
      showLoader();

      const { categories, ...payload } = registerAffiliated;

      if (payload.horses?.length) {
        const horses = payload.horses
          .filter(h => !validate(h.id))
          .map(h => ({
            name: h.name,
            type: h.type,
            birthDate: h.birthDate,
            categoriesIds: h.categories?.map(c => c.id),
            passport: h.passport,
            microchip: h.microchip,
            seal: { number: h.seal?.number, due: h.seal?.due },
            isHorseSchool: h.isHorseSchool,
          }));
        Object.assign(payload, { horses });
      }

      Object.assign(payload, { categoriesIds: categories?.map(c => c.id) });

      if (registerAffiliated.affiliatedId) {
        await api.invite().sendAcceptInviteToUpdateAffiliated({
          ...payload,
          affiliatedId: payload.affiliatedId || '',
        });
      } else {
        await api.invite().sendAcceptInviteToCreateAffiliated(payload);
      }

      setRegisterStep(9);
      setRegisterAffiliated({} as IRegisterAffiliatedByAssociation);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [registerAffiliated, showLoader, hideLoader]);

  const handleHeightSelected = useCallback((height: number | undefined) => {
    setHeightSelected(height);
  }, []);

  const handleShouldResetCategoriesSelected = useCallback((shouldReset: boolean) => {
    setShouldResetCategoriesSelected(shouldReset);
  }, []);

  const createPersonToAffiliated = useCallback(
    async (data: ICreatePersonToAffiliated) => {
      try {
        showLoader();

        await api.affiliates().createPerson(data);

        toast(t('crud.added_success', { ns: 'messages', context: 'female', item: t('person', { ns: 'common' }) }), {
          type: 'success',
        });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [t, showLoader, hideLoader],
  );

  const updatePersonToAffiliated = useCallback(
    async (data: IUpdatePersonToAffiliated) => {
      try {
        showLoader();

        await api.affiliates().updatePerson(data);

        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item: t('person', { ns: 'common' }) }), {
          type: 'success',
        });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [t, showLoader, hideLoader],
  );

  const contextValue = useMemo<IAffiliatedContext>(
    () => ({
      heightSelected,
      registerStep,
      registerAffiliated,
      shouldResetCategoriesSelected,
      createPersonToAffiliated,
      handleAffiliatedByLink,
      handleDataRegisterAffiliated,
      handleHeightSelected,
      handleRegisterStep,
      handleShouldResetCategoriesSelected,
      updatePersonToAffiliated,
    }),
    [
      heightSelected,
      registerStep,
      registerAffiliated,
      shouldResetCategoriesSelected,
      createPersonToAffiliated,
      handleAffiliatedByLink,
      handleDataRegisterAffiliated,
      handleHeightSelected,
      handleRegisterStep,
      handleShouldResetCategoriesSelected,
      updatePersonToAffiliated,
    ],
  );

  return <AffiliatesContext.Provider value={contextValue}>{children}</AffiliatesContext.Provider>;
};

export { AffiliatesProvider, AffiliatesContext };
