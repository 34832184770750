import { useContextSelector } from 'use-context-selector';

import { UsersContext } from '@users/contexts/Users';
import { IUserContext } from '@users/types/Users/context';

export function useUsers(): IUserContext {
  const company = useContextSelector(UsersContext, cs => cs.company);
  const permissions = useContextSelector(UsersContext, cs => cs.permissions);
  const subscription = useContextSelector(UsersContext, cs => cs.subscription);
  const user = useContextSelector(UsersContext, cs => cs.user);

  const handleUserAuthenticated = useContextSelector(UsersContext, cs => cs.handleUserAuthenticated);
  const handleUserCompany = useContextSelector(UsersContext, cs => cs.handleUserCompany);
  const handleUserPermissions = useContextSelector(UsersContext, cs => cs.handleUserPermissions);
  const handleUserSubscription = useContextSelector(UsersContext, cs => cs.handleUserSubscription);
  const updateUserAvatar = useContextSelector(UsersContext, cs => cs.updateUserAvatar);
  const updateUserPassword = useContextSelector(UsersContext, cs => cs.updateUserPassword);

  return {
    company,
    permissions,
    subscription,
    user,

    handleUserAuthenticated,
    handleUserCompany,
    handleUserPermissions,
    handleUserSubscription,
    updateUserAvatar,
    updateUserPassword,
  };
}
