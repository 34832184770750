import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import Translate from '@config/Translate';

import { Storage } from '@constants/Storage';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';
import { IProfileContext } from '@users/types/Profile/context';
import { IProfile } from '@users/types/Profile/profile';
import { IUpdateProfileRequest, IUpdateProfileOpts } from '@users/types/Profile/requests';

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);

ProfileContext.displayName = 'Profile';

const ProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'profile']);

  const { showLoader, hideLoader } = useLoader();
  const { user, handleUserAuthenticated } = useUsers();

  const [profile, setProfile] = useState<IProfile>({} as IProfile);

  const getProfile = useCallback(async () => {
    try {
      showLoader();

      const response = await api.profile().get();

      setProfile(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const updateProfile = useCallback(
    async (data: IUpdateProfileRequest, opts?: IUpdateProfileOpts) => {
      try {
        showLoader();

        const response = await api.profile().update(data);

        if (opts?.shouldUpdateCompany) {
          const { email, phone } = data;
          await api.company().update({ email, phone });
        }

        setProfile(response.data);

        if (user)
          handleUserAuthenticated({
            ...user,
            email: response.data.email ?? user.email,
            username: response.data.equitesIdentifier.username ?? user.username,
          });

        if (data.lang) {
          const { lang } = response.data;
          await Translate.changeLanguage(lang);
          localStorage.setItem(Storage.LANGUAGE, lang);
        }

        const item = t('profile', { ns: 'profile' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [handleUserAuthenticated, hideLoader, showLoader, t, user],
  );

  const updateProfileState = useCallback((data: Partial<IProfile>, reset?: boolean) => {
    setProfile(current => (reset ? { ...({} as IProfile), ...data } : { ...current, ...data }));
  }, []);

  const contextValue = useMemo<IProfileContext>(
    () => ({ profile, getProfile, updateProfile, updateProfileState }),
    [getProfile, profile, updateProfile, updateProfileState],
  );

  return <ProfileContext.Provider value={contextValue}>{children}</ProfileContext.Provider>;
};

export { ProfileProvider, ProfileContext };
