import { useState, useEffect, memo } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { IconBaseProps } from 'react-icons';
import { FaAngleDown } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

import { motion } from 'framer-motion';

import { AnimatePresence } from '@components/AnimatePresence';
import Link from '@components/Link';

import { useMenu } from '@menu/hooks/useMenu';

import { Container, SubItems, SubItemContainer } from './styles';

export interface ISubItem {
  title: string;
  path: string;
}
interface ISidebarItemProps {
  title: string;
  icon: React.ComponentType<React.PropsWithChildren<IconBaseProps>>;
  path: string;
  menuActive?: boolean;
  subItems?: ISubItem[];
}

const SidebarItemComponent: React.FC<React.PropsWithChildren<ISidebarItemProps>> = ({
  title,
  icon: Icon,
  path,
  menuActive,
  subItems,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { hideSidebarMobile } = useMenu();

  const [pathSelected, setPathSelected] = useState(false);
  const [menuSelected, setMenuSelected] = useState(false);

  const navigateTo = (to: string): void => {
    if (isMobileOnly) hideSidebarMobile();
    navigate(to);
  };

  useEffect(() => {
    setPathSelected(pathname.startsWith(path));
  }, [pathname, path]);

  return (
    <>
      <AnimatePresence>
        {!subItems && (
          <Container $isSelected={pathSelected} $menuActive={menuActive}>
            <Link to={path} onClick={() => hideSidebarMobile()}>
              <Icon size={24} />
              {menuActive && (
                <motion.span
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ type: 'spring', stiffness: 120, delay: 0.2 }}
                >
                  {title}
                </motion.span>
              )}
            </Link>
          </Container>
        )}
      </AnimatePresence>
      {subItems && (
        <>
          <Container $isSelected={pathSelected || menuSelected} $menuActive={menuActive}>
            <button onClick={() => (menuActive ? setMenuSelected(prev => !prev) : navigateTo(path))} type="button">
              <Icon size={24} />
              {menuActive && <span>{title}</span>}
              {menuActive && <FaAngleDown size={16} className="indicator" />}
            </button>
          </Container>
          {(menuSelected || pathSelected) && menuActive && (
            <SubItems>
              {subItems.map(item => (
                <SubItemContainer key={item.path} $isSelected={pathSelected} $menuActive={menuActive}>
                  <Link to={item.path} onClick={() => hideSidebarMobile()}>
                    {menuActive && <span>{item.title}</span>}
                  </Link>
                </SubItemContainer>
              ))}
            </SubItems>
          )}
        </>
      )}
    </>
  );
};

export const SidebarItem = memo(SidebarItemComponent);
