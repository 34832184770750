import { useContextSelector } from 'use-context-selector';

import { AffiliatesSealContext } from '@associations/contexts/AffiliatesSeal';
import { IAffiliatesSealContext } from '@associations/types/Seals/context';

export function useAffiliatesSeal(): IAffiliatesSealContext {
  const seal = useContextSelector(AffiliatesSealContext, cs => cs.seal);

  const getSeal = useContextSelector(AffiliatesSealContext, cs => cs.getSeal);
  const handleSeal = useContextSelector(AffiliatesSealContext, cs => cs.handleSeal);
  const handleSetSeal = useContextSelector(AffiliatesSealContext, cs => cs.handleSetSeal);
  const uploadPassportDocument = useContextSelector(AffiliatesSealContext, cs => cs.uploadPassportDocument);
  const deletePassportDocument = useContextSelector(AffiliatesSealContext, cs => cs.deletePassportDocument);

  return {
    seal,
    getSeal,
    handleSeal,
    handleSetSeal,
    uploadPassportDocument,
    deletePassportDocument,
  };
}
