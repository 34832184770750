import { memo, useEffect } from 'react';

import { MotionProps } from 'framer-motion';

import { Container } from './styles';

interface IBackdropProps {
  animate?: MotionProps;
}

const Backdrop: React.FC<React.PropsWithChildren<IBackdropProps>> = ({ children, animate }) => {
  useEffect(() => {
    document.body.style.height = '100vh';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.height = 'initial';
      document.body.style.overflow = 'initial';
    };
  }, []);

  return <Container {...animate}>{children}</Container>;
};

export default memo(Backdrop);
