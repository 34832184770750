import { AnimatePresence as AnimatePresenceMotion, AnimatePresenceProps } from 'framer-motion';

interface NewAnimatePresenceProps extends Omit<AnimatePresenceProps, 'children'> {
  children: React.ReactNode;
}

export const AnimatePresence: React.FC<React.PropsWithChildren> = ({ children }) => {
  const NewAnimatePresence: React.FC<NewAnimatePresenceProps> = AnimatePresenceMotion;
  return <NewAnimatePresence>{children}</NewAnimatePresence>;
};
