import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { AffiliatesAthletesAffiliation } = lazily(() => import('@associations/pages/AffiliatesAthletesAffiliation'));
const { AffiliatesAthletesCreate } = lazily(() => import('@associations/pages/AffiliatesAthletesCreate'));
const { AffiliatesAthletesEdit } = lazily(() => import('@associations/pages/AffiliatesAthletesEdit'));
const { AffiliatesAthletesList } = lazily(() => import('@associations/pages/AffiliatesAthletesList'));
const { AffiliatesHorsesAffiliation } = lazily(() => import('@associations/pages/AffiliatesHorsesAffiliation'));
const { AffiliatesHorsesCreate } = lazily(() => import('@associations/pages/AffiliatesHorsesCreate'));
const { AffiliatesHorsesEdit } = lazily(() => import('@associations/pages/AffiliatesHorsesEdit'));
const { AffiliatesHorsesList } = lazily(() => import('@associations/pages/AffiliatesHorsesList'));
const { AffiliatesHorsesMicrochip } = lazily(() => import('@associations/pages/AffiliatesHorsesMicrochip'));
const { AffiliatesHorsesSeal } = lazily(() => import('@associations/pages/AffiliatesHorsesSeal'));
const { CompaniesForm } = lazily(() => import('@associations/pages/CompaniesForm'));
const { CompaniesList } = lazily(() => import('@associations/pages/CompaniesList'));
const { InvitesList } = lazily(() => import('@associations/pages/InvitesList'));

export default [
  {
    component: AffiliatesAthletesList,
    path: 'affiliates/athletes',
    title: 'private.affiliates_athletes',
    permission: Roles.GET_AFFILIATES,
    canAccess: true,
  },
  {
    component: AffiliatesAthletesAffiliation,
    path: 'affiliates/athletes/:id/affiliation',
    title: 'private.affiliates_affiliation',
    permission: [Roles.GET_AFFILIATIONS, Roles.HANDLE_AFFILIATIONS],
    canAccess: true,
  },
  {
    component: AffiliatesAthletesCreate,
    path: 'affiliates/athletes/new',
    title: 'private.affiliates_athletes_new',
    permission: Roles.CREATE_AFFILIATE,
    canAccess: true,
  },
  {
    component: AffiliatesAthletesEdit,
    path: 'affiliates/athletes/:id/edit',
    title: 'private.affiliates_athletes_edit',
    permission: Roles.UPDATE_AFFILIATE,
    canAccess: true,
  },
  {
    component: AffiliatesHorsesList,
    path: 'affiliates/horses',
    title: 'private.affiliates_horses',
    permission: Roles.GET_AFFILIATES,
    canAccess: true,
  },
  {
    component: AffiliatesHorsesEdit,
    path: 'affiliates/horses/:id/edit',
    title: 'private.affiliates_horses_edit',
    permission: Roles.UPDATE_AFFILIATE,
    canAccess: true,
  },
  {
    component: AffiliatesHorsesCreate,
    path: 'affiliates/horses/new',
    title: 'private.affiliates_horses_new',
    permission: Roles.CREATE_AFFILIATE,
    canAccess: true,
  },
  {
    component: AffiliatesHorsesSeal,
    path: 'affiliates/horses/:id/seal',
    title: 'private.affiliates_horses_seal',
    permission: [Roles.GET_SEALS, Roles.HANDLE_SEALS],
    canAccess: true,
  },
  {
    component: AffiliatesHorsesMicrochip,
    path: 'affiliates/horses/:id/microchip',
    title: 'private.affiliates_horses_microchip',
    permission: [Roles.GET_MICROCHIPS, Roles.HANDLE_MICROCHIPS],
    canAccess: true,
  },
  {
    component: AffiliatesHorsesAffiliation,
    path: 'affiliates/horses/:id/affiliation',
    title: 'private.affiliates_affiliation',
    permission: [Roles.GET_AFFILIATIONS, Roles.HANDLE_AFFILIATIONS],
    canAccess: true,
  },
  {
    component: CompaniesList,
    path: 'companies',
    title: 'private.companies',
    permission: Roles.GET_ASSOCIATION_COMPANIES,
    canAccess: true,
  },
  {
    component: CompaniesForm,
    path: 'companies/new',
    title: 'private.companies_new',
    permission: Roles.CREATE_ASSOCIATION_COMPANY,
    canAccess: true,
  },
  {
    component: CompaniesForm,
    path: 'companies/:id/edit',
    title: 'private.companies_edit',
    permission: Roles.UPDATE_ASSOCIATION_COMPANY,
    canAccess: true,
  },
  {
    component: InvitesList,
    path: 'invites',
    title: 'private.invites',
    permission: Roles.GET_INVITES,
    canAccess: true,
  },
] as IRoute[];
