import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import axios from 'axios';
import { Buffer } from 'buffer';
import { isBefore, parse } from 'date-fns';

import { Storage } from '@constants/Storage';

import { useAuth } from '@users/hooks/useAuth';
import { ITokens } from '@users/types/Auth/auth';

export const Authenticate: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['auth']);

  const { signOut } = useAuth();

  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);

  function getExpirationDateFromToken(token: string): Date {
    const { exp } = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
    const expirationDate = parse(exp, 't', new Date());
    return expirationDate;
  }

  const expirationIsInvalidFromToken = useCallback((tokens: string, type: 'ACCESS_TOKEN' | 'REFRESH_TOKEN') => {
    let token = '';

    if (type === 'ACCESS_TOKEN') {
      const { accessToken }: ITokens = JSON.parse(tokens);
      token = accessToken;
    }
    if (type === 'REFRESH_TOKEN') {
      const { refreshToken }: ITokens = JSON.parse(tokens);
      token = refreshToken;
    }

    return isBefore(getExpirationDateFromToken(token), new Date());
  }, []);

  const validJwtRefreshToken = useCallback(async () => {
    const tokens = localStorage.getItem(Storage.TOKENS);

    if (tokens && expirationIsInvalidFromToken(tokens, 'REFRESH_TOKEN')) {
      toast(t('session_expired', { ns: 'auth' }), { type: 'error' });
      await signOut();
    }
  }, [expirationIsInvalidFromToken, signOut, t]);

  const hasPermissionsInToken = useCallback(() => {
    const tokens = localStorage.getItem(Storage.TOKENS);
    if (tokens) {
      const { accessToken }: ITokens = JSON.parse(tokens);
      const decrypt = JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString());
      return decrypt?.pms.length > 0;
    }
    return false;
  }, []);

  const validJwtAccessToken = useCallback(async () => {
    const tokens = localStorage.getItem(Storage.TOKENS);

    if (tokens && expirationIsInvalidFromToken(tokens, 'ACCESS_TOKEN')) {
      const { refreshToken }: ITokens = JSON.parse(tokens);

      try {
        const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/refreshToken`, { refreshToken });
        localStorage.setItem(Storage.TOKENS, JSON.stringify({ accessToken: data.accessToken, refreshToken }));
      } catch (e) {
        toast(t('session_expired', { ns: 'auth' }), { type: 'error' });
        await signOut();
      }
    }

    if (!tokens && !hasPermissionsInToken()) {
      await signOut();
    }

    setShouldRenderComponent(true);
  }, [expirationIsInvalidFromToken, hasPermissionsInToken, signOut, t]);

  const validJwtTokens = useCallback(async () => {
    await validJwtRefreshToken();
    await validJwtAccessToken();
  }, [validJwtRefreshToken, validJwtAccessToken]);

  useEffect(() => {
    validJwtTokens();

    return () => {
      setShouldRenderComponent(false);
    };
  }, [validJwtTokens]);

  function render(): React.ReactNode {
    return shouldRenderComponent ? children : null;
  }

  return <>{render()}</>;
};
