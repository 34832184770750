import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IFoodContext } from '@foods/types/Foods/context';
import { IFood } from '@foods/types/Foods/food';
import { ICreateFoodRequest, IUpdateFoodRequest } from '@foods/types/Foods/requests';

import { useLoader } from '@loader/hooks/useLoader';

const FoodsContext = createContext<IFoodContext>({} as IFoodContext);

FoodsContext.displayName = 'Foods';

const FoodsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [food, setFood] = useState<IFood>({} as IFood);
  const [foods, setFoods] = useState<IFood[]>([]);

  const getFoodById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.food().getFoodById(id);

        setFood(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getFoods = useCallback(async () => {
    try {
      showLoader();

      const response = await api.food().getFoods();
      setFoods(response.data);

      setFood({} as IFood);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createFood = useCallback(
    async (data: ICreateFoodRequest) => {
      try {
        showLoader();

        await api.food().createFood(data);

        navigate(`/warehouse`);

        const item = t('food', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t],
  );

  const updateFood = useCallback(
    async (data: IUpdateFoodRequest) => {
      try {
        showLoader();

        const response = await api.food().updateFood(data);

        setFood(response.data);

        const item = t('food', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IFoodContext>(
    () => ({ food, foods, createFood, getFoodById, getFoods, updateFood }),
    [food, foods, createFood, getFoodById, getFoods, updateFood],
  );

  return <FoodsContext.Provider value={contextValue}>{children}</FoodsContext.Provider>;
};

export { FoodsProvider, FoodsContext };
