import { AxiosInstance, AxiosResponse } from 'axios';

import EquitesPaginate from '@equites/api/paginate';

import {
  IRiderAppointment,
  IAppointment,
  IAppointmentCalendar,
  IAppointmentDetail,
} from '@appointments/types/Appointments/appointments';
import {
  IGetAppointmentsParamsRequest,
  IGetAppointmentsCalendarRequest,
  ICreateAppointmentRequest,
  IUpdateAppointmentRequest,
  IExportPdfAppointmentsDayRequest,
  ICountAppointmentsByRiderRequest,
  IGetAppointmentsHistoryRequest,
  ISearchAppointmentsByRiderParamsRequest,
  ICountAppointmentsByRiderResponse,
  IGetAppointmentsHistoryResponse,
  IExportPdfAppointmentsHistoryRequest,
} from '@appointments/types/Appointments/requests';
import {
  ISearchAppointmentsToConfirmParamsRequest,
  IUpdateAppointmentToConfirm,
} from '@appointments/types/Confirm/requests';
import { IAppointmentFixed } from '@appointments/types/Fixed/fixed';
import { ICreateFixedAppointmentRequest, IUpdateFixedAppointmentRequest } from '@appointments/types/Fixed/requests';

type RiderAppointments = AxiosResponse<EquitesPaginate<IRiderAppointment>>;
type AppointmentToConfirm = AxiosResponse<EquitesPaginate<IRiderAppointment>>;
type AppointmentsHistory = AxiosResponse<EquitesPaginate<IGetAppointmentsHistoryResponse>>;

export class Appointment {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Appointments

  async getByDay(params: IGetAppointmentsParamsRequest): Promise<AxiosResponse<IAppointment[]>> {
    return this.instance.get(`/appointments`, { params });
  }

  async getByDates(params: IGetAppointmentsCalendarRequest): Promise<AxiosResponse<IAppointmentCalendar[]>> {
    return this.instance.get(`/appointments/dates`, { params });
  }

  async getById(appointmentId: string): Promise<AxiosResponse<IAppointmentDetail>> {
    return this.instance.get(`/appointments/${appointmentId}`);
  }

  async create(data: ICreateAppointmentRequest): Promise<AxiosResponse<IAppointment>> {
    return this.instance.post(`/appointments`, data);
  }

  async update(data: IUpdateAppointmentRequest): Promise<AxiosResponse<IRiderAppointment>> {
    const { id, ...body } = data;
    return this.instance.patch(`/appointments/${id}`, body);
  }

  async delete(appointmentId: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/appointments/${appointmentId}`);
  }

  // Reports

  async exportPdf(params: IExportPdfAppointmentsDayRequest): Promise<AxiosResponse> {
    const response = await this.instance.get(`/appointments/reports/day/pdf/export`, {
      params,
      responseType: 'arraybuffer',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);

    return response;
  }

  async exportHistoryPdf(params: IExportPdfAppointmentsHistoryRequest): Promise<AxiosResponse> {
    const response = await this.instance.get(`/appointments/reports/history/pdf/export`, {
      params,
      responseType: 'arraybuffer',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);

    return response;
  }

  // Count

  async countAppointmentsByRider(
    params: ICountAppointmentsByRiderRequest,
  ): Promise<AxiosResponse<ICountAppointmentsByRiderResponse>> {
    const { riderId, ...query } = params;
    return this.instance.get(`/appointments/riders/${riderId}/count`, { params: query });
  }

  // FixedAppointments

  async getFixedAppointments(riderId: string): Promise<AxiosResponse<IAppointmentFixed[]>> {
    return this.instance.get(`/appointments/riders/${riderId}/fixed`);
  }

  async createFixedAppointment(params: ICreateFixedAppointmentRequest): Promise<AxiosResponse<IAppointmentFixed>> {
    const { riderId, ...body } = params;
    return this.instance.post(`/appointments/riders/${riderId}/fixed`, body);
  }

  async updateFixedAppointment(params: IUpdateFixedAppointmentRequest): Promise<AxiosResponse<IAppointmentFixed>> {
    const { fixedAppointmentId, riderId, ...body } = params;
    return this.instance.patch(`/appointments/${fixedAppointmentId}/riders/${riderId}/fixed`, body);
  }

  async deleteFixedAppointment(fixedAppointmentId: string, riderId: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/appointments/${fixedAppointmentId}/riders/${riderId}/fixed`);
  }

  // ToConfirm

  async getAppointmentsToConfirm(params: ISearchAppointmentsToConfirmParamsRequest): Promise<AppointmentToConfirm> {
    return this.instance.get(`/appointments/to-confirm`, { params });
  }

  async getAppointmentToConfirmById(id: string): Promise<AxiosResponse<IRiderAppointment>> {
    return this.instance.get(`/appointments/${id}/to-confirm`);
  }

  async updateAppointmentToConfirm(params: IUpdateAppointmentToConfirm): Promise<AxiosResponse<void>> {
    const { id, ...body } = params;
    return this.instance.patch(`/appointments/${id}/to-confirm`, body);
  }

  // History

  async getHistory(params: IGetAppointmentsHistoryRequest): Promise<AppointmentsHistory> {
    return this.instance.get(`/appointments/history`, { params });
  }

  async getAppointmentsByRider(data: ISearchAppointmentsByRiderParamsRequest): Promise<RiderAppointments> {
    const { riderId, ...params } = data;
    return this.instance.get(`/appointments/riders/${riderId}/history`, { params });
  }
}

export const appointment = (instance: AxiosInstance): Appointment => new Appointment(instance);
