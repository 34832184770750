import { useEffect, useMemo } from 'react';

import { DefaultTheme, ThemeProvider } from 'styled-components';
import { createContext } from 'use-context-selector';

import { Storage } from '@constants/Storage';

import usePersistedState from '@utils/usePersistedState';

import { lightTheme } from '@styles/theme';

export interface IThemeContextEquites {
  theme: DefaultTheme;
}

const ThemeContextEquites = createContext<IThemeContextEquites>({} as IThemeContextEquites);
ThemeContextEquites.displayName = 'Themes';

const ThemeProviderEquites: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [theme, setTheme] = usePersistedState<DefaultTheme>(Storage.THEME, lightTheme);

  useEffect(() => {
    setTheme(lightTheme);
  }, [setTheme]);

  const contextValue = useMemo<IThemeContextEquites>(() => ({ theme }), [theme]);

  return (
    <ThemeProvider theme={theme}>
      <ThemeContextEquites.Provider value={contextValue}>{children}</ThemeContextEquites.Provider>
    </ThemeProvider>
  );
};

export { ThemeProviderEquites as ThemeProvider, ThemeContextEquites as ThemeContext };
