import { AppConfigurationProvider } from '@configurations/contexts';

import { AppAppointmentProvider } from '@appointments/contexts';

import { AppAssociationProvider } from '@associations/contexts';

import { AppBalanceProvider } from '@balances/contexts';

import { AppBankProvider } from '@banks/contexts';

import { AppCompanyProvider } from '@companies/contexts';

import { AppDashboardProvider } from '@dashboard/contexts';

import { AppFlagProvider } from '@flags/contexts';

import { AppFoodProvider } from '@foods/contexts';

import { AppHorseProvider } from '@horses/contexts';

import { AppLoaderProvider } from '@loader/contexts';

import { AppMenuProvider } from '@menu/contexts';

import { AppModalityProvider } from '@modalities/contexts';

import { AppNotificationProvider } from '@notifications/contexts';

import { AppPaymentMethodsProvider } from '@paymentMethods/contexts';

import { AppRiderProvider } from '@riders/contexts';

import { AppTeacherProvider } from '@teachers/contexts';

import { AppUsersProvider } from '@users/contexts';

const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppMenuProvider>
      <AppLoaderProvider>
        <AppNotificationProvider>
          <AppFlagProvider>
            <AppUsersProvider>
              <AppCompanyProvider>
                <AppConfigurationProvider>
                  <AppModalityProvider>
                    <AppAssociationProvider>
                      <AppBalanceProvider>
                        <AppTeacherProvider>
                          <AppHorseProvider>
                            <AppFoodProvider>
                              <AppRiderProvider>
                                <AppAppointmentProvider>
                                  <AppDashboardProvider>
                                    <AppBankProvider>
                                      <AppPaymentMethodsProvider>{children}</AppPaymentMethodsProvider>
                                    </AppBankProvider>
                                  </AppDashboardProvider>
                                </AppAppointmentProvider>
                              </AppRiderProvider>
                            </AppFoodProvider>
                          </AppHorseProvider>
                        </AppTeacherProvider>
                      </AppBalanceProvider>
                    </AppAssociationProvider>
                  </AppModalityProvider>
                </AppConfigurationProvider>
              </AppCompanyProvider>
            </AppUsersProvider>
          </AppFlagProvider>
        </AppNotificationProvider>
      </AppLoaderProvider>
    </AppMenuProvider>
  );
};

export default AppProvider;
