import { useContextSelector } from 'use-context-selector';

import { SubscriptionContext } from '@users/contexts/Subscription';
import { ISubscriptionContext } from '@users/types/Subscriptions/context';

export function useSubscriptions(): ISubscriptionContext {
  const subscription = useContextSelector(SubscriptionContext, cs => cs.subscription);
  const subscriptionLimits = useContextSelector(SubscriptionContext, cs => cs.subscriptionLimits);

  const getMySubscription = useContextSelector(SubscriptionContext, cs => cs.getMySubscription);
  const getSubscriptionLimits = useContextSelector(SubscriptionContext, cs => cs.getSubscriptionLimits);
  const handleSubscriptionState = useContextSelector(SubscriptionContext, cs => cs.handleSubscriptionState);
  const updatePayment = useContextSelector(SubscriptionContext, cs => cs.updatePayment);
  const updateSubscription = useContextSelector(SubscriptionContext, cs => cs.updateSubscription);

  return {
    subscription,
    subscriptionLimits,
    getMySubscription,
    getSubscriptionLimits,
    handleSubscriptionState,
    updatePayment,
    updateSubscription,
  };
}
