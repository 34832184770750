import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IPublicAssociation, IPublicAssociationCompany } from '@associations/types/PublicAssociation/associations';
import { IPublicAssociationContext } from '@associations/types/PublicAssociation/context';

import { useLoader } from '@loader/hooks/useLoader';

const AssociationPublicContext = createContext<IPublicAssociationContext>({} as IPublicAssociationContext);

AssociationPublicContext.displayName = 'AssociationPublic';

const AssociationPublicProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [association, setAssociation] = useState<IPublicAssociation>({} as IPublicAssociation);
  const [associationCompanies, setAssociationCompanies] = useState<IPublicAssociationCompany[]>([]);

  const getAssociationBySlug = useCallback(
    async (slug: string) => {
      try {
        showLoader();

        const response = await api.association().getPublicAssociationBySlug(slug);

        setAssociation(response.data);
      } catch (err) {
        navigate({ pathname: '/404', search: 'noPrevious=true' });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader],
  );

  const getAssociationCompanies = useCallback(
    async (associationId: string) => {
      try {
        showLoader();

        const response = await api.association().getPublicCompanies(associationId);

        setAssociationCompanies(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IPublicAssociationContext>(
    () => ({ association, associationCompanies, getAssociationBySlug, getAssociationCompanies }),
    [association, associationCompanies, getAssociationBySlug, getAssociationCompanies],
  );

  return <AssociationPublicContext.Provider value={contextValue}>{children}</AssociationPublicContext.Provider>;
};

export { AssociationPublicProvider, AssociationPublicContext };
