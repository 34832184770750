import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { TOptions } from 'i18next';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useAffiliatesMicrochip } from '@associations/hooks/useAffiliatesMicrochip';
import { useAffiliatesSeal } from '@associations/hooks/useAffiliatesSeal';
import {
  IAffiliatedItem,
  IAffiliated,
  IAffiliation,
  IFilterAffiliates,
  IMicrochip,
  ISeal,
} from '@associations/types/Affiliates/affiliates';
import {
  IGetAllAffiliatesRequest,
  ICreateAffiliatedRequest,
  IUpdateAffiliatedRequest,
  IHandleAffiliationRequest,
} from '@associations/types/Affiliates/requests';
import { IAffiliatedHorseContext } from '@associations/types/Horses/context';

import { useLoader } from '@loader/hooks/useLoader';

type IAffiliates = EquitesPaginate<IAffiliatedItem>;

const AffiliatesHorsesContext = createContext<IAffiliatedHorseContext>({} as IAffiliatedHorseContext);

AffiliatesHorsesContext.displayName = 'AffiliatesHorses';

const AffiliatesHorsesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'affiliates']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [affiliated, setAffiliated] = useState<IAffiliated>({} as IAffiliated);
  const [affiliates, setAffiliates] = useState<IAffiliates>({} as IAffiliates);
  const [responsibleAthletes, setResponsibleAthletes] = useState<IAffiliated[]>([]);
  const [affiliation, setAffiliation] = useState<IAffiliation>({} as IAffiliation);
  const [filterAffiliates, setFilterAffiliates] = useState<IFilterAffiliates>({ limit: 10, page: 1 });

  const { handleSetMicrochip } = useAffiliatesMicrochip();
  const { handleSetSeal } = useAffiliatesSeal();

  const getAffiliatedById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.affiliates().getById(id);

        const payload = { ...response.data };

        if (response.data.birthDate) {
          const birthDate = format(parseISO(response.data.birthDate), 'ddMMyyyy');
          Object.assign(payload, { birthDate });
        }

        setAffiliated(payload);

        if (response.data.affiliation) setAffiliation(response.data.affiliation);
        if (response.data.seal) handleSetSeal(response.data.seal);
        if (response.data.microchip) handleSetMicrochip(response.data.microchip);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [handleSetMicrochip, handleSetSeal, showLoader, hideLoader],
  );

  const getAffiliates = useCallback(
    async (params: IGetAllAffiliatesRequest) => {
      try {
        showLoader();

        const response = await api.affiliates().getAll(params);

        setAffiliates(response.data);

        setAffiliated({} as IAffiliated);
        setAffiliation({} as IAffiliation);
        handleSetMicrochip({} as IMicrochip);
        handleSetSeal({} as ISeal);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [handleSetMicrochip, handleSetSeal, showLoader, hideLoader],
  );

  const getResponsibleAthletes = useCallback(async () => {
    try {
      showLoader();

      const response = await api.affiliates().getByType({ type: 'ATHLETE' });

      setResponsibleAthletes(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [showLoader, hideLoader]);

  const createAffiliated = useCallback(
    async (params: ICreateAffiliatedRequest) => {
      try {
        showLoader();

        const response = await api.affiliates().createAffiliated(params);

        toast(
          t('crud.created_success', { item: t('affiliated', { ns: 'affiliates' }), context: 'male', ns: 'messages' }),
          { type: 'success' },
        );

        navigate(`/affiliates/horses/${response.data.id}/edit`);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, t, navigate, hideLoader],
  );

  const updateAffiliated = useCallback(
    async (params: IUpdateAffiliatedRequest) => {
      try {
        showLoader();

        const response = await api.affiliates().updateAffiliated(params);

        toast(
          t('crud.updated_success', { item: t('affiliated', { ns: 'affiliates' }), context: 'male', ns: 'messages' }),
          { type: 'success' },
        );

        await getAffiliatedById(response.data.id);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [t, getAffiliatedById, showLoader, hideLoader],
  );

  const deleteAffiliated = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.affiliates().deleteAffiliated(id);

        setAffiliates(old => ({
          ...old,
          totalItems: old.totalItems - 1,
          items: old.items.filter(a => a.affiliatedId !== id),
        }));

        toast(
          t('crud.deleted_success', { item: t('affiliated', { ns: 'affiliates' }), context: 'male', ns: 'messages' }),
          { type: 'success' },
        );
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [t, showLoader, hideLoader],
  );

  const getAffiliation = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const { data } = await api.affiliates().getAffiliation(id);

        setAffiliation(data);
      } catch (err) {
        handleErrorApi({ err, otherStatus: [404] });
      } finally {
        hideLoader();
      }
    },
    [showLoader, hideLoader],
  );

  const handleAffiliation = useCallback(
    async (affiliationData: IHandleAffiliationRequest) => {
      try {
        showLoader();

        const { data } = await api.affiliates().handleAffiliation(affiliationData);

        navigate(`/affiliates/horses/${affiliationData.affiliatedId}/edit`);

        setAffiliation(data);

        const messageOptions: TOptions = {
          ns: 'messages',
          context: 'female',
          item: t('affiliation', { ns: 'affiliates' }),
        };
        const message = t(affiliationData.id ? 'crud.updated_success' : 'crud.created_success', messageOptions);
        toast(message, { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, navigate, t, hideLoader],
  );

  const approveAffiliated = useCallback(
    async (data: IUpdateAffiliatedRequest) => {
      try {
        showLoader();

        const response = await api.affiliates().updateAffiliated(data);

        toast(
          t('register.approved_success', {
            ns: 'messages',
            context: 'male',
            item: t('affiliated', { ns: 'affiliates' }),
          }),
          { type: 'success' },
        );

        await getAffiliatedById(response.data.id);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [t, getAffiliatedById, showLoader, hideLoader],
  );

  const reproveAffiliated = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.affiliates().deleteAffiliated(id);

        toast(
          t('register.reproved_success', {
            ns: 'messages',
            context: 'male',
            item: t('affiliated', { ns: 'affiliates' }),
          }),
          { type: 'success' },
        );

        navigate('/affiliates/horses');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [showLoader, t, navigate, hideLoader],
  );

  const handleFilters = useCallback((data: IFilterAffiliates, reset: boolean) => {
    setFilterAffiliates(old => (reset ? data : { ...old, ...data }));
  }, []);

  const resetAffiliated = useCallback(() => {
    setAffiliated({} as IAffiliated);
    setAffiliation({} as IAffiliation);
  }, []);

  const contextValue = useMemo<IAffiliatedHorseContext>(
    () => ({
      affiliated,
      affiliates,
      affiliation,
      filterAffiliates,
      responsibleAthletes,
      approveAffiliated,
      createAffiliated,
      deleteAffiliated,
      getAffiliatedById,
      getAffiliates,
      getResponsibleAthletes,
      getAffiliation,
      handleAffiliation,
      handleFilters,
      reproveAffiliated,
      resetAffiliated,
      updateAffiliated,
    }),
    [
      affiliated,
      affiliates,
      affiliation,
      filterAffiliates,
      responsibleAthletes,
      approveAffiliated,
      createAffiliated,
      deleteAffiliated,
      getAffiliatedById,
      getAffiliates,
      getResponsibleAthletes,
      getAffiliation,
      handleAffiliation,
      handleFilters,
      reproveAffiliated,
      resetAffiliated,
      updateAffiliated,
    ],
  );

  return <AffiliatesHorsesContext.Provider value={contextValue}>{children}</AffiliatesHorsesContext.Provider>;
};

export { AffiliatesHorsesProvider, AffiliatesHorsesContext };
