import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import Clean from '@utils/Clean';
import { handleErrorApi } from '@utils/handleError';

import { IAssociationCompany, IRegisterCompanyByAssociation } from '@associations/types/Association/association';
import { IAssociationContext } from '@associations/types/Association/context';
import {
  ICreateAssociationCompanyRequest,
  IUpdateAssociationCompanyRequest,
  IHandleSubmitRegisterCompanyData,
  IHandleSubmitRegisterCompanyResponsibleContact,
  IHandleSubmitAssociationCompanyIds,
  IReportListAffiliatesParams,
} from '@associations/types/Association/requests';

import { useLoader } from '@loader/hooks/useLoader';

const AssociationContext = createContext<IAssociationContext>({} as IAssociationContext);

AssociationContext.displayName = 'Associations';

const AssociationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'companies']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [associationCompany, setAssociationCompany] = useState<IAssociationCompany>({} as IAssociationCompany);
  const [associationCompanies, setAssociationCompanies] = useState<IAssociationCompany[]>([]);
  const [avatar, setAvatar] = useState<File | undefined>();
  const [registerStep, setRegisterStep] = useState(1);
  const [registerCompany, setRegisterCompany] = useState<IRegisterCompanyByAssociation>(
    {} as IRegisterCompanyByAssociation,
  );

  const getAssociationCompanyById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.association().getCompanyById(id);

        setAssociationCompany(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getAllAssociationCompanies = useCallback(async () => {
    try {
      showLoader();

      const response = await api.association().getAllCompanies();

      setAssociationCompanies(response.data);
      setAssociationCompany({} as IAssociationCompany);
      setAvatar(undefined);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createAssociationCompany = useCallback(
    async (data: ICreateAssociationCompanyRequest) => {
      try {
        showLoader();

        const response = await api.association().createCompany(data);

        if (avatar) {
          const formData = new FormData();
          formData.append('avatar', avatar);

          const responseAvatar = await api.association().updateCompanyAvatar(response.data.id, formData);

          Object.assign(response.data, { avatarUrl: responseAvatar.data.avatarUrl });
        }

        setAssociationCompanies(old => [...old, response.data]);

        const { type } = data;
        const item = type === 'TEAM' ? t('team', { ns: 'companies' }) : t('entity', { ns: 'companies' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });

        navigate('/companies');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [avatar, hideLoader, navigate, showLoader, t],
  );

  const updateAssociationCompany = useCallback(
    async (data: IUpdateAssociationCompanyRequest) => {
      try {
        showLoader();

        const response = await api.association().updateCompany(data);

        setAssociationCompanies(old => [...old.filter(a => a.id !== data.id), response.data]);
        setAssociationCompany(response.data);

        const item = data.type === 'TEAM' ? t('team', { ns: 'companies' }) : t('entity', { ns: 'companies' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteAssociationCompany = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.association().deleteCompany(id);

        setAssociationCompanies(old => old.filter(a => a.id !== id));

        const { type } = associationCompany;
        const item = type === 'TEAM' ? t('team', { ns: 'companies' }) : t('entity', { ns: 'companies' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });

        navigate('/companies');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [associationCompany, hideLoader, navigate, showLoader, t],
  );

  const handleAvatar = useCallback(async (file?: File) => {
    setAvatar(file || undefined);
  }, []);

  const handleRegisterStep = useCallback((step: number) => {
    setRegisterStep(step);
  }, []);

  const handleSubmitCompanyData = useCallback((data: IHandleSubmitRegisterCompanyData) => {
    setRegisterCompany(current => {
      const payload = { ...current, ...data };

      if (data.cnpj) {
        const cnpj = data.cnpj ? Clean.cnpj(data.cnpj) : Clean.cnpj(current.cnpj);
        if (cnpj) Object.assign(payload, { cnpj });
      }

      return payload;
    });
  }, []);

  const handleSubmitCompanyResponsibleContact = useCallback((data: IHandleSubmitRegisterCompanyResponsibleContact) => {
    setRegisterCompany(current => {
      const phone = data.phone ? Clean.phone(data.phone) : Clean.phone(current.phone);

      const payload = { ...current, ...data, phone };

      if (data.cpf) {
        const cpf = data.cpf ? Clean.cpf(data.cpf) : Clean.cpf(current.cpf);
        if (cpf) Object.assign(payload, { cpf });
      }

      return payload;
    });
  }, []);

  const handleAssociationCompanyByLink = useCallback(async () => {
    try {
      showLoader();

      let response = null;

      if (registerCompany.companyId) {
        response = await api.invite().sendAcceptInviteToUpdateCompany(registerCompany);
      } else {
        response = await api.invite().sendAcceptInviteToCreateCompany(registerCompany);
      }

      if (avatar) {
        const formData = new FormData();
        formData.append('avatar', avatar);

        await api.invite().updateCompanyAvatar(response.data.id, formData, registerCompany.token);
      }

      setRegisterStep(current => current + 1);
      setRegisterCompany({} as IRegisterCompanyByAssociation);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [showLoader, registerCompany, avatar, hideLoader]);

  const sendMailToRegisterAffiliates = useCallback(
    async (emailsRiders: string[]) => {
      try {
        showLoader();

        setRegisterCompany(current => ({ ...current, emailsRiders }));

        setRegisterStep(current => current + 1);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const handleSubmitAssociationCompanyIds = useCallback((data: IHandleSubmitAssociationCompanyIds) => {
    setRegisterCompany(current => ({ ...current, ...data }));
  }, []);

  const reproveAssociationCompany = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.association().deleteCompany(id);

        const { type } = associationCompany;
        const item = type === 'TEAM' ? t('team', { ns: 'companies' }) : t('entity', { ns: 'companies' });
        toast(t('register.reproved_success', { ns: 'messages', context: 'female', item }), { type: 'success' });

        navigate('/companies');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [associationCompany, hideLoader, navigate, showLoader, t],
  );

  const approveAssociationCompany = useCallback(
    async (data: IUpdateAssociationCompanyRequest) => {
      try {
        showLoader();

        await api.association().updateCompany(data);

        const { type } = associationCompany;
        const item = type === 'TEAM' ? t('team', { ns: 'companies' }) : t('entity', { ns: 'companies' });
        toast(t('register.approved_success', { ns: 'messages', context: 'female', item }), { type: 'success' });

        navigate('/companies');
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [associationCompany, hideLoader, navigate, showLoader, t],
  );

  const setTokenToRegisterCompany = useCallback((token: string) => {
    setRegisterCompany(current => ({ ...current, token }));
  }, []);

  const exportListAllCompaniesPDF = useCallback(async () => {
    try {
      showLoader();

      await api.association().getReportAllCompaniesPdf();
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const exportListAffiliatesPDF = useCallback(
    async (params: IReportListAffiliatesParams) => {
      try {
        showLoader();

        await api.association().getReportListAffiliatesPdf(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const exportListAffiliatesCSV = useCallback(
    async (params: IReportListAffiliatesParams) => {
      try {
        showLoader();

        await api.association().getReportListAffiliatesCsv(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IAssociationContext>(
    () => ({
      associationCompanies,
      associationCompany,
      avatar,
      registerCompany,
      registerStep,
      approveAssociationCompany,
      createAssociationCompany,
      handleAssociationCompanyByLink,
      deleteAssociationCompany,
      exportListAllCompaniesPDF,
      exportListAffiliatesCSV,
      exportListAffiliatesPDF,
      getAllAssociationCompanies,
      getAssociationCompanyById,
      handleAvatar,
      handleRegisterStep,
      handleSubmitAssociationCompanyIds,
      handleSubmitCompanyData,
      handleSubmitCompanyResponsibleContact,
      updateAssociationCompany,
      reproveAssociationCompany,
      sendMailToRegisterAffiliates,
      setTokenToRegisterCompany,
    }),
    [
      associationCompanies,
      associationCompany,
      avatar,
      registerCompany,
      registerStep,
      approveAssociationCompany,
      createAssociationCompany,
      handleAssociationCompanyByLink,
      deleteAssociationCompany,
      exportListAllCompaniesPDF,
      exportListAffiliatesCSV,
      exportListAffiliatesPDF,
      getAllAssociationCompanies,
      getAssociationCompanyById,
      handleAvatar,
      handleRegisterStep,
      handleSubmitAssociationCompanyIds,
      handleSubmitCompanyData,
      handleSubmitCompanyResponsibleContact,
      updateAssociationCompany,
      reproveAssociationCompany,
      sendMailToRegisterAffiliates,
      setTokenToRegisterCompany,
    ],
  );

  return <AssociationContext.Provider value={contextValue}>{children}</AssociationContext.Provider>;
};

export { AssociationProvider, AssociationContext };
