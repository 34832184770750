import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IPublicAffiliatesContext } from '@associations/types/PublicAffiliates/context';
import { IPublicAffiliated } from '@associations/types/PublicAffiliates/public';
import {
  IGetPublicAffiliatesParams,
  IExportPublicAffiliatesParams,
} from '@associations/types/PublicAffiliates/requests';

import { useLoader } from '@loader/hooks/useLoader';

const AffiliatesPublicContext = createContext<IPublicAffiliatesContext>({} as IPublicAffiliatesContext);

AffiliatesPublicContext.displayName = 'AffiliatesPublic';

const AffiliatesPublicProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [affiliates, setAffiliates] = useState<EquitesPaginate<IPublicAffiliated>>(
    {} as EquitesPaginate<IPublicAffiliated>,
  );

  const getAffiliates = useCallback(
    async (params: IGetPublicAffiliatesParams) => {
      try {
        showLoader();

        const response = await api.affiliates().getPublicAffiliates(params);

        setAffiliates(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const exportListAffiliatesCSV = useCallback(
    async (params: IExportPublicAffiliatesParams) => {
      try {
        showLoader();

        await api.affiliates().getPublicAffiliatesCsv(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const exportListAffiliatesPDF = useCallback(
    async (params: IExportPublicAffiliatesParams) => {
      try {
        showLoader();

        await api.affiliates().getPublicAffiliatesPdf(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IPublicAffiliatesContext>(
    () => ({ affiliates, exportListAffiliatesCSV, exportListAffiliatesPDF, getAffiliates }),
    [affiliates, exportListAffiliatesCSV, exportListAffiliatesPDF, getAffiliates],
  );

  return <AffiliatesPublicContext.Provider value={contextValue}>{children}</AffiliatesPublicContext.Provider>;
};

export { AffiliatesPublicProvider, AffiliatesPublicContext };
