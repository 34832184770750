import { rgba } from 'polished';
import styled from 'styled-components';

import breakpoints from '@styles/breakpoints';

export const Container = styled.div`
  align-items: center;
  background: ${props => rgba(props.theme.colors.primary, 0.85)};
  bottom: 0;
  box-shadow: 0 3px 6px ${props => rgba(props.theme.colors.black, 0.2)};
  display: flex;
  min-height: 150px;
  padding: 32px;
  position: fixed;
  z-index: 3;
  width: 100%;
  @media ${breakpoints.sm} {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  color: ${props => props.theme.colors.white};
  margin-right: auto;
  a {
    color: ${props => props.theme.colors.white};
    font-weight: bold;
  }
  @media ${breakpoints.sm} {
    margin-bottom: 16px;
  }
`;
