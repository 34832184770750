import { AppointmentsProvider } from './Appointments';
import { AppointmentsConfirmProvider } from './AppointmentsConfirm';
import { AppointmentsFixedProvider } from './AppointmentsFixed';

export const AppAppointmentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <AppointmentsProvider>
      <AppointmentsFixedProvider>
        <AppointmentsConfirmProvider>{children}</AppointmentsConfirmProvider>
      </AppointmentsFixedProvider>
    </AppointmentsProvider>
  );
};
