import { AxiosInstance, AxiosResponse } from 'axios';

import { IGetWalletsRequest, ICreateWalletRequest, IUpdateWalletRequest } from '@balances/types/Wallets/requests';
import { IWallet } from '@balances/types/Wallets/wallets';

export class Wallet {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAll(params?: IGetWalletsRequest): Promise<AxiosResponse<IWallet[]>> {
    return this.instance.get(`/balances/wallets`, { params });
  }

  create(data: ICreateWalletRequest): Promise<AxiosResponse<IWallet>> {
    return this.instance.post(`/balances/wallets`, data);
  }

  update(data: IUpdateWalletRequest): Promise<AxiosResponse<IWallet>> {
    const { id, ...body } = data;
    return this.instance.patch(`/balances/wallets/${id}`, body);
  }
}

export const wallet = (instance: AxiosInstance): Wallet => new Wallet(instance);
