import { AxiosInstance, AxiosResponse } from 'axios';

import EquitesPaginate from '@equites/api/paginate';

import { IHorseFeed, IHorseFeedHistory } from '@horses/types/Feeds/feed';
import { ICreateFeedRequest, IGetHorseFeedHistoryRequest, IDeleteFeedFoodRequest } from '@horses/types/Feeds/requests';

type FeedHistoryResponse = Promise<AxiosResponse<EquitesPaginate<IHorseFeedHistory>>>;

export class FeedHorse {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Feeds

  createFeed(data: ICreateFeedRequest): Promise<AxiosResponse> {
    return this.instance.post(`/horses/feeds`, data);
  }

  getFeedHorses(): Promise<AxiosResponse<IHorseFeed[]>> {
    return this.instance.get(`/horses/feeds`);
  }

  getFeedHistoryByHorse(params: IGetHorseFeedHistoryRequest): FeedHistoryResponse {
    const { horseId, ...queryParams } = params;
    return this.instance.get(`/horses/${horseId}/feeds/history`, { params: { ...queryParams } });
  }

  // Feeds foods

  deleteFeedFood(data: IDeleteFeedFoodRequest): Promise<AxiosResponse> {
    const { feedId, feedFoodId } = data;
    return this.instance.delete(`/horses/feeds/${feedId}/feed-foods/${feedFoodId}`);
  }
}

export const feedHorse = (instance: AxiosInstance): FeedHorse => new FeedHorse(instance);
