import { lazily } from 'react-lazily';

import { IRoute } from '@routes/index';

const { ForgotPassword } = lazily(() => import('@users/pages/ForgotPassword'));
const { Login } = lazily(() => import('@users/pages/Login'));
const { Register } = lazily(() => import('@users/pages/Register'));
const { ResetPassword } = lazily(() => import('@users/pages/ResetPassword'));

export default [
  {
    component: ForgotPassword,
    path: 'forgot',
    title: 'public.forgot',
    canAccess: true,
  },
  {
    component: Login,
    path: 'login',
    title: 'public.login',
    canAccess: true,
  },
  {
    component: Register,
    path: 'register',
    title: 'public.register',
    canAccess: true,
  },
  {
    component: ResetPassword,
    path: 'reset',
    title: 'public.reset',
    canAccess: true,
  },
] as IRoute[];
