import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IPasswordsContext } from '@users/types/Passwords/context';
import { IResetPasswordRequest } from '@users/types/Passwords/requests';

const PasswordsContext = createContext<IPasswordsContext>({} as IPasswordsContext);

PasswordsContext.displayName = 'Passwords';

const PasswordsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['auth']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [emailToRecoverPassword, setEmailToRecoverPassword] = useState('');

  const handleEmailToRecover = useCallback(async (email: string) => {
    setEmailToRecoverPassword(email);
  }, []);

  const recoverPassword = useCallback(
    async (email: string) => {
      try {
        showLoader();

        await api.password().recover(email);

        setEmailToRecoverPassword(email);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const resetPassword = useCallback(
    async (data: IResetPasswordRequest) => {
      try {
        showLoader();

        await api.password().reset(data);

        navigate('/login', { replace: true });

        const message = t('reset_password_success', { ns: 'auth' });
        toast(message, { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t],
  );

  const contextValue = useMemo<IPasswordsContext>(
    () => ({ emailToRecoverPassword, handleEmailToRecover, recoverPassword, resetPassword }),
    [emailToRecoverPassword, handleEmailToRecover, recoverPassword, resetPassword],
  );

  return <PasswordsContext.Provider value={contextValue}>{children}</PasswordsContext.Provider>;
};

export { PasswordsProvider, PasswordsContext };
