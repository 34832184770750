import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IBank } from '@banks/types/Banks/banks';
import { IBanksContext } from '@banks/types/Banks/context';

import { useLoader } from '@loader/hooks/useLoader';

const BanksContext = createContext<IBanksContext>({} as IBanksContext);
BanksContext.displayName = 'Banks';

const BanksProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [banks, setBanks] = useState<IBank[]>([]);

  const getBanks = useCallback(async () => {
    try {
      showLoader();

      const response = await api.banks().getBanks();

      setBanks(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const contextValue = useMemo<IBanksContext>(() => ({ banks, getBanks }), [banks, getBanks]);

  return <BanksContext.Provider value={contextValue}>{children}</BanksContext.Provider>;
};

export { BanksProvider, BanksContext };
