import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { FoodsForm } = lazily(() => import('@foods/pages/FoodsForm'));
const { FoodsList } = lazily(() => import('@foods/pages/FoodsList'));

export default [
  {
    component: FoodsList,
    path: 'warehouse',
    title: 'private.warehouse',
    permission: Roles.GET_FOODS,
    canAccess: true,
  },
  {
    component: FoodsForm,
    path: 'warehouse/:id/edit',
    title: 'private.warehouse_edit',
    permission: Roles.UPDATE_FOOD,
    canAccess: true,
  },
  {
    component: FoodsForm,
    path: 'warehouse/new',
    title: 'private.warehouse_new',
    permission: Roles.CREATE_FOOD,
    canAccess: true,
  },
] as IRoute[];
