import styled from 'styled-components';

import breakpoints from '@styles/breakpoints';

export const Container = styled.main`
  display: flex;

  width: 100%;
  height: 100%;
`;

export const WrapperContent = styled.section`
  margin-left: auto;
  padding: 0 32px;
  width: calc(100vw - 117px);
  height: 100%;

  @media ${breakpoints.m} {
    margin-left: 0;
    padding: 0 16px;
    width: 100vw;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
`;
