import { AuthProvider } from './Auth';
import { GroupsProvider } from './Groups';
import { LicensesProvider } from './License';
import { PasswordsProvider } from './Passwords';
import { PermissionsProvider } from './Permissions';
import { ProfileProvider } from './Profile';
import { SubscriptionProvider } from './Subscription';
import { TeamUsersProvider } from './Teams';
import { UsersProvider } from './Users';

export const AppUsersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <UsersProvider>
      <GroupsProvider>
        <PermissionsProvider>
          <TeamUsersProvider>
            <AuthProvider>
              <LicensesProvider>
                <PasswordsProvider>
                  <ProfileProvider>
                    <SubscriptionProvider>{children}</SubscriptionProvider>
                  </ProfileProvider>
                </PasswordsProvider>
              </LicensesProvider>
            </AuthProvider>
          </TeamUsersProvider>
        </PermissionsProvider>
      </GroupsProvider>
    </UsersProvider>
  );
};
