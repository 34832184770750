import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import { IConfiguration } from '@configurations/types/Configurations/configurations';
import { IConfigurationsContext } from '@configurations/types/Configurations/context';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

const ConfigurationsContext = createContext<IConfigurationsContext>({} as IConfigurationsContext);
ConfigurationsContext.displayName = 'Configurations';

const ConfigurationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'configurations']);

  const { showLoader, hideLoader } = useLoader();

  const [configurations, setConfigurations] = useState<IConfiguration>({} as IConfiguration);

  const transformConfiguration = (configuration: IConfiguration): IConfiguration => {
    const config: IConfiguration = {} as IConfiguration;

    Object.entries(configuration).forEach(item => {
      const [key, value] = item;
      if (key.toLowerCase().includes('afternoon') || key.toLowerCase().includes('morning')) {
        Object.assign(config, { [key]: value.replace(':', '') });
        return;
      }
      Object.assign(config, { [key]: value });
    });

    return config;
  };

  const getConfigurations = useCallback(async () => {
    try {
      showLoader();

      const response = await api.configuration().getConfigurations();

      setConfigurations(transformConfiguration(response.data));
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const updateConfigurations = useCallback(
    async (data: Partial<IConfiguration>) => {
      try {
        showLoader();

        const response = await api.configuration().updateConfigurations(data);

        setConfigurations(transformConfiguration(response.data));

        const item = t('configurations', { ns: 'configurations' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateImageToReports = useCallback(
    async (file: File) => {
      try {
        showLoader();

        const response = await api.configuration().updateImageToReports(file);

        setConfigurations(transformConfiguration(response.data));

        const item = t('configurations', { ns: 'configurations' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IConfigurationsContext>(
    () => ({ configurations, getConfigurations, updateConfigurations, updateImageToReports }),
    [configurations, getConfigurations, updateConfigurations, updateImageToReports],
  );

  return <ConfigurationsContext.Provider value={contextValue}>{children}</ConfigurationsContext.Provider>;
};

export { ConfigurationsProvider, ConfigurationsContext };
