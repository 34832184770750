import appointments from '@appointments/routes/Private';

import associations from '@associations/routes/Private';

import balances from '@balances/routes/Private';

import competitions from '@competitions/routes/Private';

import configurations from '@configurations/routes/Private';

import dashboard from '@dashboard/routes/Private';

import foods from '@foods/routes/Private';

import horses from '@horses/routes/Private';

import notifications from '@notifications/routes/Private';

import riders from '@riders/routes/Private';

import teachers from '@teachers/routes/Private';

import users from '@users/routes/Private';

import { IRoute } from '..';

export default [
  ...appointments,
  ...associations,
  ...balances,
  ...competitions,
  ...configurations,
  ...dashboard,
  ...foods,
  ...horses,
  ...notifications,
  ...riders,
  ...teachers,
  ...users,
] as IRoute[];
