import { lazily } from 'react-lazily';

import { IRoute } from '@routes/index';

const { NotificationsList } = lazily(() => import('@notifications/pages/NotificationsList'));

export default [
  {
    component: NotificationsList,
    path: 'notifications',
    title: 'private.notifications',
    canAccess: true,
  },
] as IRoute[];
