import { ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router-dom';

import Header from '@components/Header';
import HeadTitle from '@components/HeadTitle';
import { Intercom } from '@components/Intercom';

import { getAllowedRoutes } from '@utils/getAllowedRoutes';

import { Sidebar } from '@menu/components/Sidebar';
import { SidebarMobile } from '@menu/components/SidebarMobile';

import { Authenticate } from '@users/components/Authenticate';

import PrivateRoutesConfig from '../config/PrivateRoutes';
import { Container, Content, WrapperContent } from './styles';

interface IGetPrivateRoutesRequest {
  permissions: string[];
  signOut: () => Promise<void>;
}

export interface IGetPrivateRoutes {
  canAccess: boolean;
  path: string;
  element: ReactNode;
}

export const getPrivateRoutes = async (data: IGetPrivateRoutesRequest): Promise<IGetPrivateRoutes[]> => {
  const { permissions, signOut } = data;

  const routes = await getAllowedRoutes({ routes: PrivateRoutesConfig, permissions, signOut });

  return routes.map(route => {
    const { path, component: Component, title, children, canAccess, ...rest } = route;
    return {
      canAccess,
      path,
      element: (
        <>
          <Container {...rest}>
            {isMobile ? <SidebarMobile /> : <Sidebar />}
            <HeadTitle titlePage={title} />
            <WrapperContent>
              <Header />
              <Intercom />
              <Content>
                <Authenticate>
                  <Component>{children}</Component>
                </Authenticate>
              </Content>
            </WrapperContent>
          </Container>
          <Outlet />
        </>
      ),
    };
  });
};
