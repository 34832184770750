import { memo, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { lazily } from 'react-lazily';
import { Route, Routes, useNavigate, matchRoutes } from 'react-router-dom';
import { useEffectOnce, useLocation } from 'react-use';

import { useAuth } from '@users/hooks/useAuth';
import { useUsers } from '@users/hooks/useUsers';

import { update as updateIntercom } from '../intercom';
import { PrivateLayout } from './layouts/PrivateLayout';
import { PublicLayout } from './layouts/PublicLayout';
import { getPrivateRoutes, IGetPrivateRoutes } from './PrivateRoutes';
import { getPublicRoutes, IGetPublicRoutes } from './PublicRoutes';

interface IRouteBase {
  component: React.LazyExoticComponent<React.FC<React.PropsWithChildren>>;
  path: string;
  title: string;
  canAccess: boolean;
  permission?: string | string[];
  isPublicButAccessLogged?: boolean;
}

export interface IRoute extends IRouteBase {
  children?: React.PropsWithChildren<React.ReactNode & IRouteBase[]>;
}

const { Maintenance } = lazily(() => import('@maintenance/pages/Maintenance'));
const { NotFound } = lazily(() => import('@notFound/pages/NotFound'));

const EquitesRoutes: React.FC<React.PropsWithChildren> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { permissions } = useUsers();
  const { isAuthenticated, signOut } = useAuth();

  const [privateRoutes, setPrivateRoutes] = useState<IGetPrivateRoutes[]>([]);
  const [publicRoutes, setPublicRoutes] = useState<IGetPublicRoutes[]>([]);

  const getPrivateRoutesToRender = useCallback(async () => {
    const routes = await getPrivateRoutes({ permissions, signOut });
    setPrivateRoutes(routes);
  }, [permissions, signOut]);

  const getPublicRoutesToRender = useCallback(async () => {
    const routes = await getPublicRoutes({ signOut });
    setPublicRoutes(routes);
  }, [signOut]);

  useLayoutEffect(() => {
    updateIntercom();
  }, [location.pathname]);

  useEffect(() => {
    getPrivateRoutesToRender();
    getPublicRoutesToRender();
  }, [getPrivateRoutesToRender, getPublicRoutesToRender]);

  useEffectOnce(() => {
    if (isAuthenticated && location.pathname === '/') navigate('/dashboard');
  });

  useEffect(() => {
    if (location.pathname && location.pathname !== '/' && privateRoutes.length && publicRoutes.length) {
      const routeExists = matchRoutes([...privateRoutes, ...publicRoutes], location.pathname);
      if (!routeExists) navigate('/404');
    }
  }, [location.pathname, navigate, privateRoutes, publicRoutes]);

  return (
    <Routes>
      <Route path="/404" element={<NotFound />} />

      <Route path="/maintenance" element={<Maintenance />} />

      <Route path="/" element={<PrivateLayout />}>
        {privateRoutes.map(({ element, path }) => (
          <Route key={path} path={path} element={element} />
        ))}
      </Route>

      <Route path="/" element={<PublicLayout />}>
        {publicRoutes
          .filter(route => !route.isPublicButAccessLogged)
          .map(({ element, path }) => (
            <Route key={path} path={path} element={element} />
          ))}
      </Route>

      <Route path="/">
        {publicRoutes
          .filter(route => route.isPublicButAccessLogged)
          .map(({ element, path }) => (
            <Route key={path} path={path} element={element} />
          ))}
      </Route>
    </Routes>
  );
};

export default memo(EquitesRoutes);
