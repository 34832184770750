import { AxiosInstance, AxiosResponse } from 'axios';

import { IPaymentMethod } from '@paymentMethods/types/PaymentMethods/paymentMethods';
import { ICreatePaymentMethodRequest } from '@paymentMethods/types/PaymentMethods/requests';

export class PaymentMethods {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAll(): Promise<AxiosResponse<IPaymentMethod[]>> {
    return this.instance.get(`/payment-methods`);
  }

  create(data: ICreatePaymentMethodRequest): Promise<AxiosResponse<IPaymentMethod>> {
    return this.instance.post(`/payment-methods`, data);
  }

  delete(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/payment-methods/${id}`);
  }

  updatePaymentMethodToDefault(paymentMethodId: string): Promise<AxiosResponse<void>> {
    return this.instance.patch(`/payment-methods/${paymentMethodId}/turn-default`);
  }
}

export const paymentMethods = (instance: AxiosInstance): PaymentMethods => new PaymentMethods(instance);
