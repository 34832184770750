import { useCallback, useMemo, memo, useEffect, SyntheticEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCog, FaBars, FaArrowLeft } from 'react-icons/fa';
import { useLocation, LinkProps, useNavigate } from 'react-router-dom';

import { isAfter, isBefore, parseISO, startOfDay } from 'date-fns';

import profileImage from '@assets/img/no-user.webp';

import Roles from '@config/Roles';

import Button from '@components/Button';
import Link from '@components/Link';

import { useMenu } from '@menu/hooks/useMenu';

import { useTheme } from '@theme/hooks/useTheme';

import { NeedPermission } from '@users/components/NeedPermission';
import { useAuth } from '@users/hooks/useAuth';
import { useUsers } from '@users/hooks/useUsers';

import { Container, Left, ButtonBack, Right } from './styles';

interface IHeaderProps {}

export interface LocationProps extends LinkProps {
  previousPathStructure: string;
  previousPath: string;
}

const Header: React.FC<React.PropsWithChildren<IHeaderProps>> = () => {
  const profileImageRef = useRef<HTMLImageElement>(null);

  const location = useLocation();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'plans', 'routes']);

  const { theme } = useTheme();
  const { showSidebarMobile } = useMenu();
  const { user, subscription, permissions } = useUsers();
  const { isAuthenticated, signOut } = useAuth();

  const shouldShowSubscriptionButton = (): boolean => {
    const subscriptionIsNotCustom = !subscription?.isCustom;
    const userIsCompany = user?.types.map(({ type }) => type).includes('COMPANY') ?? true;
    const hasPermission = permissions.includes(Roles.GET_SUBSCRIPTION);

    return subscriptionIsNotCustom && hasPermission && userIsCompany;
  };

  const handleImageError = (eventError: SyntheticEvent<HTMLImageElement, Event>): void => {
    if (eventError.isTrusted) {
      const image = profileImageRef.current;
      if (image) image.src = profileImage;
    }
  };

  const openSidebar = useCallback(() => {
    showSidebarMobile();
  }, [showSidebarMobile]);

  const Subscription = useCallback(() => {
    const isExpired = subscription?.expiresAt
      ? isBefore(startOfDay(parseISO(subscription.expiresAt)), startOfDay(new Date()))
      : false;
    if (isExpired)
      return (
        <Link to="/plans">
          <Button red outline>
            {t('update_your_payment_data', { ns: 'plans' })}
          </Button>
        </Link>
      );

    const subscriptionIsNotExpired =
      subscription?.expiresAt && isAfter(startOfDay(parseISO(subscription.expiresAt)), startOfDay(new Date()));
    const subscriptionHasPreviousLicense = subscription?.previousLicense;
    const previousLicenseIsPaid = subscriptionHasPreviousLicense && !subscription.previousLicense?.isFree;
    const shouldActivate = subscriptionIsNotExpired && previousLicenseIsPaid;

    return subscription?.isFree ? (
      <Link to="/plans">
        <Button className={shouldActivate ? 'activate_now' : 'subscribe_now'}>
          {t(shouldActivate ? 'activate_subscription' : 'subscribe_now', { ns: 'plans' })}
        </Button>
      </Link>
    ) : (
      <Link to="/plans" className="link__my_subscription">
        <span>{`${t('my', { ns: 'common', context: 'male' })} ${subscription?.name}`}</span>
      </Link>
    );
  }, [subscription, t]);

  const shouldShowPathBack = useMemo(() => {
    return !location.pathname.includes('/dashboard');
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && (!subscription?.name || !user?.types)) signOut();
  }, [isAuthenticated, subscription, signOut, user?.types]);

  return (
    <Container>
      <Left>
        <FaBars onClick={openSidebar} size={24} color={theme.colors.primary} className="sidebar_mobile__bars" />
        {shouldShowPathBack && (
          <ButtonBack onClick={() => navigate(-1)}>
            <FaArrowLeft size={24} color={theme.colors.primary} />
            <span>{t('go_back', { ns: 'common' })}</span>
          </ButtonBack>
        )}
      </Left>
      <Right>
        <NeedPermission permission={Roles.GET_SUBSCRIPTION}>
          {shouldShowSubscriptionButton() && (
            <div className="subscription__container">
              <Subscription />
            </div>
          )}
        </NeedPermission>
        <NeedPermission permission={Roles.GET_CONFIGURATIONS}>
          <Link to="/configurations">
            <FaCog size={24} color={theme.colors.primary} />
          </Link>
        </NeedPermission>
        {/* <NotificationsContainer>
          <Button ghost>
            <FaBell size={24} color={theme.colors.primary} onClick={() => showSummaryNotifications()} />
          </Button>
          <BadgeNotifications />
          <SummaryNotifications />
        </NotificationsContainer> */}
        <NeedPermission permission={Roles.GET_PROFILE}>
          <Link to="/profile">
            <img
              ref={profileImageRef}
              onError={handleImageError}
              src={user?.avatarUrl || profileImage}
              alt={user?.name || 'Equites'}
            />
          </Link>
        </NeedPermission>
      </Right>
    </Container>
  );
};

export default memo(Header);
