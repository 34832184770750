import { AxiosInstance, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import EquitesPaginate from '@equites/api/paginate';

import { openPdf } from '@utils/OpenPDF';

import {
  IAffiliatedItem,
  IAffiliated,
  IAffiliation,
  ISeal,
  IMicrochip,
} from '@associations/types/Affiliates/affiliates';
import {
  IGetAllAffiliatesRequest,
  IGetAffiliatesByTypeRequest,
  ICreateAffiliatedRequest,
  IUpdateAffiliatedRequest,
  IHandleAffiliationRequest,
  IHandleMicrochipRequest,
  ICreatePersonToAffiliated,
  IUpdatePersonToAffiliated,
  ICreateAffiliatedAddressRequest,
  IUpdateAffiliatedAddressRequest,
} from '@associations/types/Affiliates/requests';
import { IPublicAffiliated } from '@associations/types/PublicAffiliates/public';
import {
  IExportPublicAffiliatesParams,
  IGetPublicAffiliatesParams,
} from '@associations/types/PublicAffiliates/requests';
import {
  IDeletePassportDocument,
  IHandleSealRequest,
  IUploadPassportDocument,
} from '@associations/types/Seals/requests';

type IGetAllAffiliatesResponse = Promise<AxiosResponse<EquitesPaginate<IAffiliatedItem>>>;

export class Affiliates {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Affiliates

  getAll(params: IGetAllAffiliatesRequest): IGetAllAffiliatesResponse {
    return this.instance.get(`/affiliates`, { params });
  }

  getById(id: string): Promise<AxiosResponse<IAffiliated>> {
    return this.instance.get(`/affiliates/${id}`);
  }

  getByType(params: IGetAffiliatesByTypeRequest): Promise<AxiosResponse<IAffiliated[]>> {
    return this.instance.get(`/affiliates/types/${params.type}`);
  }

  createAffiliated(data: ICreateAffiliatedRequest): Promise<AxiosResponse<IAffiliated>> {
    return this.instance.post(`/affiliates`, data);
  }

  updateAffiliated(data: IUpdateAffiliatedRequest): Promise<AxiosResponse<IAffiliated>> {
    const { id, ...body } = data;
    return this.instance.patch(`/affiliates/${id}`, body);
  }

  deleteAffiliated(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/affiliates/${id}`);
  }

  // Affiliation

  getAffiliation(id: string): Promise<AxiosResponse<IAffiliation>> {
    return this.instance.get(`/affiliates/${id}/affiliations`);
  }

  handleAffiliation(data: IHandleAffiliationRequest): Promise<AxiosResponse<IAffiliation>> {
    const { affiliatedId, ...body } = data;
    return this.instance.post(`/affiliates/${affiliatedId}/affiliations`, body);
  }

  // Seal

  getSeal(id: string): Promise<AxiosResponse<ISeal>> {
    return this.instance.get(`/affiliates/${id}/seals`);
  }

  handleSeal(data: IHandleSealRequest): Promise<AxiosResponse<ISeal>> {
    const { affiliatedId, ...body } = data;
    return this.instance.post(`/affiliates/${affiliatedId}/seals`, body);
  }

  uploadPassportDocument(data: IUploadPassportDocument): Promise<AxiosResponse<ISeal>> {
    const { affiliatedId, sealId, files } = data;

    const body = new FormData();
    files.forEach(file => {
      body.append('documents-passport', file);
    });

    return this.instance.post(`/affiliates/${affiliatedId}/seals/${sealId}/upload/document-passport`, body);
  }

  deletePassportDocument(request: IDeletePassportDocument): Promise<AxiosResponse<void>> {
    const { affiliatedId, sealId, passportDocumentIds } = request;
    const params = { passportDocumentIds };
    return this.instance.delete(`/affiliates/${affiliatedId}/seals/${sealId}/document-passport`, { params });
  }

  // Microchip

  getMicrochip(id: string): Promise<AxiosResponse<IMicrochip>> {
    return this.instance.get(`/affiliates/${id}/microchips`);
  }

  handleMicrochip(data: IHandleMicrochipRequest): Promise<AxiosResponse<IMicrochip>> {
    const { affiliatedId, ...body } = data;
    return this.instance.post(`/affiliates/${affiliatedId}/microchips`, body);
  }

  // Public affiliates

  getPublicAffiliates(data: IGetPublicAffiliatesParams): Promise<AxiosResponse<EquitesPaginate<IPublicAffiliated>>> {
    const { associationId, ...params } = data;
    return this.instance.get(`/public/associations/${associationId}/affiliates`, { params });
  }

  // Public affiliates reports

  async getPublicAffiliatesCsv(
    request: IExportPublicAffiliatesParams,
  ): Promise<AxiosResponse<EquitesPaginate<IPublicAffiliated>>> {
    const { associationId, ...params } = request;

    const url = `/public/associations/${associationId}/affiliates/report/csv/export`;
    const { data, ...requestData } = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(data, 'affiliates.csv');

    return { data, ...requestData };
  }

  async getPublicAffiliatesPdf(request: IExportPublicAffiliatesParams): Promise<AxiosResponse> {
    const { associationId, ...params } = request;

    const url = `/public/associations/${associationId}/affiliates/report/pdf/export`;
    const { data, ...requestData } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...requestData };
  }

  // Persons

  createPerson(data: ICreatePersonToAffiliated): Promise<AxiosResponse<IAffiliated>> {
    const { affiliatedId, ...body } = data;
    return this.instance.post(`/affiliates/${affiliatedId}/persons`, body);
  }

  updatePerson(data: IUpdatePersonToAffiliated): Promise<AxiosResponse<IAffiliated>> {
    const { affiliatedId, personId } = data;
    return this.instance.patch(`/affiliates/${affiliatedId}/persons/${personId}`);
  }

  // Address

  createAffiliatedAddress(data: ICreateAffiliatedAddressRequest): Promise<AxiosResponse<IAffiliated>> {
    const { affiliatedId, ...body } = data;
    return this.instance.post(`/affiliates/${affiliatedId}/address`, body);
  }

  updateAffiliatedAddress(data: IUpdateAffiliatedAddressRequest): Promise<AxiosResponse<IAffiliated>> {
    const { affiliatedId, id, ...body } = data;
    return this.instance.patch(`/affiliates/${affiliatedId}/address/${id}`, body);
  }
}

export const affiliates = (instance: AxiosInstance): Affiliates => new Affiliates(instance);
