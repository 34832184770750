import { useCallback, useMemo, useState } from 'react';
import { useNetworkState } from 'react-use';

import { getRemoteConfig, getAll, fetchAndActivate } from '@firebase/remote-config';
import { createContext } from 'use-context-selector';

import Firebase from '@config/Firebase';

import { IFlagsContext } from '@flags/types/Flags/context';
import { IFlagsDefaults } from '@flags/types/Flags/flags';

const remoteConfig = getRemoteConfig(Firebase);
remoteConfig.defaultConfig = { underMaintenance: false };
remoteConfig.settings.minimumFetchIntervalMillis = 300000;

const FlagsContext = createContext<IFlagsContext>({} as IFlagsContext);

FlagsContext.displayName = 'Flags';

const FlagsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { online } = useNetworkState();

  const [flags, setFlags] = useState<IFlagsDefaults>({} as IFlagsDefaults);

  const getFlags = useCallback(async () => {
    if (online) {
      await fetchAndActivate(remoteConfig);

      const firebaseFlags = getAll(remoteConfig);

      const flagsArray = Object.entries(firebaseFlags);

      const newFlags = {} as IFlagsDefaults;

      flagsArray.forEach(flag => {
        const label = flag[0];
        const value = flag[1].asBoolean();
        Object.assign(newFlags, { [label]: value });
      });

      setFlags(newFlags);
    }
  }, [online]);

  const contextValue = useMemo<IFlagsContext>(() => ({ flags, getFlags }), [flags, getFlags]);

  return <FlagsContext.Provider value={contextValue}>{children}</FlagsContext.Provider>;
};

export { FlagsContext, FlagsProvider };
