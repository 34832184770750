import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';
import toCurrency from '@utils/toCurrency';

import { useLoader } from '@loader/hooks/useLoader';

import { IRiderPaymentsContext } from '@riders/types/context';
import {
  IGetRiderPaymentsParams,
  ICreateRiderPaymentRequest,
  ICreateRiderPaymentLessonPackageRequest,
  IPaidRiderPaymentRequest,
  IDeleteRiderPaymentRequest,
} from '@riders/types/requests';
import { IRiderPayments, RiderPaymentStatus, IRiderPayment } from '@riders/types/riders';

const RiderPaymentsContext = createContext<IRiderPaymentsContext>({} as IRiderPaymentsContext);

RiderPaymentsContext.displayName = 'RiderPayments';

const RiderPaymentsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'balances']);

  const { showLoader, hideLoader } = useLoader();

  const [riderPayments, setRiderPayments] = useState<IRiderPayments>({ limit: 10, page: 1 } as IRiderPayments);

  const getPaymentStatus = useCallback(
    (status: RiderPaymentStatus) => {
      switch (status) {
        case 'PENDING':
          return t('pending', { ns: 'balances' });
        case 'LATE':
          return t('late', { ns: 'balances' });
        case 'PAID':
          return t('paid_out', { ns: 'balances' });
        default:
          return '';
      }
    },
    [t],
  );

  const formatItem = useCallback(
    (item: IRiderPayment) => {
      const priceFormatted = toCurrency(item.price);
      const dueFormatted = item.due ? format(parseISO(item.due), 'dd/MM/yyyy') : undefined;
      const paidFormatted = item.paid ? format(parseISO(item.paid), 'dd/MM/yyyy') : undefined;
      const statusFormatted = getPaymentStatus(item.status);
      return { ...item, priceFormatted, dueFormatted, paidFormatted, statusFormatted };
    },
    [getPaymentStatus],
  );

  const getRiderPayments = useCallback(
    async (params: IGetRiderPaymentsParams) => {
      try {
        showLoader();

        const response = await api.rider().getPaymentsByRider(params);

        const items = response.data.items.map(item => formatItem(item));

        setRiderPayments({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [formatItem, hideLoader, showLoader],
  );

  const createRiderPayment = useCallback(
    async (data: ICreateRiderPaymentRequest) => {
      try {
        showLoader();

        await api.rider().createRiderPayment(data);

        const item = t('charge', { ns: 'balances' });
        toast(t('crud.added_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const createRiderPaymentLessonPackage = useCallback(
    async (data: ICreateRiderPaymentLessonPackageRequest) => {
      try {
        showLoader();

        await api.rider().createRiderPaymentLessonPackage(data);

        const item = t('payment_monthly', { ns: 'balances' });
        toast(t('crud.added_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const paidRiderPayment = useCallback(
    async (data: IPaidRiderPaymentRequest) => {
      try {
        showLoader();

        const response = await api.rider().updateRiderPaymentToPaid(data);

        setRiderPayments(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === data.paymentId);
          items[index] = formatItem(response.data);

          return { ...current, items };
        });

        const item = t('charge', { ns: 'balances' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [formatItem, hideLoader, showLoader, t],
  );

  const deleteRiderPayment = useCallback(
    async (data: IDeleteRiderPaymentRequest) => {
      try {
        showLoader();

        await api.rider().deleteRiderPayment(data);

        setRiderPayments(current => {
          return { ...current, items: current.items.filter(item => item.id !== data.paymentId) };
        });

        const item = t('charge', { ns: 'balances' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IRiderPaymentsContext>(
    () => ({
      riderPayments,
      createRiderPayment,
      createRiderPaymentLessonPackage,
      deleteRiderPayment,
      getRiderPayments,
      paidRiderPayment,
    }),
    [
      createRiderPayment,
      createRiderPaymentLessonPackage,
      deleteRiderPayment,
      getRiderPayments,
      paidRiderPayment,
      riderPayments,
    ],
  );

  return <RiderPaymentsContext.Provider value={contextValue}>{children}</RiderPaymentsContext.Provider>;
};

export { RiderPaymentsProvider, RiderPaymentsContext };
