import { memo, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface IProps {
  titlePage: string;
}

const HeadTitle: React.FC<React.PropsWithChildren<IProps>> = ({ titlePage }) => {
  const { t } = useTranslation('routes');

  const getTitle = useCallback(() => {
    const title = `${t(titlePage)} | Equites`;
    return title;
  }, [t, titlePage]);

  document.title = getTitle();

  return <Helmet>{/* <title>{getTitle()}</title> */}</Helmet>;
};

export default memo(HeadTitle);
