import { AxiosInstance, AxiosResponse } from 'axios';

import { IDashboard, IDashboardAssociation } from '@dashboard/types/dashboard';
import { IGetDashboardAssociationRequest } from '@dashboard/types/requests';

export class Dashboard {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getDashboard(): Promise<AxiosResponse<IDashboard>> {
    return this.instance.get(`/dashboard`);
  }

  getDashboardAssociation(params: IGetDashboardAssociationRequest): Promise<AxiosResponse<IDashboardAssociation>> {
    return this.instance.get(`/dashboard/association`, { params });
  }
}

export const dashboard = (instance: AxiosInstance): Dashboard => new Dashboard(instance);
