import { useContextSelector } from 'use-context-selector';

import { AffiliatesMicrochipContext } from '@associations/contexts/AffiliatesMicrochip';
import { IAffiliatesMicrochipContext } from '@associations/types/Microchips/context';

export function useAffiliatesMicrochip(): IAffiliatesMicrochipContext {
  const microchip = useContextSelector(AffiliatesMicrochipContext, cs => cs.microchip);

  const getMicrochip = useContextSelector(AffiliatesMicrochipContext, cs => cs.getMicrochip);
  const handleMicrochip = useContextSelector(AffiliatesMicrochipContext, cs => cs.handleMicrochip);
  const handleSetMicrochip = useContextSelector(AffiliatesMicrochipContext, cs => cs.handleSetMicrochip);

  return {
    microchip,
    getMicrochip,
    handleMicrochip,
    handleSetMicrochip,
  };
}
