import { useContextSelector } from 'use-context-selector';

import { MenuContext } from '@menu/contexts/Menu';
import { IMenuContext } from '@menu/types/Menu/context';

export function useMenu(): IMenuContext {
  const shouldShowSidebarMobile = useContextSelector(MenuContext, cs => cs.shouldShowSidebarMobile);

  const hideSidebarMobile = useContextSelector(MenuContext, cs => cs.hideSidebarMobile);
  const showSidebarMobile = useContextSelector(MenuContext, cs => cs.showSidebarMobile);

  return { shouldShowSidebarMobile, hideSidebarMobile, showSidebarMobile };
}
