import { AxiosInstance, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import { openPdf } from '@utils/OpenPDF';

import { IAssociationCompany } from '@associations/types/Association/association';
import {
  ICreateAssociationCompanyRequest,
  IUpdateAssociationCompanyRequest,
  IReportListAffiliatesParams,
} from '@associations/types/Association/requests';
import { IPublicAssociation, IPublicAssociationCompany } from '@associations/types/PublicAssociation/associations';

export class Association {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getCompanyById(id: string): Promise<AxiosResponse<IAssociationCompany>> {
    return this.instance.get(`/associations/companies/${id}`);
  }

  getAllCompanies(): Promise<AxiosResponse<IAssociationCompany[]>> {
    return this.instance.get(`/associations/companies`);
  }

  createCompany(data: ICreateAssociationCompanyRequest): Promise<AxiosResponse<IAssociationCompany>> {
    return this.instance.post(`/associations/companies`, data);
  }

  updateCompany(data: IUpdateAssociationCompanyRequest): Promise<AxiosResponse<IAssociationCompany>> {
    const { id, ...body } = data;
    return this.instance.patch(`/associations/companies/${id}`, body);
  }

  deleteCompany(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/associations/companies/${id}`);
  }

  updateCompanyAvatar(id: string, data: FormData): Promise<AxiosResponse<IAssociationCompany>> {
    return this.instance.patch(`/associations/companies/${id}/upload/avatar`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // Reports

  async getReportAllCompaniesPdf(): Promise<AxiosResponse> {
    const { data, ...requestData } = await this.instance.get(`/associations/companies/all/report/pdf/export`, {
      responseType: 'blob',
    });

    openPdf(data);

    return { data, ...requestData };
  }

  async getReportListAffiliatesPdf(params: IReportListAffiliatesParams): Promise<AxiosResponse> {
    const url = '/associations/affiliates/report/pdf/export';
    const { data, ...requestData } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...requestData };
  }

  async getReportListAffiliatesCsv(params: IReportListAffiliatesParams): Promise<AxiosResponse> {
    const url = '/associations/affiliates/report/csv/export';
    const { data, ...requestData } = await this.instance.get(url, { params, responseType: 'blob' });

    fileDownload(data, 'affiliates.csv');

    return { data, ...requestData };
  }

  // Public associations

  getPublicAssociationBySlug(slug: string): Promise<AxiosResponse<IPublicAssociation>> {
    return this.instance.get(`/public/associations/${slug}`, { params: { shouldAssociation: true } });
  }

  getPublicCompanies(associationId: string): Promise<AxiosResponse<IPublicAssociationCompany[]>> {
    return this.instance.get(`/public/associations/${associationId}/companies`);
  }
}

export const association = (instance: AxiosInstance): Association => new Association(instance);
