import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import formatString from 'format-string-by-pattern';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { ITeamUserContext } from '@users/types/Team/context';
import { IGetAllTeamUsersRequest, ICreateTeamUserRequest, IUpdateTeamUserRequest } from '@users/types/Team/requests';
import { ITeamUser } from '@users/types/Team/teams';

type TeamUsers = EquitesPaginate<ITeamUser>;

const TeamUserContext = createContext<ITeamUserContext>({} as ITeamUserContext);

TeamUserContext.displayName = 'TeamUsers';

const TeamUsersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'common', 'myTeam']);

  const { showLoader, hideLoader } = useLoader();

  const [teamUsers, setTeamUsers] = useState<TeamUsers>({} as TeamUsers);

  const getTeamUsers = useCallback(
    async (params: IGetAllTeamUsersRequest) => {
      try {
        showLoader();

        const response = await api.teamUser().getAll(params);

        const items = response.data.items.map(user => {
          let phonePattern = '(99) 99999-9999';

          if (user.userPhone?.length === 10) phonePattern = '(99) 9999-9999';

          return {
            ...user,
            userPhoneFormatted: user.userPhone
              ? formatString(phonePattern, user.userPhone)
              : t('without_phone', { ns: 'common' }),
          };
        });

        setTeamUsers({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const createTeamUser = useCallback(
    async (data: ICreateTeamUserRequest) => {
      try {
        showLoader();

        await api.teamUser().create(data);

        await getTeamUsers({ limit: 10, page: 1 });

        const item = t('user', { ns: 'myTeam' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getTeamUsers, hideLoader, showLoader, t],
  );

  const updateTeamUser = useCallback(
    async (data: IUpdateTeamUserRequest) => {
      try {
        showLoader();

        await api.teamUser().update(data);

        await getTeamUsers({ limit: 10, page: 1 });

        const item = t('user', { ns: 'myTeam' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getTeamUsers, hideLoader, showLoader, t],
  );

  const deleteTeamUser = useCallback(
    async (userId: string) => {
      try {
        showLoader();

        await api.teamUser().delete(userId);

        await getTeamUsers({ limit: 10, page: 1 });

        const item = t('user', { ns: 'myTeam' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getTeamUsers, hideLoader, showLoader, t],
  );

  const blockTeamUser = useCallback(
    async (userId: string, blocked: boolean) => {
      try {
        showLoader();

        await api.teamUser().handleBlock(userId, blocked);

        getTeamUsers({ limit: 10, page: 1 });

        const item = t('user', { ns: 'myTeam' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getTeamUsers, hideLoader, showLoader, t],
  );

  const contextValue = useMemo<ITeamUserContext>(
    () => ({ teamUsers, getTeamUsers, createTeamUser, updateTeamUser, deleteTeamUser, blockTeamUser }),
    [blockTeamUser, createTeamUser, deleteTeamUser, getTeamUsers, teamUsers, updateTeamUser],
  );

  return <TeamUserContext.Provider value={contextValue}>{children}</TeamUserContext.Provider>;
};

export { TeamUsersProvider, TeamUserContext };
