import { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@users/hooks/useAuth';

const PublicLayoutComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Navigate to="/dashboard" />;

  return (
    <>
      {children}
      <Outlet />
    </>
  );
};

export const PublicLayout = memo(PublicLayoutComponent);
