import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { Plans } = lazily(() => import('@users/pages/Plans'));
const { Profile } = lazily(() => import('@users/pages/Profile'));
const { TeamUsersList } = lazily(() => import('@users/pages/TeamUsersList'));

export default [
  {
    component: TeamUsersList,
    path: 'my-team',
    title: 'private.my_team',
    permission: Roles.GET_TEAM_USERS,
    canAccess: true,
  },
  {
    component: Plans,
    path: 'plans',
    title: 'private.plans',
    permission: Roles.GET_SUBSCRIPTION,
    canAccess: true,
  },
  {
    component: Profile,
    path: 'profile',
    title: 'private.profile',
    permission: Roles.GET_PROFILE,
    canAccess: true,
  },
] as IRoute[];
