import { AxiosInstance, AxiosResponse } from 'axios';

import { IBalanceCategory } from '@balances/types/Categories/categories';
import { ICreateBalanceCategoryRequest, IUpdateBalanceCategoryRequest } from '@balances/types/Categories/requests';

export class BalanceCategory {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getAll(): Promise<AxiosResponse<IBalanceCategory[]>> {
    return this.instance.get(`/balances/categories`);
  }

  create(data: ICreateBalanceCategoryRequest): Promise<AxiosResponse<IBalanceCategory>> {
    return this.instance.post(`/balances/categories`, data);
  }

  update(data: IUpdateBalanceCategoryRequest): Promise<AxiosResponse<IBalanceCategory>> {
    const { id, ...body } = data;
    return this.instance.patch(`/balances/categories/${id}`, body);
  }
}

export const balanceCategory = (instance: AxiosInstance): BalanceCategory => new BalanceCategory(instance);
