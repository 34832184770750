import { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

type IButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  ghost?: boolean;
  red?: boolean;
  outline?: boolean;
  fontWeight?: 300 | 400 | 700;
  loading?: boolean;
};

const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({
  children,
  type = 'button',
  ghost = false,
  red = false,
  outline = false,
  disabled = false,
  fontWeight = 700,
  loading = false,
  ...rest
}) => {
  const { t } = useTranslation('common');

  return (
    <Container
      type={type}
      $ghost={ghost}
      $red={red}
      $outline={outline}
      $isDisabled={loading || disabled}
      $fontWeight={fontWeight}
      {...rest}
    >
      {loading ? `${t('loading')}...` : children}
    </Container>
  );
};

export default Button;
