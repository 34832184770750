import { AxiosInstance, AxiosResponse } from 'axios';

import { IResetPasswordRequest } from '@users/types/Passwords/requests';

export class Password {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  recover(email: string): Promise<AxiosResponse> {
    return this.instance.post(`/passwords/forgot`, { email });
  }

  reset(data: IResetPasswordRequest): Promise<AxiosResponse> {
    return this.instance.post(`/passwords/reset`, data);
  }
}

export const password = (instance: AxiosInstance): Password => new Password(instance);
