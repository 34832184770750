import { rgba, shade } from 'polished';
import styled, { css } from 'styled-components';

interface IContainerProps {
  $ghost: boolean;
  $red: boolean;
  $outline: boolean;
  $isDisabled: boolean;
  $fontWeight: 300 | 400 | 700;
}

export const Container = styled.button<IContainerProps>`
  background-color: ${props => props.theme.colors.primary};
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 10px ${props => rgba(props.theme.colors.primary, 0.2)};
  transition: all 0.2s;

  color: ${props => props.theme.colors.white};
  font-size: 1.4rem;
  font-weight: ${props => props.$fontWeight};

  width: 200px;
  height: 40px;

  &:hover {
    background-color: ${props => shade(0.1, props.theme.colors.primary)};
    box-shadow: none;
  }

  ${props =>
    props.$red &&
    css`
      background-color: ${props.theme.colors.red};
      box-shadow: 0px 3px 6px ${rgba(props.theme.colors.red, 0.2)};
      color: ${props.theme.colors.background};

      &:hover {
        background-color: ${shade(0.2, props.theme.colors.red)};
        color: ${props.theme.colors.background};
      }
    `}

  ${props =>
    props.$ghost &&
    css`
      background-color: ${props.theme.colors.background};
      box-shadow: none;
      color: ${props.theme.colors.primary};

      &:hover {
        background-color: ${props.theme.colors.background};
        color: ${shade(0.2, props.theme.colors.primary)};
      }
    `}

    ${props =>
    props.$outline &&
    css`
      background-color: ${props.theme.colors.background};
      box-shadow: 0px 3px 6px ${rgba(props.theme.colors.black, 0.08)};
      color: ${props.theme.colors.primary};
      border: 1px solid ${props.theme.colors.primary};

      &:hover {
        background-color: ${props.theme.colors.background};
        color: ${shade(0.1, props.theme.colors.primary)};
        border-color: ${shade(0.1, props.theme.colors.primary)};
        box-shadow: none;
      }
    `}

    ${props =>
    props.$outline &&
    props.$red &&
    css`
      background-color: ${props.theme.colors.background};
      box-shadow: 0px 3px 6px ${rgba(props.theme.colors.black, 0.08)};
      color: ${props.theme.colors.secondary};
      border: 1px solid ${props.theme.colors.secondary};

      &:hover {
        background-color: ${props.theme.colors.background};
        color: ${shade(0.1, props.theme.colors.secondary)};
        border-color: ${shade(0.1, props.theme.colors.secondary)};
        box-shadow: none;
      }
    `}

  ${props =>
    props.$ghost &&
    props.$red &&
    css`
      background-color: ${props.theme.colors.background};
      box-shadow: none;
      color: ${props.theme.colors.secondary};

      &:hover {
        background-color: ${props.theme.colors.background};
        color: ${shade(0.2, props.theme.colors.secondary)};
      }
    `}

  ${props =>
    props.$isDisabled &&
    css`
      background-color: ${rgba(props.theme.colors.primary, 0.5)};
      box-shadow: none;
      cursor: initial;
      pointer-events: none;
      user-select: none;
    `}

  ${props =>
    props.$isDisabled &&
    props.$outline &&
    css`
      background-color: ${props.theme.colors.background};
      border-color: ${rgba(props.theme.colors.primary, 0.3)};
      box-shadow: none;
      color: ${rgba(props.theme.colors.primary, 0.3)};
      cursor: initial;
      pointer-events: none;
      user-select: none;
    `}

  ${props =>
    props.$isDisabled &&
    props.$ghost &&
    css`
      background-color: ${props.theme.colors.background};
      box-shadow: none;
      color: ${rgba(props.theme.colors.primary, 0.3)};
      cursor: initial;
      pointer-events: none;
      user-select: none;
    `}
`;
