import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IModalityCategoriesContext } from '@modalities/types/ModalityCategories/context';
import { IModalityCategory } from '@modalities/types/ModalityCategories/modalityCategories';
import {
  ICreateModalityCategoryRequest,
  IDeleteModalityCategoryRequest,
  IUpdateModalityCategoryRequest,
} from '@modalities/types/ModalityCategories/requests';

const ModalityCategoriesContext = createContext<IModalityCategoriesContext>({} as IModalityCategoriesContext);

ModalityCategoriesContext.displayName = 'ModalityCategories';

const ModalityCategoriesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();
  const { t } = useTranslation(['messages', 'modalities']);

  const [modalityCategories, setModalityCategories] = useState<IModalityCategory[]>([]);

  const getModalityCategories = useCallback(
    async (modalityId: string) => {
      try {
        showLoader();

        const response = await api.modalityCategoryAssociation().get(modalityId);
        setModalityCategories(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createModalityCategory = useCallback(
    async (data: ICreateModalityCategoryRequest) => {
      try {
        showLoader();

        const response = await api.modalityCategoryAssociation().create(data);
        setModalityCategories(current => [...current, response.data]);

        const item = t('category', { ns: 'modalities' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteModalityCategory = useCallback(
    async (data: IDeleteModalityCategoryRequest) => {
      try {
        showLoader();

        await api.modalityCategoryAssociation().delete(data);
        setModalityCategories(current => current.filter(mc => mc.id !== data.modalityCategoryId));

        const item = t('category', { ns: 'modalities' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateModalityCategory = useCallback(
    async (data: IUpdateModalityCategoryRequest) => {
      try {
        showLoader();

        const response = await api.modalityCategoryAssociation().update(data);
        setModalityCategories(current => {
          const index = current.findIndex(mc => mc.id === data.id);
          const categories = [...current];
          categories[index] = response.data;
          return categories;
        });

        const item = t('category', { ns: 'modalities' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IModalityCategoriesContext>(
    () => ({
      modalityCategories,
      createModalityCategory,
      deleteModalityCategory,
      getModalityCategories,
      updateModalityCategory,
    }),
    [modalityCategories, createModalityCategory, deleteModalityCategory, getModalityCategories, updateModalityCategory],
  );

  return <ModalityCategoriesContext.Provider value={contextValue}>{children}</ModalityCategoriesContext.Provider>;
};

export { ModalityCategoriesProvider, ModalityCategoriesContext };
