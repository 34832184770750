import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import axios, { AxiosError } from 'axios';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { HandleDate } from '@utils/HandleDate';
import { handleErrorApi } from '@utils/handleError';

import {
  IAppointmentHistory,
  IRiderAppointment,
  IAppointmentDetail,
  IAppointment,
  IAppointmentCalendar,
} from '@appointments/types/Appointments/appointments';
import { IAppointmentContext } from '@appointments/types/Appointments/context';
import {
  IExportPdfAppointmentsDayRequest,
  ICountAppointmentsByRiderRequest,
  IGetAppointmentsParamsRequest,
  ISearchAppointmentsByRiderParamsRequest,
  ICreateAppointmentRequest,
  IUpdateAppointmentRequest,
  IGetAppointmentsHistoryRequest,
  IGetAppointmentsCalendarRequest,
  IExportPdfAppointmentsHistoryRequest,
} from '@appointments/types/Appointments/requests';

import { useLoader } from '@loader/hooks/useLoader';

import { useUsers } from '@users/hooks/useUsers';

type IAppointmentsHistory = EquitesPaginate<IAppointmentHistory>;
type IRiderAppointments = EquitesPaginate<IRiderAppointment>;

const AppointmentsContext = createContext<IAppointmentContext>({} as IAppointmentContext);

AppointmentsContext.displayName = 'Appointments';

const AppointmentsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'common']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();
  const { company } = useUsers();

  const [appointmentDetail, setAppointmentDetail] = useState<IAppointmentDetail>({} as IAppointmentDetail);
  const [appointments, setAppointments] = useState<IAppointment[]>([]);
  const [appointmentsCalendar, setAppointmentsCalendar] = useState<IAppointmentCalendar[]>([]);
  const [countAppointmentsRemainingToRider, setCountAppointmentsRemainingToRider] = useState(0);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [riderAppointments, setRiderAppointments] = useState<IRiderAppointments>({} as IRiderAppointments);
  const [teacherId, setTeacherId] = useState<string>();
  const [hourOpened, setHourOpened] = useState<number | null>(null);
  const [history, setHistory] = useState<IAppointmentsHistory>({} as IAppointmentsHistory);

  const exportPdfAppointmentsDay = useCallback(
    async (params: IExportPdfAppointmentsDayRequest) => {
      try {
        showLoader();

        await api.appointment().exportPdf(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const exportPdfAppointmentsHistory = useCallback(
    async (params: IExportPdfAppointmentsHistoryRequest) => {
      try {
        showLoader();

        await api.appointment().exportHistoryPdf(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const countAppointmentsByRider = useCallback(
    async (params: ICountAppointmentsByRiderRequest) => {
      try {
        showLoader();

        const response = await api.appointment().countAppointmentsByRider(params);

        setCountAppointmentsRemainingToRider(response.data.appointments);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          const error = { ...err } as AxiosError<{ message: string }>;
          toast(error.response?.data.message || t('error.generic', { ns: 'messages' }), { type: 'warning' });
          setCountAppointmentsRemainingToRider(0);
        }
        handleErrorApi({ err, otherStatus: [404], onError: () => setCountAppointmentsRemainingToRider(0) });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const getAppointments = useCallback(
    async (data: IGetAppointmentsParamsRequest) => {
      try {
        showLoader();

        const response = await api.appointment().getByDay(data);

        setAppointments(response.data);
        setAppointmentDetail({} as IAppointmentDetail);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getAppointmentById = useCallback(
    async (appointmentId: string) => {
      try {
        showLoader();

        const response = await api.appointment().getById(appointmentId);

        setAppointmentDetail(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getAppointmentsByRider = useCallback(
    async (params: ISearchAppointmentsByRiderParamsRequest) => {
      try {
        showLoader();

        const response = await api.appointment().getAppointmentsByRider(params);

        setRiderAppointments(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createAppointment = useCallback(
    async (data: ICreateAppointmentRequest) => {
      try {
        showLoader();

        setDateSelected(new Date());
        setTeacherId(undefined);
        setHourOpened(null);

        await api.appointment().create(data);

        const today = new Date();
        await getAppointments({ day: today.getDate(), month: today.getMonth() + 1, year: today.getFullYear() });

        navigate(`/appointments`);

        toast(t('appointment.created_success', { ns: 'messages' }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getAppointments, hideLoader, navigate, showLoader, t],
  );

  const updateAppointment = useCallback(
    async (data: IUpdateAppointmentRequest) => {
      try {
        showLoader();

        await api.appointment().update(data);

        toast(t('appointment.updated_success', { ns: 'messages' }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteAppointment = useCallback(
    async (appointmentId: string, riderId?: string) => {
      try {
        showLoader();

        await api.appointment().delete(appointmentId);

        if (riderId) await getAppointmentsByRider({ riderId, limit: 10, page: 1 });

        toast(t('appointment.canceled_success', { ns: 'messages' }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getAppointmentsByRider, hideLoader, showLoader, t],
  );

  const handleDateSelected = useCallback((date: Date) => {
    setDateSelected(date);
  }, []);

  const handleTeacherId = useCallback((id?: string) => {
    setTeacherId(id);
  }, []);

  const handleHourOpened = useCallback((hour: number) => {
    setHourOpened(hour);
  }, []);

  const getHistory = useCallback(
    async (params: IGetAppointmentsHistoryRequest) => {
      try {
        showLoader();

        const response = await api.appointment().getHistory(params);

        const items = response.data.items.map(item => ({
          id: item.id,
          date: new HandleDate().setDateAsISO(item.date).setTimeZone(company?.timezone).formatTz('dd/MM/yyyy') || '',
          hour: new HandleDate().setDateAsISO(item.date).setTimeZone(company?.timezone).formatTz('HH:mm') || '',
          source: item.source,
          teacher: item.teacher?.name || '',
          rider: item.rider?.name || '',
          horse: item.horse?.name || '',
          observation: item.observation,
          canceledAt: item.canceledAt
            ? new HandleDate().setDateAsISO(item.canceledAt).setTimeZone(company?.timezone).formatTz('dd/MM/yyyy')
            : '',
        }));

        setHistory({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [company?.timezone, hideLoader, showLoader],
  );

  const getAppointmentsCalendar = useCallback(
    async (params: IGetAppointmentsCalendarRequest) => {
      try {
        showLoader();

        const response = await api.appointment().getByDates(params);

        setAppointmentsCalendar(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IAppointmentContext>(
    () => ({
      appointmentDetail,
      appointments,
      appointmentsCalendar,
      countAppointmentsRemainingToRider,
      dateSelected,
      history,
      hourOpened,
      riderAppointments,
      teacherId,
      countAppointmentsByRider,
      createAppointment,
      deleteAppointment,
      exportPdfAppointmentsDay,
      exportPdfAppointmentsHistory,
      getAppointmentById,
      getAppointments,
      getAppointmentsByRider,
      getAppointmentsCalendar,
      getHistory,
      handleDateSelected,
      handleHourOpened,
      handleTeacherId,
      updateAppointment,
    }),
    [
      appointmentDetail,
      appointments,
      appointmentsCalendar,
      countAppointmentsRemainingToRider,
      dateSelected,
      history,
      hourOpened,
      riderAppointments,
      teacherId,
      countAppointmentsByRider,
      createAppointment,
      deleteAppointment,
      exportPdfAppointmentsDay,
      exportPdfAppointmentsHistory,
      getAppointmentById,
      getAppointments,
      getAppointmentsByRider,
      getAppointmentsCalendar,
      getHistory,
      handleDateSelected,
      handleHourOpened,
      handleTeacherId,
      updateAppointment,
    ],
  );

  return <AppointmentsContext.Provider value={contextValue}>{children}</AppointmentsContext.Provider>;
};

export { AppointmentsProvider, AppointmentsContext };
