import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { AppointmentsConfirmation } = lazily(() => import('@appointments/pages/AppointmentsConfirmation'));
const { AppointmentsEdit } = lazily(() => import('@appointments/pages/AppointmentsEdit'));
const { AppointmentsHistory } = lazily(() => import('@appointments/pages/AppointmentsHistory'));
const { AppointmentsList } = lazily(() => import('@appointments/pages/AppointmentsList'));
const { AppointmentsSchedule } = lazily(() => import('@appointments/pages/AppointmentsSchedule'));

export default [
  {
    component: AppointmentsList,
    path: 'appointments',
    title: 'private.appointments',
    permission: Roles.GET_APPOINTMENTS,
    canAccess: true,
  },
  {
    component: AppointmentsHistory,
    path: 'appointments/history',
    title: 'private.appointments_history',
    permission: Roles.GET_APPOINTMENTS_HISTORY,
    canAccess: true,
  },
  {
    component: AppointmentsSchedule,
    path: 'appointments/schedule',
    title: 'private.appointments_schedule',
    permission: Roles.CREATE_APPOINTMENT,
    canAccess: true,
  },
  {
    component: AppointmentsEdit,
    path: 'appointments/:id/edit',
    title: 'private.appointments_edit',
    permission: Roles.UPDATE_APPOINTMENT,
    canAccess: true,
  },
  {
    component: AppointmentsConfirmation,
    path: 'appointments/:id/to-confirm',
    title: 'private.appointments_to_confirm',
    permission: Roles.UPDATE_CONFIRM_APPOINTMENT,
    canAccess: true,
  },
] as IRoute[];
