import { AxiosInstance, AxiosResponse } from 'axios';

import { IModalityCategory } from '@modalities/types/ModalityCategories/modalityCategories';

export class ModalityCategory {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  getModalityCategories(modalityId: string): Promise<AxiosResponse<IModalityCategory[]>> {
    return this.instance.get(`/modalities/${modalityId}/categories`);
  }
}

export const modalityCategory = (instance: AxiosInstance): ModalityCategory => new ModalityCategory(instance);
