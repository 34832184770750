import { rgba } from 'polished';
import styled from 'styled-components';

import breakpoints from '@styles/breakpoints';

interface IMenuProps {
  $active: boolean;
}

export const Container = styled.aside<IMenuProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  transition: all 0.4s ease;
  width: ${props => (props.$active ? '235px' : '117px')};
  height: 100%;

  @media ${breakpoints.m} {
    display: none;
  }
`;

export const Content = styled.div<IMenuProps>`
  box-shadow: 3px 0px 6px ${props => rgba(props.theme.colors.black, 0.1)};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
  transition: all 0.4s ease;

  position: fixed;
  top: 0;
  left: 0;

  width: ${props => (props.$active ? '235px' : '117px')};
  height: 100%;

  > button {
    background-color: transparent;
    border: none;
  }
`;

export const Logo = styled.img`
  border: none;
  height: 60px;
`;

export const HandleMenu = styled.button<IMenuProps>`
  background: ${props => props.theme.colors.white} !important;
  border-radius: 30px;
  box-shadow: 3px 0px 6px ${props => rgba(props.theme.colors.black, 0.2)};
  color: ${props => props.theme.colors.gray};
  display: flex;
  padding: 8px;
  position: absolute;
  top: 120px;
  transform: ${props => (props.$active ? 'rotateY(0deg)' : 'rotateY(180deg)')};
  right: -14px;
`;

export const Items = styled.ul`
  list-style: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
