import { useContextSelector } from 'use-context-selector';

import { CompaniesContext } from '@companies/contexts/Companies';
import { ICompanyContext } from '@companies/types/Companies/context';

export function useCompanies(): ICompanyContext {
  const company = useContextSelector(CompaniesContext, cs => cs.company);

  const create = useContextSelector(CompaniesContext, cs => cs.create);
  const createAddress = useContextSelector(CompaniesContext, cs => cs.createAddress);
  const getCurrentCompany = useContextSelector(CompaniesContext, cs => cs.getCurrentCompany);
  const updateAddInformation = useContextSelector(CompaniesContext, cs => cs.updateAddInformation);
  const updateAddress = useContextSelector(CompaniesContext, cs => cs.updateAddress);
  const updateCompany = useContextSelector(CompaniesContext, cs => cs.updateCompany);
  const updateCompanyState = useContextSelector(CompaniesContext, cs => cs.updateCompanyState);

  return {
    company,
    create,

    createAddress,
    getCurrentCompany,
    updateAddInformation,
    updateAddress,
    updateCompany,
    updateCompanyState,
  };
}
