import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  @media (min-width: 769px) {
    display: none !important;
  }

  background-color: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  width: 100vw;
  height: 100vh;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.5rem;
  }
  img {
    width: 30vw;
    align-self: center;
  }

  > button {
    background-color: ${props => props.theme.colors.primary};
    border: none;
    border-radius: 8px;
    color: ${props => props.theme.colors.white};
    font-size: 1.6rem;
    font-weight: 700;
    width: 80vw;
    height: 40px;
  }
`;

export const Content = styled(motion.section)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 2.5rem;
`;

export const Items = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;
