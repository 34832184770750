import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseOwner } from '@horses/types/Horses/horse';
import { IHorseOwnersContext } from '@horses/types/Owners/context';
import { ICreateHorseOwnerRequest, IUpdateHorseOwnerRequest } from '@horses/types/Owners/requests';

import { useLoader } from '@loader/hooks/useLoader';

const HorseOwnersContext = createContext<IHorseOwnersContext>({} as IHorseOwnersContext);

HorseOwnersContext.displayName = 'HorseOwners';

const HorseOwnersProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [owners, setOwners] = useState<IHorseOwner[]>([]);

  const getOwners = useCallback(async () => {
    try {
      showLoader();

      const response = await api.horse().getOwners();

      setOwners(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createOwner = useCallback(
    async (data: ICreateHorseOwnerRequest) => {
      let owner: IHorseOwner = {} as IHorseOwner;

      try {
        showLoader();

        const response = await api.horse().createOwner(data);
        owner = response.data;

        setOwners(current => [...current, owner]);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }

      return owner;
    },
    [hideLoader, showLoader],
  );

  const updateOwner = useCallback(
    async (data: IUpdateHorseOwnerRequest) => {
      try {
        showLoader();

        const response = await api.horse().updateOwner(data);

        setOwners(current => {
          const newOwners = current;
          const index = current.findIndex(owner => owner.id === response.data.id);
          newOwners[index] = response.data;
          return newOwners;
        });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IHorseOwnersContext>(
    () => ({ owners, createOwner, getOwners, updateOwner }),
    [owners, createOwner, getOwners, updateOwner],
  );

  return <HorseOwnersContext.Provider value={contextValue}>{children}</HorseOwnersContext.Provider>;
};

export { HorseOwnersProvider, HorseOwnersContext };
