import { AxiosInstance, AxiosResponse } from 'axios';

import { IModalityCategory } from '@modalities/types/ModalityCategories/modalityCategories';
import {
  ICreateModalityCategoryRequest,
  IDeleteModalityCategoryRequest,
  IUpdateModalityCategoryRequest,
} from '@modalities/types/ModalityCategories/requests';

export class ModalityCategoryAssociation {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(modalityId: string): Promise<AxiosResponse<IModalityCategory[]>> {
    return this.instance.get(`/association/modalities/${modalityId}/categories`);
  }

  create(data: ICreateModalityCategoryRequest): Promise<AxiosResponse<IModalityCategory>> {
    const { modalityId, ...body } = data;
    return this.instance.post(`/association/modalities/${modalityId}/categories`, body);
  }

  update(data: IUpdateModalityCategoryRequest): Promise<AxiosResponse<IModalityCategory>> {
    const { modalityId, id, ...body } = data;
    return this.instance.patch(`/association/modalities/${modalityId}/categories/${id}`, body);
  }

  delete(data: IDeleteModalityCategoryRequest): Promise<AxiosResponse<void>> {
    const { modalityId, modalityCategoryId } = data;
    return this.instance.delete(`/association/modalities/${modalityId}/categories/${modalityCategoryId}`);
  }
}

export const modalityCategoryAssociation = (instance: AxiosInstance): ModalityCategoryAssociation =>
  new ModalityCategoryAssociation(instance);
