import { AxiosInstance, AxiosResponse } from 'axios';

import { ICompany, IAddress } from '@companies/types/Companies/company';
import {
  ICreateCompany,
  IUpdateCompany,
  ICreateCompanyAdditionalInfo,
  ICreateAddress,
  IUpdateAddress,
} from '@companies/types/Companies/requests';

export class Company {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Companies

  create(data: ICreateCompany): Promise<AxiosResponse<ICompany>> {
    return this.instance.post(`/companies`, data);
  }

  get(): Promise<AxiosResponse<ICompany>> {
    return this.instance.get(`/companies/me`);
  }

  update(data: IUpdateCompany): Promise<AxiosResponse<ICompany>> {
    return this.instance.patch(`/companies/me`, data);
  }

  updateAddInformation(data: ICreateCompanyAdditionalInfo): Promise<AxiosResponse<ICompany>> {
    const { id, ...body } = data;
    return this.instance.put(`/companies/${id}`, { ...body });
  }

  // Address

  createAddress(data: ICreateAddress): Promise<AxiosResponse<IAddress>> {
    return this.instance.post(`/companies/me/address`, data);
  }

  updateAddress(data: IUpdateAddress): Promise<AxiosResponse<IAddress>> {
    return this.instance.patch(`/companies/me/address`, data);
  }
}

export const company = (instance: AxiosInstance): Company => new Company(instance);
