import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useAppointment } from '@appointments/hooks/useAppointment';
import { IAppointmentFixedContext } from '@appointments/types/Fixed/context';
import { IAppointmentFixed } from '@appointments/types/Fixed/fixed';
import { ICreateFixedAppointmentRequest, IUpdateFixedAppointmentRequest } from '@appointments/types/Fixed/requests';

import { useLoader } from '@loader/hooks/useLoader';

const AppointmentsFixedContext = createContext<IAppointmentFixedContext>({} as IAppointmentFixedContext);

AppointmentsFixedContext.displayName = 'AppointmentsFixed';

const AppointmentsFixedProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'appointments']);

  const { showLoader, hideLoader } = useLoader();

  const [fixedAppointments, setFixedAppointments] = useState<IAppointmentFixed[]>([]);

  const { getAppointmentsByRider } = useAppointment();

  const getFixedAppointments = useCallback(
    async (riderId: string) => {
      try {
        showLoader();

        const response = await api.appointment().getFixedAppointments(riderId);

        setFixedAppointments(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createFixedAppointment = useCallback(
    async (data: ICreateFixedAppointmentRequest) => {
      try {
        showLoader();

        const response = await api.appointment().createFixedAppointment(data);

        setFixedAppointments(old => [...old, response.data]);

        const item = t('appointment_fixed', { ns: 'appointments' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateFixedAppointment = useCallback(
    async (data: IUpdateFixedAppointmentRequest) => {
      try {
        showLoader();

        const response = await api.appointment().updateFixedAppointment(data);

        const index = fixedAppointments.findIndex(fa => fa.id === response.data.id);
        fixedAppointments[index] = response.data;

        const item = t('appointment_fixed', { ns: 'appointments' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [fixedAppointments, hideLoader, showLoader, t],
  );

  const deleteFixedAppointment = useCallback(
    async (fixedAppointmentId: string, riderId: string) => {
      try {
        showLoader();

        await api.appointment().deleteFixedAppointment(fixedAppointmentId, riderId);

        setFixedAppointments(old => old.filter(fa => fa.id !== fixedAppointmentId));

        await getAppointmentsByRider({ limit: 10, page: 1, riderId });

        const item = t('appointment_fixed', { ns: 'appointments' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [getAppointmentsByRider, hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IAppointmentFixedContext>(
    () => ({
      fixedAppointments,
      getFixedAppointments,
      createFixedAppointment,
      updateFixedAppointment,
      deleteFixedAppointment,
    }),
    [fixedAppointments, getFixedAppointments, createFixedAppointment, updateFixedAppointment, deleteFixedAppointment],
  );

  return <AppointmentsFixedContext.Provider value={contextValue}>{children}</AppointmentsFixedContext.Provider>;
};

export { AppointmentsFixedContext, AppointmentsFixedProvider };
