import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { Dashboard } = lazily(() => import('@dashboard/pages/Dashboard'));

export default [
  {
    component: Dashboard,
    path: 'dashboard',
    title: 'private.dashboard',
    permission: Roles.GET_DASHBOARD,
    canAccess: true,
  },
] as IRoute[];
