import { ModalitiesProvider } from './Modalities';
import { ModalityCategoriesProvider } from './ModalityCategories';

export const AppModalityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ModalitiesProvider>
      <ModalityCategoriesProvider>{children}</ModalityCategoriesProvider>
    </ModalitiesProvider>
  );
};
