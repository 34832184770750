import { Container } from './styles';

interface ISpinnerProps {
  size: number;
}

const Spinner: React.FC<React.PropsWithChildren<ISpinnerProps>> = ({ size }) => {
  return <Container size={size} />;
};

export default Spinner;
