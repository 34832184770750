import { AxiosInstance, AxiosResponse } from 'axios';

import { IProfile } from '@users/types/Profile/profile';
import { IUpdateProfileRequest } from '@users/types/Profile/requests';

export class Profile {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(): Promise<AxiosResponse<IProfile>> {
    return this.instance.get('/profile');
  }

  update(data: IUpdateProfileRequest): Promise<AxiosResponse<IProfile>> {
    return this.instance.patch('/profile', data);
  }
}

export const profile = (instance: AxiosInstance): Profile => new Profile(instance);
