import { useCallback, useMemo, useState } from 'react';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IPersonContext } from '@associations/types/Persons/context';
import { IPerson, PersonType } from '@associations/types/Persons/persons';
import { ICreatePersonRequest } from '@associations/types/Persons/requests';

import { useLoader } from '@loader/hooks/useLoader';

const PersonsContext = createContext<IPersonContext>({} as IPersonContext);

PersonsContext.displayName = 'Persons';

const PersonsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [persons, setPersons] = useState<IPerson[]>([]);

  const createPerson = useCallback(
    async (data: ICreatePersonRequest) => {
      try {
        showLoader();

        const response = await api.person().create(data);

        setPersons(current => [...current, response.data]);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getAllPersons = useCallback(async () => {
    try {
      showLoader();

      const response = await api.person().findAll();

      setPersons(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const getPersonsByType = useCallback(
    async (type: PersonType) => {
      try {
        showLoader();

        const response = await api.person().findByType(type);

        setPersons(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IPersonContext>(
    () => ({ persons, createPerson, getAllPersons, getPersonsByType }),
    [createPerson, getAllPersons, getPersonsByType, persons],
  );

  return <PersonsContext.Provider value={contextValue}>{children}</PersonsContext.Provider>;
};

export { PersonsProvider, PersonsContext };
