import { AxiosInstance, AxiosResponse } from 'axios';

import { IZipCodeResponse } from '@companies/types/Companies/requests';

export class ZipCode {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  get(zipCode: string): Promise<AxiosResponse<IZipCodeResponse>> {
    return this.instance.get(`/cep/${zipCode}`);
  }
}

export const zipCode = (instance: AxiosInstance): ZipCode => new ZipCode(instance);
