import { AxiosInstance, AxiosResponse } from 'axios';

import { IUpdatePayment, IUpdateSubscription } from '@users/types/Subscriptions/requests';
import { ISubscription, ISubscriptionLimits } from '@users/types/Subscriptions/subscriptions';

export class Subscription {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Subscriptions

  getMySubscription(): Promise<AxiosResponse<ISubscription>> {
    return this.instance.get(`/subscriptions/me`);
  }

  updateSubscription(data: IUpdateSubscription): Promise<AxiosResponse<ISubscription>> {
    return this.instance.put(`/subscriptions/me`, data);
  }

  getSubscriptionLimits(): Promise<AxiosResponse<ISubscriptionLimits>> {
    return this.instance.get(`/subscriptions/limits/companies/me`);
  }

  // Payments

  updatePayment(data: IUpdatePayment): Promise<AxiosResponse<ISubscription>> {
    return this.instance.patch(`/companies/me/payment`, data);
  }
}

export const subscription = (instance: AxiosInstance): Subscription => new Subscription(instance);
