import { useUsers } from '@users/hooks/useUsers';

import { boot as bootIntercom } from '../../intercom';

export const Intercom: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user, company, subscription } = useUsers();

  if (user?.id && company?.id) {
    bootIntercom({
      username: user.username,
      name: user.name,
      email: user.email,
      created_at: new Date().getTime() / 1000,
      language_override: user.lang,
      avatar: {
        type: 'avatar',
        image_url: user.avatarUrl ?? '',
      },
      company: {
        id: company.id,
        name: company.fantasyName ?? 'No company name',
        created_at: new Date().getTime() / 1000,
        plan: subscription?.identifier ?? 'No plan',
      },
    });
  }

  function render(): React.ReactNode {
    return children;
  }

  return <>{render()}</>;
};
