import { Storage } from '@constants/Storage';

import { IRoute } from '@routes/index';

interface IGetAllowedRoutes {
  routes: IRoute[];
  permissions: string[];
  signOut: () => Promise<void>;
}

export async function getAllowedRoutes({ routes, permissions, signOut }: IGetAllowedRoutes): Promise<IRoute[]> {
  const permissionsStr = localStorage.getItem(Storage.PERMISSIONS);

  if (!permissionsStr) await signOut();

  const roles = [...permissions];
  return routes.map(route => {
    if (!route.permission) return { ...route, canAccess: true };

    if (Array.isArray(route.permission)) {
      const hasPermission = route.permission.some(p => roles.includes(p));
      return { ...route, canAccess: hasPermission };
    }

    return { ...route, canAccess: roles.includes(route.permission) };
  });
}
