import { HorseExamProvider } from './HorseExams';
import { HorseExpensesProvider } from './HorseExpenses';
import { HorsesFeedsProvider } from './HorseFeeds';
import { HorseMedicineProvider } from './HorseMedicines';
import { HorseOdontologyProvider } from './HorseOdontologist';
import { HorseOwnersProvider } from './HorseOwners';
import { HorseProvider } from './Horses';
import { HorseVaccineProvider } from './HorseVaccines';

export const AppHorseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <HorseProvider>
      <HorseExamProvider>
        <HorseExpensesProvider>
          <HorsesFeedsProvider>
            <HorseMedicineProvider>
              <HorseOwnersProvider>
                <HorseVaccineProvider>
                  <HorseOdontologyProvider>{children}</HorseOdontologyProvider>
                </HorseVaccineProvider>
              </HorseOwnersProvider>
            </HorseMedicineProvider>
          </HorsesFeedsProvider>
        </HorseExpensesProvider>
      </HorseExamProvider>
    </HorseProvider>
  );
};
