export default {
  title: 'light',

  colors: {
    primary: '#2acb97',
    secondary: '#cc4c35',

    primaryLight: '#d4f5ea',

    white: '#ffffff',
    black: '#333333',
    red: '#83311c',
    gray: '#a8a8a8',
    grayLight: '#c4c4c4',
    grayExtraLight: '#e5e5e5',
    greenLight: '#6cd5b4',
    greenStrong: '#0e805c',
    yellowAlert: '#eeb200',
    yellowStrong: '#cb982a',
    negative: '#CC4C2B',

    lightBlue: '#23afd9',

    background: '#fff',
    text: '#333333',

    error: '#cb2c2a',
  },
};
