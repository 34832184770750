import { AxiosInstance, AxiosResponse } from 'axios';

import { IUser } from '@users/types/Users/users';

export class Users {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  updateAvatar(file: File): Promise<AxiosResponse<IUser>> {
    const data = new FormData();
    data.append('avatar', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.instance.patch('/users/upload/avatar', data, { headers });
  }
}

export const users = (instance: AxiosInstance): Users => new Users(instance);
