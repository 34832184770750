import { useContextSelector } from 'use-context-selector';

import { AppointmentsContext } from '@appointments/contexts/Appointments';
import { IAppointmentContext } from '@appointments/types/Appointments/context';

export function useAppointment(): IAppointmentContext {
  const appointmentDetail = useContextSelector(AppointmentsContext, cs => cs.appointmentDetail);
  const appointments = useContextSelector(AppointmentsContext, cs => cs.appointments);
  const appointmentsCalendar = useContextSelector(AppointmentsContext, cs => cs.appointmentsCalendar);
  const countAppointmentsRemainingToRider = useContextSelector(
    AppointmentsContext,
    cs => cs.countAppointmentsRemainingToRider,
  );
  const dateSelected = useContextSelector(AppointmentsContext, cs => cs.dateSelected);
  const riderAppointments = useContextSelector(AppointmentsContext, cs => cs.riderAppointments);
  const teacherId = useContextSelector(AppointmentsContext, cs => cs.teacherId);
  const history = useContextSelector(AppointmentsContext, cs => cs.history);
  const hourOpened = useContextSelector(AppointmentsContext, cs => cs.hourOpened);

  const countAppointmentsByRider = useContextSelector(AppointmentsContext, cs => cs.countAppointmentsByRider);
  const createAppointment = useContextSelector(AppointmentsContext, cs => cs.createAppointment);
  const deleteAppointment = useContextSelector(AppointmentsContext, cs => cs.deleteAppointment);
  const exportPdfAppointmentsDay = useContextSelector(AppointmentsContext, cs => cs.exportPdfAppointmentsDay);
  const exportPdfAppointmentsHistory = useContextSelector(AppointmentsContext, cs => cs.exportPdfAppointmentsHistory);
  const getAppointmentById = useContextSelector(AppointmentsContext, cs => cs.getAppointmentById);
  const getAppointments = useContextSelector(AppointmentsContext, cs => cs.getAppointments);
  const getAppointmentsCalendar = useContextSelector(AppointmentsContext, cs => cs.getAppointmentsCalendar);
  const getAppointmentsByRider = useContextSelector(AppointmentsContext, cs => cs.getAppointmentsByRider);
  const getHistory = useContextSelector(AppointmentsContext, cs => cs.getHistory);
  const handleDateSelected = useContextSelector(AppointmentsContext, cs => cs.handleDateSelected);
  const handleHourOpened = useContextSelector(AppointmentsContext, cs => cs.handleHourOpened);
  const handleTeacherId = useContextSelector(AppointmentsContext, cs => cs.handleTeacherId);
  const updateAppointment = useContextSelector(AppointmentsContext, cs => cs.updateAppointment);

  return {
    appointmentDetail,
    appointments,
    appointmentsCalendar,
    countAppointmentsRemainingToRider,
    dateSelected,
    history,
    hourOpened,
    riderAppointments,
    teacherId,

    countAppointmentsByRider,
    createAppointment,
    deleteAppointment,
    exportPdfAppointmentsDay,
    exportPdfAppointmentsHistory,
    getAppointmentById,
    getAppointments,
    getAppointmentsCalendar,
    getAppointmentsByRider,
    getHistory,
    handleDateSelected,
    handleHourOpened,
    handleTeacherId,
    updateAppointment,
  };
}
