import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { BalanceCategories } = lazily(() => import('@balances/pages/Categories'));
const { BalanceDashboard } = lazily(() => import('@balances/pages/Dashboard'));
const { BalanceMovements } = lazily(() => import('@balances/pages/Movements'));
const { BalanceReports } = lazily(() => import('@balances/pages/Reports'));
const { BalanceWallets } = lazily(() => import('@balances/pages/Wallets'));

export default [
  {
    component: BalanceDashboard,
    path: 'balance',
    title: 'private.balance',
    permission: Roles.GET_BALANCE,
    canAccess: true,
  },
  {
    component: BalanceWallets,
    path: 'balance/wallets',
    title: 'private.balance_wallets',
    permission: Roles.GET_WALLET,
    canAccess: true,
  },
  {
    component: BalanceCategories,
    path: 'balance/categories',
    title: 'private.balance_categories',
    permission: Roles.GET_BALANCE_CATEGORIES,
    canAccess: true,
  },
  {
    component: BalanceMovements,
    path: 'balance/all',
    title: 'private.balance_movements',
    permission: Roles.GET_BALANCE,
    canAccess: true,
  },
  {
    component: BalanceReports,
    path: 'balance/reports',
    title: 'private.reports',
    permission: Roles.VIEW_BALANCE_REPORTS,
    canAccess: true,
  },
] as IRoute[];
