import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IMicrochip } from '@associations/types/Affiliates/affiliates';
import { IHandleMicrochipRequest } from '@associations/types/Affiliates/requests';
import { IAffiliatesMicrochipContext } from '@associations/types/Microchips/context';

import { useLoader } from '@loader/hooks/useLoader';

const AffiliatesMicrochipContext = createContext<IAffiliatesMicrochipContext>({} as IAffiliatesMicrochipContext);

AffiliatesMicrochipContext.displayName = 'AffiliatesMicrochip';

const AffiliatesMicrochipProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'affiliates']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [microchip, setMicrochip] = useState<IMicrochip>({} as IMicrochip);

  const getMicrochip = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const { data } = await api.affiliates().getMicrochip(id);

        setMicrochip(data);
      } catch (err) {
        handleErrorApi({ err, otherStatus: [404] });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const handleMicrochip = useCallback(
    async (microchipData: IHandleMicrochipRequest) => {
      try {
        showLoader();

        const { data } = await api.affiliates().handleMicrochip(microchipData);

        navigate(`/affiliates/horses/${microchipData.affiliatedId}/edit`);

        setMicrochip(data);

        toast(
          t(microchipData.id ? 'crud.updated_success' : 'crud.created_success', {
            ns: 'messages',
            context: 'male',
            item: t('microchip', { ns: 'affiliates' }),
          }),
          { type: 'success' },
        );
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t],
  );

  const handleSetMicrochip = useCallback((data: IMicrochip) => {
    setMicrochip(data);
  }, []);

  const contextValue = useMemo<IAffiliatesMicrochipContext>(
    () => ({ microchip, getMicrochip, handleMicrochip, handleSetMicrochip }),
    [microchip, getMicrochip, handleMicrochip, handleSetMicrochip],
  );

  return <AffiliatesMicrochipContext.Provider value={contextValue}>{children}</AffiliatesMicrochipContext.Provider>;
};

export { AffiliatesMicrochipProvider, AffiliatesMicrochipContext };
