import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseVaccineContext } from '@horses/types/Vaccines/context';
import {
  IGetHorseVaccinesParams,
  ICreateHorseVaccineRequest,
  IDeleteHorseVaccineParams,
} from '@horses/types/Vaccines/requests';
import { IVaccine } from '@horses/types/Vaccines/vaccine';

import { useLoader } from '@loader/hooks/useLoader';

const HorseVaccineContext = createContext<IHorseVaccineContext>({} as IHorseVaccineContext);

HorseVaccineContext.displayName = 'HorseVaccines';

const HorseVaccineProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [vaccines, setVaccines] = useState<IVaccine[]>([]);

  const getHorseVaccines = useCallback(
    async (params: IGetHorseVaccinesParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseVaccines(params);

        const items = response.data.map(vaccine => {
          const dateFormatted = format(parseISO(vaccine.date), 'dd/MM/yyyy');
          return { ...vaccine, dateFormatted };
        });

        setVaccines(items);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseVaccine = useCallback(
    async (data: ICreateHorseVaccineRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorseVaccine(data);

        const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');

        setVaccines(current => [...current, { ...response.data, dateFormatted }]);

        const item = t('vaccine', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorseVaccine = useCallback(
    async (params: IDeleteHorseVaccineParams) => {
      try {
        showLoader();

        await api.horse().deleteHorseVaccine(params);

        setVaccines(current => current.filter(vaccine => vaccine.id !== params.id));

        const item = t('vaccine', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorseVaccineContext>(
    () => ({ vaccines, getHorseVaccines, createHorseVaccine, deleteHorseVaccine }),
    [vaccines, getHorseVaccines, createHorseVaccine, deleteHorseVaccine],
  );

  return <HorseVaccineContext.Provider value={contextValue}>{children}</HorseVaccineContext.Provider>;
};

export { HorseVaccineProvider, HorseVaccineContext };
