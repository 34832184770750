import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorsesFeedsContext } from '@horses/types/Feeds/context';
import { IHorseFeedHistory, IHorseFeed } from '@horses/types/Feeds/feed';
import { ICreateFeedRequest, IGetHorseFeedHistoryRequest, IDeleteFeedFoodRequest } from '@horses/types/Feeds/requests';

import { useLoader } from '@loader/hooks/useLoader';

const HorsesFeedsContext = createContext<IHorsesFeedsContext>({} as IHorsesFeedsContext);

HorsesFeedsContext.displayName = 'HorsesFeeds';

type History = EquitesPaginate<IHorseFeedHistory>;

const HorsesFeedsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [feedHorses, setFeedHorses] = useState<IHorseFeed[]>([]);
  const [feedHorseHistory, setFeedHorseHistory] = useState<History>({} as History);

  const getFeedHorses = useCallback(async () => {
    try {
      showLoader();

      const response = await api.feedHorse().getFeedHorses();

      setFeedHorses(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createFeedHorses = useCallback(
    async (data: ICreateFeedRequest) => {
      try {
        await api.feedHorse().createFeed(data);

        const item = t('feeding', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, t],
  );

  const getHistoryByHorse = useCallback(
    async (params: IGetHorseFeedHistoryRequest) => {
      try {
        showLoader();

        const response = await api.feedHorse().getFeedHistoryByHorse(params);

        const items = response.data.items.map(item => ({
          ...item,
          dateFormatted: format(parseISO(item.date), 'dd/MM/yyyy'),
        }));

        setFeedHorseHistory({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const deleteFeedFood = useCallback(
    async (params: IDeleteFeedFoodRequest) => {
      try {
        showLoader();

        await api.feedHorse().deleteFeedFood(params);

        const items = feedHorseHistory.items.filter(item => item.feedFoodId !== params.feedFoodId);

        setFeedHorseHistory(current => ({ ...current, items, totalItems: current.totalItems - 1 }));

        const item = t('feeding', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [feedHorseHistory.items, hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorsesFeedsContext>(
    () => ({ feedHorses, feedHorseHistory, deleteFeedFood, getHistoryByHorse, getFeedHorses, createFeedHorses }),
    [feedHorses, feedHorseHistory, deleteFeedFood, getHistoryByHorse, getFeedHorses, createFeedHorses],
  );

  return <HorsesFeedsContext.Provider value={contextValue}>{children}</HorsesFeedsContext.Provider>;
};

export { HorsesFeedsContext, HorsesFeedsProvider };
