import { useCallback, useMemo, useState } from 'react';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IDashboardContext } from '@dashboard/types/context';
import { IDashboard, IDashboardAssociation } from '@dashboard/types/dashboard';
import { IGetDashboardAssociationRequest } from '@dashboard/types/requests';

import { useLoader } from '@loader/hooks/useLoader';

const DashboardContext = createContext<IDashboardContext>({} as IDashboardContext);
DashboardContext.displayName = 'Dashboard';

const DashboardProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();

  const [dashboard, setDashboard] = useState<IDashboard>({} as IDashboard);
  const [dashboardAssociation, setDashboardAssociation] = useState<IDashboardAssociation>({
    athletes: 0,
    horses: 0,
    companies: 0,
    birthdays: [],
  });

  const getDashboard = useCallback(async () => {
    try {
      showLoader();

      const response = await api.dashboard().getDashboard();

      const birthdaysOfTheMonth =
        response.data.birthdaysOfTheMonth?.map(rider => ({
          ...rider,
          birthDateFormatted: format(parseISO(rider.birthDate), 'dd/MM'),
        })) || [];

      const foodForecast =
        response.data.foodForecast?.map(food => ({
          ...food,
          expectedEndDateFormatted: format(parseISO(food.expectedEndDate), 'dd/MM/yyyy'),
        })) || [];

      setDashboard({ ...response.data, birthdaysOfTheMonth, foodForecast });
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const getDashboardAssociation = useCallback(
    async (params: IGetDashboardAssociationRequest) => {
      try {
        showLoader();

        const response = await api.dashboard().getDashboardAssociation(params);

        const birthdays = response.data.birthdays.map(affiliated => ({
          ...affiliated,
          birthday: format(parseISO(affiliated.birthday), 'dd/MM'),
        }));

        setDashboardAssociation({ ...response.data, birthdays });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IDashboardContext>(
    () => ({ dashboard, dashboardAssociation, getDashboard, getDashboardAssociation }),
    [dashboard, dashboardAssociation, getDashboard, getDashboardAssociation],
  );

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

export { DashboardProvider, DashboardContext };
