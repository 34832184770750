import { AxiosInstance, AxiosResponse } from 'axios';

import {
  ICreateRiderLessonPackageRequest,
  ICreateRiderPaymentLessonPackageRequest,
  ICreateRiderPaymentRequest,
  ICreateRiderRequest,
  IDeleteRiderPaymentRequest,
  IDeleteRiderRequest,
  IGetRiderPaymentsParams,
  IPaidRiderPaymentRequest,
  IUpdateRiderLessonPackageRequest,
  IUpdateRiderRequest,
} from '@riders/types/requests';
import { IRider, IRiderLessonPackage, IRiderPayment, IRiderPayments } from '@riders/types/riders';

export class Rider {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Riders

  getAll(): Promise<AxiosResponse<IRider[]>> {
    return this.instance.get('/riders');
  }

  getById(id: string): Promise<AxiosResponse<IRider>> {
    return this.instance.get(`/riders/${id}`);
  }

  createRider(data: ICreateRiderRequest): Promise<AxiosResponse<IRider>> {
    return this.instance.post('/riders', data);
  }

  deleteRider(data: IDeleteRiderRequest): Promise<AxiosResponse> {
    return this.instance.delete(`/riders/${data.riderId}`);
  }

  updateRider(data: IUpdateRiderRequest): Promise<AxiosResponse<IRider>> {
    const { id, ...body } = data;
    return this.instance.patch(`/riders/${id}`, body);
  }

  updateRiderAvatar(id: string, file: File): Promise<AxiosResponse<IRider>> {
    const data = new FormData();
    data.append('avatar', file);
    return this.instance.patch(`/riders/${id}/upload/avatar`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // LessonPackages

  getLessonPackageByRider(riderId: string): Promise<AxiosResponse<IRiderLessonPackage>> {
    return this.instance.get(`/riders/${riderId}/lessons-package`);
  }

  createLessonPackage(data: ICreateRiderLessonPackageRequest): Promise<AxiosResponse<IRiderLessonPackage>> {
    const { riderId, ...body } = data;
    return this.instance.post(`/riders/${riderId}/lessons-package`, body);
  }

  updateLessonPackage(data: IUpdateRiderLessonPackageRequest): Promise<AxiosResponse<IRiderLessonPackage>> {
    const { id, riderId, ...body } = data;
    return this.instance.put(`/riders/${riderId}/lessons-package/${id}`, body);
  }

  // Payments

  getPaymentsByRider(riderPaymentParams: IGetRiderPaymentsParams): Promise<AxiosResponse<IRiderPayments>> {
    const { riderId, ...params } = riderPaymentParams;
    return this.instance.get(`/riders/${riderId}/payments`, { params });
  }

  createRiderPayment(data: ICreateRiderPaymentRequest): Promise<AxiosResponse<IRiderPayment>> {
    const { riderId, ...body } = data;
    return this.instance.post(`/riders/${riderId}/payments`, body);
  }

  deleteRiderPayment(data: IDeleteRiderPaymentRequest): Promise<AxiosResponse<void>> {
    const { riderId, paymentId } = data;
    return this.instance.delete(`/riders/${riderId}/payments/${paymentId}`);
  }

  createRiderPaymentLessonPackage(
    data: ICreateRiderPaymentLessonPackageRequest,
  ): Promise<AxiosResponse<IRiderPayment>> {
    const { riderId, ...body } = data;
    return this.instance.post(`/riders/${riderId}/payments/lesson-package`, body);
  }

  updateRiderPaymentToPaid(params: IPaidRiderPaymentRequest): Promise<AxiosResponse<IRiderPayment>> {
    const { riderId, paymentId, ...body } = params;
    return this.instance.patch(`/riders/${riderId}/payments/${paymentId}/paid`, body);
  }
}

export const rider = (instance: AxiosInstance): Rider => new Rider(instance);
