import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { createContext } from 'use-context-selector';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { useLoader } from '@loader/hooks/useLoader';

import { IPaymentMethodsContext } from '@paymentMethods/types/PaymentMethods/context';
import { IPaymentMethod } from '@paymentMethods/types/PaymentMethods/paymentMethods';
import { ICreatePaymentMethodRequest } from '@paymentMethods/types/PaymentMethods/requests';

const PaymentMethodContext = createContext<IPaymentMethodsContext>({} as IPaymentMethodsContext);

PaymentMethodContext.displayName = 'PaymentMethod';

const PaymentMethodProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { showLoader, hideLoader } = useLoader();
  const { t } = useTranslation(['messages', 'plans']);

  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);

  const getPaymentMethods = useCallback(async () => {
    try {
      showLoader();

      const response = await api.paymentMethods().getAll();

      setPaymentMethods(response.data);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createPaymentMethod = useCallback(
    async (data: ICreatePaymentMethodRequest) => {
      try {
        showLoader();

        const response = await api.paymentMethods().create(data);

        setPaymentMethods(current => [...current, response.data]);

        const item = t('payment_method', { ns: 'plans' });
        const message = t('crud.created_success', { ns: 'messages', context: 'male', item });
        toast(message, { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deletePaymentMethodById = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.paymentMethods().delete(id);

        setPaymentMethods(current => current.filter(pm => pm.id !== id));

        const item = t('payment_method', { ns: 'plans' });
        const message = t('crud.deleted_success', { ns: 'messages', context: 'male', item });
        toast(message, { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const turnPaymentMethodAsDefault = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.paymentMethods().updatePaymentMethodToDefault(id);

        setPaymentMethods(current =>
          current.map(paymentMethod => {
            if (paymentMethod.id === id) return { ...paymentMethod, isDefault: true };
            return { ...paymentMethod, isDefault: false };
          }),
        );

        const item = t('payment_method', { ns: 'plans' });
        const message = t('crud.updated_success', { ns: 'messages', context: 'male', item });
        toast(message, { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IPaymentMethodsContext>(
    () => ({
      paymentMethods,
      getPaymentMethods,
      createPaymentMethod,
      deletePaymentMethodById,
      turnPaymentMethodAsDefault,
    }),
    [createPaymentMethod, deletePaymentMethodById, getPaymentMethods, paymentMethods, turnPaymentMethodAsDefault],
  );

  return <PaymentMethodContext.Provider value={contextValue}>{children}</PaymentMethodContext.Provider>;
};

export { PaymentMethodProvider, PaymentMethodContext };
