import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseExpenseContext } from '@horses/types/Expenses/context';
import { IHorseExpense } from '@horses/types/Expenses/expense';
import {
  IGetHorseExpensesParams,
  IReportHorseExpensesParams,
  ICreateHorseExpenseRequest,
  IDeleteHorseExpenseRequest,
  IPaidHorseExpenseRequest,
} from '@horses/types/Expenses/requests';

import { useLoader } from '@loader/hooks/useLoader';

type IExpenses = EquitesPaginate<IHorseExpense>;

const HorseExpensesContext = createContext<IHorseExpenseContext>({} as IHorseExpenseContext);

HorseExpensesContext.displayName = 'HorseExpenses';

const HorseExpensesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [expenses, setExpenses] = useState({} as IExpenses);

  function formatHorseExpense(expense: IHorseExpense): IHorseExpense {
    const amountFormatted = expense.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    const dateFormatted = format(parseISO(expense.date), 'dd/MM/yyyy');
    const paidFormatted = expense.paid ? format(parseISO(expense.paid), 'dd/MM/yyyy') : 'Em aberto';

    return { ...expense, amountFormatted, dateFormatted, paidFormatted };
  }

  const getHorseExpenses = useCallback(
    async (params: IGetHorseExpensesParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseExpenses(params);

        const items = response.data.items.map(expense => formatHorseExpense(expense));

        setExpenses({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getReportHorseExpenses = useCallback(
    async (params: IReportHorseExpensesParams) => {
      try {
        showLoader();

        await api.horse().getReportHorseExpenses(params);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseExpense = useCallback(
    async (data: ICreateHorseExpenseRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorseExpense(data);

        setExpenses(current => ({
          ...current,
          totalItems: current.totalItems + 1,
          items: [formatHorseExpense(response.data), ...current.items],
        }));

        const item = t('expense', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorseExpense = useCallback(
    async (params: IDeleteHorseExpenseRequest) => {
      try {
        showLoader();

        await api.horse().deleteHorseExpense(params);

        setExpenses(current => ({
          ...current,
          totalItems: current.totalItems - 1,
          items: current.items.filter(item => item.id !== params.expenseId),
        }));

        const item = t('expense', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const paidHorseExpense = useCallback(
    async (data: IPaidHorseExpenseRequest) => {
      try {
        showLoader();

        const response = await api.horse().paidHorseExpense(data);

        setExpenses(current => {
          const { items } = current;

          const index = current.items.findIndex(expense => expense.id === data.expenseId);
          items[index] = response.data;

          const newItems = items.map(expense => formatHorseExpense(expense));

          return { ...current, items: newItems };
        });

        const item = t('expense', { ns: 'horses' });
        toast(t('payment.paid_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorseExpenseContext>(
    () => ({
      expenses,
      createHorseExpense,
      deleteHorseExpense,
      getHorseExpenses,
      getReportHorseExpenses,
      paidHorseExpense,
    }),
    [expenses, createHorseExpense, deleteHorseExpense, getHorseExpenses, getReportHorseExpenses, paidHorseExpense],
  );

  return <HorseExpensesContext.Provider value={contextValue}>{children}</HorseExpensesContext.Provider>;
};

export { HorseExpensesProvider, HorseExpensesContext };
