import { lazily } from 'react-lazily';

import { IRoute } from '@routes/index';

const { AssociationPublicListAthletes } = lazily(() => import('@associations/pages/AssociationPublic/ListAthletes'));
const { AssociationPublicListHorses } = lazily(() => import('@associations/pages/AssociationPublic/ListHorses'));
const { RegisterAffiliate } = lazily(() => import('@associations/pages/RegisterAffiliate'));
const { RegisterAssociationCompany } = lazily(() => import('@associations/pages/RegisterAssociationCompany'));

export default [
  {
    component: AssociationPublicListAthletes,
    path: '/entities/:slug/athletes',
    title: 'public.association_public_list_athletes',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
  {
    component: AssociationPublicListHorses,
    path: '/entities/:slug/horses',
    title: 'public.association_public_list_horses',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
  {
    component: RegisterAssociationCompany,
    path: '/register/association/company/:token',
    title: 'public.register_association_company',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
  {
    component: RegisterAssociationCompany,
    path: '/update/association/company/:token',
    title: 'public.update_association_company',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
  {
    component: RegisterAffiliate,
    path: '/register/association/affiliates/:token',
    title: 'public.register_association_affiliated',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
  {
    component: RegisterAffiliate,
    path: '/update/association/affiliates/:token',
    title: 'public.update_association_affiliated',
    canAccess: true,
    isPublicButAccessLogged: true,
  },
] as IRoute[];
