import { AxiosInstance, AxiosResponse } from 'axios';

import { ISignInRequest, ISignInResponse } from '@users/types/Auth/requests';

export class Sessions {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(data: ISignInRequest): Promise<AxiosResponse<ISignInResponse>> {
    return this.instance.post('/sessions', data);
  }
}

export const sessions = (instance: AxiosInstance): Sessions => new Sessions(instance);
