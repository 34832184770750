import { AxiosInstance, AxiosResponse } from 'axios';

import EquitesPaginate from '@equites/api/paginate';

import { IGroup } from '@users/types/Groups/groups';
import { ICreateGroupPermissionsRequest } from '@users/types/Groups/requests';
import { IPermission } from '@users/types/Permissions/permissions';
import { IUpdateGroupPermissionsRequest } from '@users/types/Permissions/requests';
import { IGetAllTeamUsersRequest, ICreateTeamUserRequest, IUpdateTeamUserRequest } from '@users/types/Team/requests';
import { ITeamUser } from '@users/types/Team/teams';
import { IUser } from '@users/types/Users/users';

type GetAllTeamUsersResponse = Promise<AxiosResponse<EquitesPaginate<ITeamUser>>>;

export class TeamUser {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Team users

  getAll(params: IGetAllTeamUsersRequest): GetAllTeamUsersResponse {
    return this.instance.get(`/companies/users`, { params });
  }

  create(data: ICreateTeamUserRequest): Promise<AxiosResponse<IUser>> {
    return this.instance.post(`/companies/users`, data);
  }

  update(data: IUpdateTeamUserRequest): Promise<AxiosResponse<IUser>> {
    const { id, ...body } = data;
    return this.instance.patch(`/companies/users/${id}`, body);
  }

  delete(userId: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/companies/users/${userId}`);
  }

  handleBlock(userId: string, blocked: boolean): Promise<AxiosResponse<void>> {
    return this.instance.patch(`/companies/users/${userId}/blocked`, { blocked });
  }

  // Groups

  getGroups(isAssociation: boolean): Promise<AxiosResponse<IGroup[]>> {
    return this.instance.get(`/users/groups`, { params: { isAssociation } });
  }

  createGroup(data: ICreateGroupPermissionsRequest): Promise<AxiosResponse<IGroup[]>> {
    return this.instance.post(`/users/groups`, data);
  }

  updateGroup(data: IUpdateGroupPermissionsRequest): Promise<AxiosResponse<IGroup[]>> {
    const { id, ...body } = data;
    return this.instance.patch(`/users/groups/${id}`, body);
  }

  // Permissions

  getPermissions(): Promise<AxiosResponse<IPermission[]>> {
    return this.instance.get(`/users/permissions`);
  }
}

export const teamUser = (instance: AxiosInstance): TeamUser => new TeamUser(instance);
