import { useCallback, useEffect, useState } from 'react';

import { Storage } from '@constants/Storage';

import Button from '@components/Button';

import { Container, Content } from './styles';

const Cookie: React.FC<React.PropsWithChildren> = () => {
  const [cookieStatus, setCookieStatus] = useState<boolean>(true);

  useEffect(() => {
    const cookieVerification = JSON.parse(localStorage.getItem(Storage.COOKIE) ?? '{}');
    setCookieStatus(cookieVerification.acceptedCookies);
  }, []);

  const acceptCookies = useCallback(() => {
    localStorage.setItem(Storage.COOKIE, JSON.stringify({ acceptedCookies: true }));
    setCookieStatus(true);
  }, []);

  return cookieStatus ? null : (
    <Container>
      <Content>
        <h1>Aviso de cookies</h1>
        <span>
          O Equites usa cookies que são indispensáveis para continuarmos galopando por aqui e assim manter as suas
          preferências. Você pode saber mais em nossa
          <a href="https://equites.com.br/privacy-policy" target="_blank" rel="noreferrer">
            Política de Privacidade.
          </a>
        </span>
      </Content>
      <Button ghost onClick={acceptCookies}>
        Ok
      </Button>
    </Container>
  );
};

export default Cookie;
