import { AxiosInstance, AxiosResponse } from 'axios';

import { ICreateTeacherRequest, IUpdateTeacherRequest } from '@teachers/types/requests';
import { ITeacher } from '@teachers/types/teacher';

export class Teacher {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  create(data: ICreateTeacherRequest): Promise<AxiosResponse<ITeacher>> {
    return this.instance.post('/teachers', data);
  }

  deleteById(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/teachers/${id}`);
  }

  getAll(): Promise<AxiosResponse<ITeacher[]>> {
    return this.instance.get('/teachers');
  }

  getById(teacherId: string): Promise<AxiosResponse<ITeacher>> {
    return this.instance.get(`/teachers/${teacherId}`);
  }

  update(data: IUpdateTeacherRequest): Promise<AxiosResponse<ITeacher>> {
    const { id, ...body } = data;
    return this.instance.put(`/teachers/${id}`, body);
  }

  updateTeacherAvatar(teacherId: string, file: File): Promise<AxiosResponse<ITeacher>> {
    const data = new FormData();
    data.append('avatar', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return this.instance.patch(`/teachers/${teacherId}/upload/avatar`, data, { headers });
  }
}

export const teacher = (instance: AxiosInstance): Teacher => new Teacher(instance);
