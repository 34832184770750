import { RiderLessonPackageProvider } from './LessonPackage';
import { RiderPaymentsProvider } from './Payments';
import { RidersProvider } from './Riders';

export const AppRiderProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <RidersProvider>
      <RiderLessonPackageProvider>
        <RiderPaymentsProvider>{children}</RiderPaymentsProvider>
      </RiderLessonPackageProvider>
    </RidersProvider>
  );
};
