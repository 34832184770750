import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseContext } from '@horses/types/Horses/context';
import { IHorse, IHorseFood, IHorseshoes } from '@horses/types/Horses/horse';
import {
  IHandleHorseFoodsParams,
  IGetHorseHorseshoesParams,
  ICreateHorseHorseshoesParams,
  ICreateHorseRequest,
  IUpdateHorseRequest,
  IGetHorsesPaginateParams,
} from '@horses/types/Horses/requests';

import { useLoader } from '@loader/hooks/useLoader';

const HorseContext = createContext<IHorseContext>({} as IHorseContext);

HorseContext.displayName = 'Horses';

const HorseProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useLoader();

  const [horse, setHorse] = useState({} as IHorse);
  const [horseRegister, setHorseRegister] = useState({} as Partial<IHorse>);
  const [horses, setHorses] = useState<IHorse[]>([]);
  const [horsesPaginate, setHorsesPaginate] = useState({} as EquitesPaginate<IHorse>);
  const [horseFoods, setHorseFoods] = useState<IHorseFood[]>([]);
  const [lastHorseshoes, setLastHorseshoes] = useState({} as IHorseshoes);
  const [stepRegister, setStepRegister] = useState(1);

  const formatHorseFoods = useCallback((foods: IHorseFood[]) => {
    return foods.map(horseFood => {
      const quantityFormatted = `${new Intl.NumberFormat('pt-BR').format(horseFood.quantity)} kg`;
      return { ...horseFood, quantityFormatted };
    });
  }, []);

  const getHorse = useCallback(
    async (id: string) => {
      try {
        showLoader();

        const response = await api.horse().getHorse(id);

        setHorse(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const getHorseFoods = useCallback(
    async (horseId: string) => {
      try {
        showLoader();

        const response = await api.horse().getHorseFoods(horseId);

        setHorseFoods(formatHorseFoods(response.data));
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [formatHorseFoods, hideLoader, showLoader],
  );

  const handleHorseFoods = useCallback(
    async (params: IHandleHorseFoodsParams) => {
      try {
        showLoader();

        const response = await api.horse().handleHorseFoods(params);

        setHorseFoods(formatHorseFoods(response.data));

        const item = t('feeding', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [formatHorseFoods, hideLoader, showLoader, t],
  );

  const getHorseHorseshoes = useCallback(
    async (params: IGetHorseHorseshoesParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseLastHorseshoes(params);

        setLastHorseshoes(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseshoes = useCallback(
    async (params: ICreateHorseHorseshoesParams) => {
      try {
        showLoader();

        await api.horse().createHorseshoes(params);

        const item = t('horseshoe', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'female', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const getHorses = useCallback(async () => {
    try {
      showLoader();

      const response = await api.horse().getHorses();
      setHorses(response.data);

      setHorse({} as IHorse);
      setHorseRegister({} as Partial<IHorse>);
      setStepRegister(1);
    } catch (err) {
      handleErrorApi({ err });
    } finally {
      hideLoader();
    }
  }, [hideLoader, showLoader]);

  const createHorse = useCallback(
    async ({ file }: ICreateHorseRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorse(horseRegister);

        if (file) await api.horse().updateHorseAvatar(response.data.id, file);

        navigate(`/stable`);

        const item = t('horse', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, horseRegister, navigate, showLoader, t],
  );

  const updateHorse = useCallback(
    async ({ file, ...data }: IUpdateHorseRequest) => {
      try {
        showLoader();

        const response = await api.horse().updateHorse(data);

        if (file) await api.horse().updateHorseAvatar(response.data.id, file);

        const birthDate = response.data.birthDate
          ? format(parseISO(response.data.birthDate), 'ddMMyyyy')
          : response.data.birthDate;

        setHorse({ ...response.data, birthDate });

        const item = t('horse', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorse = useCallback(
    async (id: string) => {
      try {
        showLoader();

        await api.horse().deleteHorse(id);

        navigate(`/stable`);

        const item = t('horse', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, navigate, showLoader, t],
  );

  const handleStepRegister = useCallback((step: number) => {
    setStepRegister(step);
  }, []);

  const handleHorseRegister = useCallback((data: Partial<IHorse>) => {
    setHorseRegister(current => ({ ...current, ...data }));
  }, []);

  const getHorsesPaginate = useCallback(
    async (data: IGetHorsesPaginateParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorsesPaginate(data);

        setHorsesPaginate(response.data);
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const contextValue = useMemo<IHorseContext>(
    () => ({
      horse,
      horseRegister,
      horses,
      horsesPaginate,
      horseFoods,
      lastHorseshoes,
      stepRegister,
      createHorse,
      createHorseshoes,
      deleteHorse,
      getHorse,
      getHorses,
      getHorsesPaginate,
      getHorseFoods,
      getHorseHorseshoes,
      handleHorseFoods,
      handleHorseRegister,
      handleStepRegister,
      updateHorse,
    }),
    [
      horse,
      horseRegister,
      horses,
      horsesPaginate,
      horseFoods,
      lastHorseshoes,
      stepRegister,
      createHorse,
      createHorseshoes,
      deleteHorse,
      getHorse,
      getHorses,
      getHorsesPaginate,
      getHorseFoods,
      getHorseHorseshoes,
      handleHorseFoods,
      handleHorseRegister,
      handleStepRegister,
      updateHorse,
    ],
  );

  return <HorseContext.Provider value={contextValue}>{children}</HorseContext.Provider>;
};

export { HorseProvider, HorseContext };
