import { lazily } from 'react-lazily';

import Roles from '@config/Roles';

import { IRoute } from '@routes/index';

const { Configurations } = lazily(() => import('@configurations/pages/Configurations'));
const { ModalitiesCategories } = lazily(() => import('@configurations/pages/ModalitiesCategories'));

export default [
  {
    component: Configurations,
    path: 'configurations',
    title: 'private.configurations',
    permission: Roles.GET_CONFIGURATIONS,
    canAccess: true,
  },
  {
    component: ModalitiesCategories,
    path: 'configurations/modalities-categories',
    title: 'private.modalities_categories',
    // TODO: Permission here
    // permission: Roles.GET_CONFIGURATIONS,
    canAccess: true,
  },
] as IRoute[];
