import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { format, parseISO } from 'date-fns';
import { createContext } from 'use-context-selector';

import EquitesPaginate from '@equites/api/paginate';

import api from '@services/index';

import { handleErrorApi } from '@utils/handleError';

import { IHorseExamContext } from '@horses/types/Exams/context';
import { IHorseExam } from '@horses/types/Exams/exam';
import {
  IGetHorseExamsParams,
  ICreateHorseExamRequest,
  IUpdateHorseExamRequest,
  IDeleteHorseExamParams,
  IHorseExamReportUploadRequest,
  IDeleteHorseExamReportRequest,
} from '@horses/types/Exams/requests';

import { useLoader } from '@loader/hooks/useLoader';

type IExam = EquitesPaginate<IHorseExam>;

const HorseExamContext = createContext<IHorseExamContext>({} as IHorseExamContext);

HorseExamContext.displayName = 'HorseExams';

const HorseExamProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['messages', 'horses']);

  const { showLoader, hideLoader } = useLoader();

  const [exams, setExams] = useState<IExam>({ totalItems: 0 } as IExam);

  const getHorseExams = useCallback(
    async (params: IGetHorseExamsParams) => {
      try {
        showLoader();

        const response = await api.horse().getHorseExams(params);

        const items = response.data.items.map(exam => {
          const dateFormatted = format(parseISO(exam.date), 'dd/MM/yyyy');
          const dueFormatted = exam.due ? format(parseISO(exam.due), 'dd/MM/yyyy') : '';
          return { ...exam, dateFormatted, dueFormatted };
        });

        setExams({ ...response.data, items });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader],
  );

  const createHorseExam = useCallback(
    async ({ file, ...data }: ICreateHorseExamRequest) => {
      try {
        showLoader();

        const response = await api.horse().createHorseExam(data);

        if (file) {
          const { horseId, id: examId } = response.data;
          const responseUpload = await api.horse().uploadHorseExamReport({ horseId, examId, report: file });

          Object.assign(response.data, { reportUrl: responseUpload.data.reportUrl });
        }

        const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');
        const dueFormatted = response.data.due ? format(parseISO(response.data.due), 'dd/MM/yyyy') : '';

        setExams(current => ({
          ...current,
          totalItems: current.totalItems + 1,
          items: [...current.items, { ...response.data, dateFormatted, dueFormatted }],
        }));

        const item = t('exam', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const updateHorseExam = useCallback(
    async (data: IUpdateHorseExamRequest) => {
      try {
        showLoader();

        const response = await api.horse().updateHorseExam(data);

        setExams(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === data.id);

          const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');
          const dueFormatted = response.data.due ? format(parseISO(response.data.due), 'dd/MM/yyyy') : '';

          items[index] = { ...response.data, dateFormatted, dueFormatted };

          return { ...current, items };
        });

        const item = t('exam', { ns: 'horses' });
        toast(t('crud.updated_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteHorseExam = useCallback(
    async (params: IDeleteHorseExamParams) => {
      try {
        showLoader();

        await api.horse().deleteHorseExam(params);

        setExams(current => ({
          ...current,
          totalItems: current.totalItems - 1,
          items: current.items.filter(exam => exam.id !== params.id),
        }));

        const item = t('exam', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const uploadExamReport = useCallback(
    async (params: IHorseExamReportUploadRequest) => {
      try {
        showLoader();

        const response = await api.horse().uploadHorseExamReport(params);

        setExams(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === params.examId);

          const dateFormatted = format(parseISO(response.data.date), 'dd/MM/yyyy');
          const dueFormatted = response.data.due ? format(parseISO(response.data.due), 'dd/MM/yyyy') : '';
          items[index] = { ...response.data, dateFormatted, dueFormatted };

          return { ...current, items };
        });

        const item = t('medical_report', { ns: 'horses' });
        toast(t('crud.created_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const deleteExamReport = useCallback(
    async (params: IDeleteHorseExamReportRequest) => {
      try {
        showLoader();

        await api.horse().deleteHorseExamReport(params);

        setExams(current => {
          const { items } = current;

          const index = items.findIndex(item => item.id === params.examId);

          items[index] = { ...items[index], reportUrl: undefined };

          return { ...current, items };
        });

        const item = t('medical_report', { ns: 'horses' });
        toast(t('crud.deleted_success', { ns: 'messages', context: 'male', item }), { type: 'success' });
      } catch (err) {
        handleErrorApi({ err });
      } finally {
        hideLoader();
      }
    },
    [hideLoader, showLoader, t],
  );

  const contextValue = useMemo<IHorseExamContext>(
    () => ({
      exams,
      createHorseExam,
      deleteExamReport,
      deleteHorseExam,
      getHorseExams,
      updateHorseExam,
      uploadExamReport,
    }),
    [exams, createHorseExam, deleteExamReport, deleteHorseExam, getHorseExams, updateHorseExam, uploadExamReport],
  );

  return <HorseExamContext.Provider value={contextValue}>{children}</HorseExamContext.Provider>;
};

export { HorseExamProvider, HorseExamContext };
