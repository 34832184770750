import { AxiosInstance, AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

import EquitesPaginate from '@equites/api/paginate';

import { openPdf } from '@utils/OpenPDF';

import {
  IBalanceItem,
  IBalanceGeneral,
  IBalanceMonth,
  IBalanceMonthWithWallet,
} from '@balances/types/Balances/balances';
import {
  IGetBalancesParams,
  IReportBalancesPdfParams,
  IReportBalancesCsvParams,
  ICreateBalanceRequest,
  IUpdateBalanceRequest,
  IUploadBalanceAttachmentRequest,
  IDeleteBalanceAttachmentRequest,
  IGetBalanceMonthRequest,
  IGetBalancesMonthRequest,
} from '@balances/types/Balances/requests';
import { IBalanceReportByCategory, IBalanceReportDaily, IBalanceReportMonthly } from '@balances/types/Reports/reports';
import {
  IGetBalancesCategoriesParams,
  IGetBalancesDailyParams,
  IGetBalancesMonthlyParams,
} from '@balances/types/Reports/requests';

export class Balance {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
  }

  // Balances

  get(params: IGetBalancesParams): Promise<AxiosResponse<EquitesPaginate<IBalanceItem>>> {
    return this.instance.get(`/balances`, { params });
  }

  async getReportPdf(params: IReportBalancesPdfParams): Promise<AxiosResponse> {
    const url = `/balances/report/pdf/export`;
    const { data, ...requestData } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...requestData };
  }

  async getReportCsv(params: IReportBalancesCsvParams): Promise<AxiosResponse> {
    const url = `/balances/report/csv/export`;
    const { data, ...requestData } = await this.instance.get(url, { params });

    fileDownload(data, `balances-${params.from}-to${params.to}.csv`);

    return { data, ...requestData };
  }

  create(data: ICreateBalanceRequest): Promise<AxiosResponse<IBalanceItem>> {
    return this.instance.post(`/balances`, data);
  }

  update(data: IUpdateBalanceRequest): Promise<AxiosResponse<IBalanceItem>> {
    const { id, ...body } = data;
    return this.instance.patch(`/balances/${id}`, body);
  }

  delete(id: string): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/balances/${id}`);
  }

  uploadAttachment(params: IUploadBalanceAttachmentRequest): Promise<AxiosResponse<IBalanceItem>> {
    const { balanceId, attachment } = params;

    const data = new FormData();
    data.append('attachment', attachment);

    return this.instance.patch(`/balances/${balanceId}/upload/attachment`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  deleteAttachment(params: IDeleteBalanceAttachmentRequest): Promise<AxiosResponse<void>> {
    return this.instance.delete(`/balances/${params.balanceId}/attachment`);
  }

  // Balances general

  getBalancesGeneral(): Promise<AxiosResponse<IBalanceGeneral[]>> {
    return this.instance.get(`/balances-general`);
  }

  // Balances month

  getBalanceMonth(params: IGetBalanceMonthRequest): Promise<AxiosResponse<IBalanceMonth>> {
    return this.instance.get(`/balances-month`, { params });
  }

  getBalancesMonth(params: IGetBalancesMonthRequest): Promise<AxiosResponse<IBalanceMonthWithWallet[]>> {
    const { year, month } = params;
    return this.instance.get(`/balances-month/year/${year}/month/${month}`);
  }

  // Reports

  getReportBalancesCategories(params: IGetBalancesCategoriesParams): Promise<AxiosResponse<IBalanceReportByCategory>> {
    return this.instance.get('/balances/categories/report', { params });
  }

  async getReportBalancesCategoriesPDF(params: IGetBalancesCategoriesParams): Promise<AxiosResponse> {
    const url = `/balances/categories/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async getReportBalancesCategoriesCSV(params: IGetBalancesCategoriesParams): Promise<AxiosResponse> {
    const url = `/balances/categories/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `balances-categories-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  getReportBalancesDaily(params: IGetBalancesDailyParams): Promise<AxiosResponse<IBalanceReportDaily[]>> {
    return this.instance.get('/balances/earnings-expenses/daily/report', { params });
  }

  async getReportBalancesDailyPDF(params: IGetBalancesDailyParams): Promise<AxiosResponse> {
    const url = `/balances/earnings-expenses/daily/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async getReportBalancesDailyCSV(params: IGetBalancesDailyParams): Promise<AxiosResponse> {
    const url = `/balances/earnings-expenses/daily/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `balances-entradas-saidas-${params.from}-to-${params.to}.csv`);

    return { data, ...response };
  }

  getReportBalancesMonthly(params: IGetBalancesMonthlyParams): Promise<AxiosResponse<IBalanceReportMonthly>> {
    return this.instance.get('/balances/earnings-expenses/monthly/report', { params });
  }

  async getReportBalancesMonthlyPDF(params: IGetBalancesMonthlyParams): Promise<AxiosResponse> {
    const url = `/balances/earnings-expenses/monthly/report/pdf/export`;
    const { data, ...response } = await this.instance.get(url, { params, responseType: 'blob' });

    openPdf(data);

    return { data, ...response };
  }

  async getReportBalancesMonthlyCSV(params: IGetBalancesMonthlyParams): Promise<AxiosResponse> {
    const url = `/balances/earnings-expenses/monthly/report/csv/export`;
    const { data, ...response } = await this.instance.get(url, { params });

    fileDownload(data, `balances-entradas-saidas-mes-${params.from}.csv`);

    return { data, ...response };
  }
}

export const balance = (instance: AxiosInstance): Balance => new Balance(instance);
